import React, { useReducer, createContext, useContext, useState } from 'react';
import { useUserInfo } from '../../services/useUserInfo';
import { appInitialState, reducer } from '../../reducers/appReducer';

const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, appInitialState);
  const { isAuthenticated, userSectionCode, isUserRoleAllowed, isAdministrator, isDataAdministrator, isReviewer } = useUserInfo(dispatch);
  const singleCompanyId = state.singleCompanyId;
  const [isSortArrowUp, setIsSortArrowUp] = useState({
    missingInfo: false,
    overdue: false,
    followUp: false,
    noMissingInfo: false,
  });
  const value = {
    state,
    dispatch,
    isAuthenticated,
    userSectionCode,
    isUserRoleAllowed,
    singleCompanyId,
    isAdministrator,
    isDataAdministrator,
    isReviewer,
    isSortArrowUp,
    setIsSortArrowUp
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
