import React, { useMemo } from 'react';
import { Refresh, RefreshDotted, TimeMachine } from '@instech/icons';
import { postRevalidate, postResync } from 'services/postRevalidateResync';
import { TooltipButtons, TooltipButton } from './TooltipButtons';
import { enqueueFleetToFix } from "../../services/enqueueFleetToFix";

export const RevalidateResyncButtons = ({ entityId, entityType, entityPartitionKey }) => {
  const resyncRevalidatePayload = useMemo(
    () => ({ entityId, entityType, entityPartitionKey }),
    [entityId, entityType, entityPartitionKey]
  );

  return (
    <TooltipButtons>
      <TooltipButton
        handler={postRevalidate}
        payload={resyncRevalidatePayload}
        text={"Revalidate " + entityType.replace(/([A-Z])/g, ' $1')}
        buttonTestId="revalidate-data"
        icon={Refresh}
      />
      <TooltipButton
        handler={postResync}
        payload={resyncRevalidatePayload}
        text={"Resync " + entityType.replace(/([A-Z])/g, ' $1')}
        buttonTestId="refresh-data"
        icon={RefreshDotted}
      />
        {entityType && entityType === 'Fleet' && (
            <TooltipButton
                handler={enqueueFleetToFix}
                payload={resyncRevalidatePayload}
                text={"Check and fix " + entityType.replace(/([A-Z])/g, ' $1')}
                buttonTestId="check-fix-data"
                icon={TimeMachine}
            />
        )}
    </TooltipButtons>
  );
};
