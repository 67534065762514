import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getFriendlyDate } from '../../utils/date';
import { MenuDots } from '@instech/icons';
import { ButtonsSection } from './ButtonsSection';
import { usePersonById } from '../../services/postPutPersonData';
import { reviewPersonHighRiskAcceptance, proposePersonHighRiskAcceptance } from '../../services/putAcceptanceReviews';
import { AcceptModalDialog } from "../shared/AcceptModalDialog";
import { AcceptanceReviewStatus, AcceptanceType } from "../../utils/AcceptanceType";
import { usePersonAcceptanceReviews } from "../../services/useAcceptanceReviews";

const StyledDots = styled(MenuDots)`
  transform: rotate(90deg);
  cursor: pointer;
`;
const RolesWrapper = styled.div`
  padding: 0 10px;
`;
const EditSection = styled.div`
  padding: 0 20px;
  border-left: 1px solid ${(props) => props.theme.marineBlue};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.5fr repeat(5, 1fr) 0.5fr 50px;
  row-gap: 0;
  column-gap: 16px;
  align-items: center;
  padding: 10px;
`;
export const CompanyRoleSection = ({
  values,
  clientId,
  fleetId,
  vesselId,
  handleRemove,
  remove,
  index,
  locationState,
  isUbo,
}) => {
  const [isButtonsSectionOpen, setIsButtonsSectionOpen] = useState(false);
  const [isProposeHighRiskVisible, setIsProposeHighRiskVisible] = useState(false);
  const [isReviewHighRiskVisible, setIsReviewHighRiskVisible] = useState(false);
  const { personData: person, mutate } = usePersonById(values.personReference.id);

  const node = useRef();

  const { data: acceptanceReviews, mutate: mutateReviews} = usePersonAcceptanceReviews(person?.id);
  const pendingAcceptanceReview = acceptanceReviews?.find((review) =>
    review.acceptanceType === AcceptanceType.RiskLevel
    && review.status === AcceptanceReviewStatus.Pending);
  const hasPendingReview = !!pendingAcceptanceReview;

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsButtonsSectionOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const proposeHighRiskAcceptance = async (values) => {
    const period = {
      from: values.acceptanceDateFrom,
      to: values.acceptanceDateTo
    }
    const command = {
      personId: person.id,
      data: {
        acceptancePeriod: period,
        comments: values.additionalComments,
        reviewerSubjectIds: values.reviewerSubjectIds?.map(x => x.value) ?? [],
        fleetId: fleetId
      },
      eTag: pendingAcceptanceReview?._etag ?? '*'
    };
    proposePersonHighRiskAcceptance(command)
      .then(() => {
        mutate();
        mutateReviews && mutateReviews();
      })
      .finally(() => setIsProposeHighRiskVisible(false));
  }

  const reviewHighRiskAcceptance = async (values) => {
     const command = {
      personId: person.id,
      data: {
        acceptanceReviewStatus: values.acceptanceReviewStatus,
        reviewComments: values.additionalComments
      },
      eTag: pendingAcceptanceReview._etag
    };

    reviewPersonHighRiskAcceptance(command)
      .then(() => {
        mutate();
        mutateReviews && mutateReviews();
      })
      .finally(() => setIsReviewHighRiskVisible(false));
  }

  const clickButtonsSectionHandler = () => {
    setIsButtonsSectionOpen((isButtonsSectionOpen) => !isButtonsSectionOpen);
  };
  return (
    <RolesWrapper>
      <Grid>
        <span>{values.type}</span>
        <span>
          {values.activePeriod && values.activePeriod.from && getFriendlyDate(values.activePeriod.from)} &nbsp; - &nbsp;
          {values.activePeriod && values.activePeriod.to && getFriendlyDate(values.activePeriod.to)}
        </span>
        <span>{person?.nationality?.displayName}</span>
        <span>{person?.firstName}</span>
        <span>{person?.lastName}</span>
        <span>{person?.dateOfBirth ? getFriendlyDate(person?.dateOfBirth) : '-'}</span>
        <span>
          {person?.isPoliticallyExposed === true
              ? person.acceptedHighRisk ? 'YES - Accepted\u00A0HR' : hasPendingReview ? 'Yes - Review\u00A0pending' : 'YES'
              : person?.isPoliticallyExposed === false ? 'NO' : '-'}
        </span>

        <EditSection data-test-id="open-edit-section" ref={node} onClick={clickButtonsSectionHandler}>
          <StyledDots />
          {isButtonsSectionOpen && (
            <ButtonsSection
              handleRemove={handleRemove}
              remove={remove}
              index={index}
              personId={values.personReference.id}
              clientId={clientId}
              fleetId={fleetId}
              vesselId={vesselId}
              locationState={locationState}
              isUbo={isUbo}
              isPep={person?.isPoliticallyExposed}
              isHRAccepted={person?.acceptedHighRisk}
              hasPendingAcceptanceReview={hasPendingReview}
              showAcceptanceModal={() => hasPendingReview ? setIsReviewHighRiskVisible(true) : setIsProposeHighRiskVisible(true)}
            />
          )}
        </EditSection>
      </Grid>
      {isProposeHighRiskVisible && (
          <AcceptModalDialog
              text='Fill in to accept high risk for person'
              labelText='Reason for accepting high risk'
              cancelButtonText='Cancel'
              acceptButtonText='Propose HR acceptance'
              onCancel={() => setIsProposeHighRiskVisible(false)}
              onAccept={(values) => proposeHighRiskAcceptance(values)}
          />
      )}
      {isReviewHighRiskVisible && (
          <AcceptModalDialog
              text='Fill in to review high risk acceptance for person'
              labelText='Proposed reason for accepting high risk'
              reviewLabelText='Reason for accepting/rejecting proposal'
              pendingReview={pendingAcceptanceReview}
              cancelButtonText='Cancel'
              acceptButtonText='Accept'
              rejectButtonText='Reject'
              onCancel={() => setIsReviewHighRiskVisible(false)}
              onAccept={(values) => reviewHighRiskAcceptance(values)}
          />
      )}
    </RolesWrapper>
  );
};

CompanyRoleSection.propTypes = {
  values: PropTypes.object,
  handleRemove: PropTypes.func,
  clientId: PropTypes.string,
  fleetId: PropTypes.string,
  vesselId: PropTypes.string,
  remove: PropTypes.func,
  index: PropTypes.number,
  locationState: PropTypes.object,
};
