import { getJsonAsync, deleteJsonAsync, postJsonAsync } from './fetchConfig';

export const deleteQuotedFleetEntry = (fleetId) => deleteJsonAsync(`fleets/quoted?fleetId=${fleetId}`);

export const getInterestsForQuotedFleet = (insuranceYear, fleetName) =>
  getJsonAsync(`/fleets/quoted/covers?insuranceYear=${insuranceYear}&fleetName=${encodeURIComponent(fleetName)}`);

export const getQuotedFleetsByYear = (insuranceYear) => getJsonAsync(`/fleets/quoted?insuranceYear=${insuranceYear}`);

export const PostQuotedFleet = (values) => postJsonAsync('Fleets/quoted', values);
