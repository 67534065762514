import React from 'react';
import styled from 'styled-components';
import { BoxButton, ButtonGroup } from '@instech/components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 265px;
  grid-auto-flow: row dense;
  margin-top: 16px;
  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }
  .approveChangesButton {
    background-color: ${(props) => props.theme.status.red};
    color: ${(props) => props.theme.marineBlue};
    &:hover {
      color: ${(props) => props.theme.white};
    }
  }
`;
const WarningMessage = styled.div`
  background-color: ${(props) => props.theme.background.red};
  padding: 10px;
  margin-right: 16px;
  color: ${(props) => props.theme.marineBlue};
  grid-column: span 2;
`;
const ButtonsWrapper = styled.div`
  text-align: right;
  grid-column: 3;
  @media (max-width: 960px) {
    margin-top: 16px;
  }
`;
export const ApproveChanges = ({ handleApprove, handleCancel }) => {
  return (
    <Wrapper>
      <WarningMessage>Approve changes to update high risk flag status in the portal.</WarningMessage>
      <ButtonsWrapper>
        <ButtonGroup buttonGap='16px' alignRight>
          <BoxButton inverted aria-label='Cancel changes' onClick={handleCancel} type='button'>
            CANCEL
          </BoxButton>
          <BoxButton
            aria-label='Approve changes'
            onClick={handleApprove}
            type='button'
            className='approveChangesButton'
          >
            APPROVE
          </BoxButton>
        </ButtonGroup>
      </ButtonsWrapper>
    </Wrapper>
  );
};

ApproveChanges.propTypes = {
  handleApprove: PropTypes.func,
  handleCancel: PropTypes.func,
};
