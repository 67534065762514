import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from '../shared/formFields/Label';
import { Formik, Form, FieldArray } from 'formik';
import { Checkbox } from '../shared/formFields/CheckboxForGroup';
import { useCaseHandlerEmail } from '../../services/useCaseHandlerEmail';
import { FilterRemove } from '@instech/icons';
import { constants } from '../../utils/constants';
import { SlimButton, BoxButton } from '@instech/components';
import { useAppContext } from '../appRouting/AppContext';

const StyledFilterRemove = styled(FilterRemove)`
  width: 20px;
  padding-left: 2px;
  color: ${(props) => props.theme.marineBlue50};
`;
const Wrapper = styled.div`
  background: ${(props) => props.theme.white};
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
  border-top: 2px solid ${(props) => props.theme.marineBlue};
  position: absolute;
  right: 0;
  top: 32px;
  z-index: 2;
`;
const FilterItem = styled.div`
  margin-bottom: 32px;
`;
const CheckboxWrapper = styled.div`
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.marineBlue10};
  label {
    padding: 4px 0;
    color: ${(props) => props.theme.marineBlue};
    font-weight: normal;
  }
`;
const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  color: ${(props) => props.theme.marineBlue};
  background-color: ${(props) => props.theme.marineBlue10};
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 16px;
  padding-left: 8px;
  border: 1px solid ${(props) => props.theme.marineBlue20};
  border-radius: 2px;
  outline: none;
  transition: 0.4s;
  height: 46px;
  &:focus,
  &:hover {
    border: 1px solid ${(props) => props.theme.marineBlue};
  }
  ::placeholder {
    color: ${(props) => props.theme.marineBlue50};
  }
`;
const ClearFilersButton = styled.button`
  margin: 0 0 0 auto;
  color: ${(props) => props.theme.marineBlue50};
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  &:hover {
    background: ${(props) => props.theme.whiteBlue};
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: right;
  button {
    margin-left: 16px;
  }
`;

export const FilterCaseHandlers = ({ onlySnapshots, handleOpenCaseHandlerFilters, setCurrentPage }) => {
  const { state, dispatch } = useAppContext();

  const sections = onlySnapshots ? state.sectionsForQuoted : state.sections;
  const caseHandlerEmails = onlySnapshots ? state.caseHandlerEmailsForQuoted : state.caseHandlerEmails;

  const caseHandlerEmailsFullList = useCaseHandlerEmail(caseHandlerEmails, sections, onlySnapshots);
  const [searchText, setSearchText] = useState('');

  const handleClearFilters = () => {
    dispatch({
      type: onlySnapshots ? constants.SET_CASE_HANDLER_EMAILS_FOR_QUOTED : constants.SET_CASE_HANDLER_EMAILS,
      value: [],
    });
    setCurrentPage(1);
    handleOpenCaseHandlerFilters();
  };
  const handleSubmit = (values) => {
    dispatch({
      type: onlySnapshots ? constants.SET_CASE_HANDLER_EMAILS_FOR_QUOTED : constants.SET_CASE_HANDLER_EMAILS,
      value: values.caseHandlerEmails,
    });
    setCurrentPage(1);
    handleOpenCaseHandlerFilters();
  };

  const filtered =
    !!searchText &&
    caseHandlerEmailsFullList &&
    caseHandlerEmailsFullList?.filter((dt) => dt.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <Wrapper>
      <ClearFilersButton data-test-id="clear-filters" type="button" onClick={handleClearFilters}>
        <span>Clear Filters</span>
        <StyledFilterRemove />
      </ClearFilersButton>
      <Formik enableReinitialize initialValues={{ caseHandlerEmails }}>
        {({ values }) => {
          return (
            <Form>
              <FilterItem>
                <Label label="Case Handler" />
                <StyledInput
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <CheckboxWrapper>
                  <FieldArray
                    name="caseHandlerEmails"
                    render={(arrayHelpers) => (
                      <div>
                        {!searchText &&
                          caseHandlerEmailsFullList &&
                          caseHandlerEmailsFullList.length > 0 &&
                          caseHandlerEmailsFullList.map((item, index) => (
                            <Checkbox
                              key={item + index}
                              name="caseHandlerEmails"
                              checked={values.caseHandlerEmails?.includes(item)}
                              onChange={(e) => {
                                if (e.target.checked) arrayHelpers.push(item);
                                else {
                                  const idx = values.caseHandlerEmails.indexOf(item);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                              value={item}
                              label={item}
                            />
                          ))}
                        {caseHandlerEmailsFullList && caseHandlerEmailsFullList.length === 0 && 'no results found'}
                        {filtered &&
                          filtered.map((item, index) => {
                            return (
                              <Checkbox
                                key={item + index}
                                name="caseHandlerEmails"
                                checked={values.caseHandlerEmails.includes(item)}
                                onChange={(e) => {
                                  if (e.target.checked) arrayHelpers.push(item);
                                  else {
                                    const idx = values.caseHandlerEmails.indexOf(item);
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                                value={item}
                                label={item}
                              />
                            );
                          })}
                      </div>
                    )}
                  />
                </CheckboxWrapper>
              </FilterItem>
              <ButtonsWrapper>
                <BoxButton
                  aria-label="Cancel"
                  onClick={handleClearFilters}
                  title=""
                  type="button"
                  inverted
                  padding="8px 16px"
                >
                  CANCEL
                </BoxButton>
                <SlimButton aria-label="Filter" onClick={() => handleSubmit(values)} type="button" padding="8px 16px">
                  FILTER
                </SlimButton>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

FilterCaseHandlers.propTypes = {
  onlySnapshots: PropTypes.bool,
  handleOpenCaseHandlerFilters: PropTypes.func,
  setCurrentPage: PropTypes.func,
};
