import moment from 'moment-timezone';

export const friendlyDateFormat = 'DD.MM.YYYY';
export const friendlyDateTimeFormat = 'DD.MM.YYYY HH:mm';

export const formatDateOfBirth = (date) => !date ? null : moment(date).format('YYYY-MM-DD');

export const getFriendlyDate = (date) =>
  moment(date).format(friendlyDateFormat);

export const getFriendlyDateTime = (date) =>
  moment(date).format(friendlyDateTimeFormat);

export const getDateISOString = (date) => date === null ? null : date.startOf('date').toISOString(true); 
 
export const parseISODateString = (dateString) => {
  moment.tz.setDefault('Europe/Oslo');
  return moment(dateString, moment.ISO_8601, true).tz('Europe/Oslo');
}

export const guidelineInceptionDate = '2022-07-01T00:00:00.000+02:00';

export const subtractCurrentDate = days => getDateISOString(moment().subtract(days, "days"));

export const subtractDate = (days, date) => getDateISOString(moment(date).subtract(days, "days"));