import styled, { css } from 'styled-components';

export const FieldLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ErrorMessage = styled.span`
  display: block;
  height: 30px;
  color: red;
  font-size: 14px;
`;

export const FieldLabel = styled.span`
  display: inline-block;
  height: 15px;
  margin-bottom: 12px;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue};
  cursor: default;
`;

export const EmptyLabel = styled.span`
  height: 15px;
  margin-bottom: 12px;
`;

export const InputField = styled.div`
  & select,
  & input,
  & textarea {
    width: 100%;
    height: 40px;
    padding: 0px 16px;
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid;
    border-color: ${(props) => props.theme.border.gray};
    box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
    appearance: none;

    ${(props) =>
      props.error &&
      css`
        border-color: ${props.theme.red};
        color: ${props.theme.red};
        &::placeholder {
          color: ${props.theme.red};
          opacity: 0.6;
        }
      `};
    &:disabled {
      color: ${(props) => props.theme.black};
      background-color: ${(props) => props.theme.background.primary};
    }
  }

  /* Extend styles for textarea */
  & textarea {
    height: 60px;
    padding: 8px 16px;
    resize: vertical;
  }
`;

export const InputFieldArea = styled.div`
  & textarea {
    width: 100%;
    height: 78px;
    min-height: 40px;
    padding: 8px 16px;
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid;
    border-color: ${(props) => props.theme.border.gray};
    box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
    appearance: none;
    resize: vertical;

    ${(props) =>
      props.error &&
      css`
        border-color: ${props.theme.red};
        color: ${props.theme.red};
        &::placeholder {
          color: ${props.theme.red};
          opacity: ${props.theme.opacity.placeholder};
        }
      `};
  }
`;
