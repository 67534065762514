import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AcceptanceLogOrganization } from './AcceptanceLogOrganization';
import {
  useRiskLevelAcceptanceLogForOrganization,
  useMissingDataAcceptanceLogForOrganization,
} from '../../services/useAcceptanceLogs';
import { ContentBlock } from '../shared/ContentBlock';
import { PagedContent } from '../shared/PagedContent';

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(80px, 1fr)) minmax(150px, 2fr) 50px;
  grid-gap: 8px;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue50};
  font-weight: bold;
  font-size: 14px;
  padding: 10px 20px;
  align-items: center;
`;
const Table = styled.div`
  margin-bottom: 10px;
  background: ${(props) => props.theme.white};
  padding-bottom: 16px;
`;
const Notification = styled.div`
  padding: 32px 0;
`;

export const ActivityLog = ({ organizationId }) => {
  const acceptanceRiskLevelLog = useRiskLevelAcceptanceLogForOrganization(organizationId);
  const acceptanceMissingDataLog = useMissingDataAcceptanceLogForOrganization(organizationId);

  const logsData = useMemo(() => {
    const combinedData = (acceptanceMissingDataLog || []).concat(acceptanceRiskLevelLog || []);
    return combinedData.sort((a, b) => b.acceptedAt.localeCompare(a.acceptedAt, 'en'));
  }, [acceptanceMissingDataLog, acceptanceRiskLevelLog]);

  return (
    <ContentBlock title="Activity Log">
      {logsData.length === 0 ? (
        <Notification>There is no data available </Notification>
      ) : (
        <Table>
          <TableHeader>
            <span>Log Type</span>
            <span>Created Date/Time</span>
            <span>Created by</span>
            <span>Comments</span>
          </TableHeader>
          <PagedContent items={logsData} itemsPerPage={8}>
            {(pageData) => <AcceptanceLogOrganization organizationId={organizationId} logsData={logsData} pageData={pageData} />}
          </PagedContent>
        </Table>
      )}
    </ContentBlock>
  );
};

ActivityLog.propTypes = {
  organizationId: PropTypes.string,
};
