import useSWR from 'swr';
import { postJsonAsync } from './fetchConfig';
import qs from 'qs';

export const createRequestParams = (riskLevel) => {
  return (
    {
      riskLevel: riskLevel === 'Accepted' ? 'High' : riskLevel,
      hasUnhandledHighRisk: riskLevel === 'Accepted' ? false : (riskLevel === 'High' ? true : null)
    }
  )
};
 
export const useFleetItemsList = (params) => {
  const query = qs.stringify(params, { arrayFormat: 'repeat' });
  const swrKey = `fleets?${query}`;

  // Create key based on the query params for caching purposes
  const { data, mutate } = useSWR(swrKey, () => postJsonAsync('fleets', params));

  const fleets = data?.items;
  const totalCount = data?.totalCount;

  return { fleets, totalCount, mutate };
};
