import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { FilterRemove, ArrowForward } from '@instech/icons';
import { constants } from '../../utils/constants';
import { SlimButton, BoxButton, RadioButtonsControlled } from '@instech/components';
import { useAppContext } from '../appRouting/AppContext';

const StyledFilterRemove = styled(FilterRemove)`
  width: 20px;
  padding-left: 2px;
  color: ${(props) => props.theme.marineBlue50};
`;
const StyledUp = styled(ArrowForward)`
  width: 20px;
  color: ${(props) => props.theme.marineBlue};
  transform: rotate(-90deg);
`;
const StyledDown = styled(ArrowForward)`
  width: 20px;
  color: ${(props) => props.theme.marineBlue};
  transform: rotate(90deg);
`;
const Wrapper = styled.div`
  background: ${(props) => props.theme.white};
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
  border-top: 2px solid ${(props) => props.theme.marineBlue};
  position: absolute;
  right: 0;
  top: 32px;
  z-index: 2;
`;
const FilterItem = styled.div`
  margin-bottom: 32px;
`;
const ClearFilersButton = styled.button`
  margin: 0 0 0 auto;
  color: ${(props) => props.theme.marineBlue50};
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  &:hover {
    background: ${(props) => props.theme.whiteBlue};
  }
`;
const SortButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 400;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: right;
  button {
    margin-left: 16px;
  }
`;
const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const FilterRiskLevel = ({ handleOpenRiskLevelFilters, name, setCurrentPage, handleSort }) => {
  const { state, dispatch, isSortArrowUp, setIsSortArrowUp } = useAppContext();
  const riskLevel = state.riskLevel;

  const handleClearFilters = () => {
    dispatch({
      type: constants.SET_RISK_LEVEL,
      value: null,
      name: name,
    });
    setCurrentPage(1);
    handleSort('name');
    setIsSortArrowUp((prev) => ({ ...prev, [name]: false }));
    handleOpenRiskLevelFilters();
  };

  const handleSortByRiskLevel = () => {
    handleSort('riskLevel');
    setIsSortArrowUp((prev) => ({ ...prev, [name]: !isSortArrowUp[name] }));
    handleOpenRiskLevelFilters();
  };

  const handleSubmit = (values) => {
    dispatch({
      type: constants.SET_RISK_LEVEL,
      value: values.riskLevel,
      name: name,
    });
    setCurrentPage(1);
    handleOpenRiskLevelFilters();
  };

  return (
    <Wrapper>
      <FilterHeader>
        <SortButton data-test-id="sort-by-risk" onClick={handleSortByRiskLevel}>
          {isSortArrowUp[name] ? <StyledUp /> : <StyledDown />}
          <span>Sort by Risk Level</span>
        </SortButton>
        <ClearFilersButton data-test-id="clear-filters" type="button" onClick={handleClearFilters}>
          <StyledFilterRemove />
        </ClearFilersButton>
      </FilterHeader>
      <Formik enableReinitialize initialValues={{ riskLevel: riskLevel[name] }}>
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <FilterItem>
                <RadioButtonsControlled
                  label="Risk Level"
                  name="riskLevel"
                  options={['High', 'Moderate', 'Low', 'Accepted']}
                  onChange={(value) => setFieldValue('riskLevel', value)}
                  selected={values.riskLevel}
                />
              </FilterItem>
              <ButtonsWrapper>
                <BoxButton
                  aria-label="Cancel"
                  onClick={handleClearFilters}
                  title=""
                  type="button"
                  inverted
                  padding="8px 16px"
                >
                  CANCEL
                </BoxButton>
                <SlimButton aria-label="Filter" onClick={() => handleSubmit(values)} type="button" padding="8px 16px">
                  FILTER
                </SlimButton>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

FilterRiskLevel.propTypes = {
  handleOpenRiskLevelFilters: PropTypes.func,
  name: PropTypes.string,
};
