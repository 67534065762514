import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { mutate } from 'swr';
import { BoxButton } from '@instech/components';
import { VesselsTableForMultipleChoice } from './VesselsTableForMultipleChoice';
import { CreateCompany, UpdateCompany, AddCompanyRolesToObjects } from '../../services/postPutCompanyData';
import { ProgressModalDialog } from '../shared/ProgressModalDialog';
import { ErrorMessage } from 'components/shared/datePicker/Components';
import { formatErrorMessage } from '../../utils/errorMessageFormatter';
import { useEventStream } from '../../services/useEventStream';
import { v4 as uuidv4 } from 'uuid';
import { ArrowBack } from '@instech/icons';

const Wrapper = styled.div`
  padding: 20px;
  margin-top: 32px;
  color: ${(props) => props.theme.marineBlue};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;
const CompanyInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  grid-column-gap: 1rem;
  border: 2px solid ${(props) => props.theme.marineBlue50};
  background: ${(props) => props.theme.white};
  padding: 8px;
`;
const Title = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  padding-top: 20px;
`;
const SectionWrapper = styled.div`
  border: 2px solid ${(props) => props.theme.marineBlue50};
  background: ${(props) => props.theme.white};
  padding: 8px;
`;

const NavigateButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
`;
const ErrorWrapper = styled.div`
  text-align: right;
`;
const organizationRolesToObjects = (commandId, fleetId, commandData) => ({
  id: commandId,
  subject: {
    id: fleetId,
    partitionKey: fleetId,
    entityType: 'Fleet',
  },
  fleetId: fleetId,
  data: commandData,
});

const sortByKey = (key, arr, order) =>
  arr.sort((a, b) => (order === 'asc' ? (a[key] > b[key] ? 1 : -1) : a[key] > b[key] ? -1 : 1));

export const NewCompanyForMultipleVesselsConfirmation = ({
  handleStepBack,
  fleetId,
  fleetCovers,
  formValues,
  vesselsList,
  isCompanyNew,
  fieldsSortOrder,
  setFieldsSortOrder,
  claims,
  fleetEtag,
}) => {
  // Unique identifier of this wizard instance
  const [commandId] = useState(uuidv4());

  const [isModalDialogVisible, setIsModalDialogVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [savingProgress, setSavingProgress] = useState({
    value: 0,
    message: '',
    timestamp: null,
  });

  // Topic based on command subject
  const eventStreamTopic = `/Fleet/${fleetId}/${fleetId}/CommandProgress/${commandId}`;
  const { onEvent, onConnect, onDisconnect, eventHandlerCleanup } = useEventStream(eventStreamTopic);

  onConnect(
    useCallback(() => {
      console.log('Connected to event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  onDisconnect(
    useCallback(() => {
      console.log('Disconnected from event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  useEffect(() => {
    onEvent((event) => {
      if (!!event && event.type.endsWith('ProgressReportEvent')) {
        console.info('Received event on topic', eventStreamTopic, event);
        setSavingProgress((prev) =>
          prev.value > event.progress || (prev.timestamp && prev.timestamp > event.timestamp)
            ? prev
            : {
                value: event.progress,
                message: event.details,
                timestamp: event.timestamp,
              }
        );
      }
    });

    return eventHandlerCleanup;
  });

  const showModal = (value) => {
    setIsModalDialogVisible(value);
  };

  const createCommanData = (response, values, interestCodesByObjectId) => {
    const commandData = [];

    values.vessels.objectIds.forEach((objectId) => {
      commandData.push({
        organizationId: response.id,
        ownerIsAssured: true,
        twoLetterCountryCode: response.organization
          ? response.organization.legalAddress.country.partitionKey
          : response.legalAddress.country.partitionKey,

        fleetId: fleetId,
        objectId: objectId,
        roleType: values.company.clientRoleType.roleType,
        activePeriod: values.company.activePeriod,
        interestCodes: interestCodesByObjectId[objectId],
      });
    });

    return commandData;
  };

  const handleSubmit = (values) => {
    showModal(true);
    setIsError(false);
    setErrorMessage(null);
    if (!values.company.legalAddress.state?.id) values.company.legalAddress.state = null;
    if (!values.company.legalAddress.country.id)
      values.company.legalAddress.country.id = '00000000-0000-0000-0000-000000000000';

    const companyValues = {
      id: values.company.id,
      organization: values.company,
    };

    const interestCodesByObjectId = {};
    Object.keys(values.vessels.interestCodesByObjectId).forEach((item) => {
      if (values.vessels.objectIds.includes(item)) {
        const itemInterests = values.vessels.interestCodesByObjectId[item];
        const itemInterestsIds = itemInterests.map(
          (i) => fleetCovers.find((fc) => fc.interestCode.name === i).interestCode.id
        );
        interestCodesByObjectId[item] = itemInterestsIds;
      }
    });

    if (isCompanyNew) {
      CreateCompany(companyValues)
        .then((response) => {
          const commandData = createCommanData(response, values, interestCodesByObjectId);
          AddCompanyRolesToObjects(organizationRolesToObjects(commandId, fleetId, commandData), fleetEtag)
            .then(() => {
              setIsSubmitted(true);
            })
            .catch((error) => {
              setIsError(true);
              setIsSubmitted(false);
              setErrorMessage(formatErrorMessage(error));
            });
        })
        .catch((error) => {
          setErrorMessage(formatErrorMessage(error));
          setIsError(true);
          setIsSubmitted(false);
        });
    } else {
      UpdateCompany(companyValues, values.company._etag)
        .then((response) => {
          const commandData = createCommanData(response, values, interestCodesByObjectId);
          mutate(`organizations/${companyValues.id}`);
          AddCompanyRolesToObjects(organizationRolesToObjects(commandId, fleetId, commandData), fleetEtag)
            .then(() => {
              setIsSubmitted(true);
            })
            .catch((error) => {
              setIsError(true);
              setIsSubmitted(false);
              setErrorMessage(formatErrorMessage(error));
            });
        })
        .catch((error) => {
          setErrorMessage(formatErrorMessage(error));
          setIsError(true);
          setIsSubmitted(false);
        });
    }
  };

  const [sortSelectedVesselsList, setSortSelectedVesselsList] = useState(null);

  const handleSort = (sort) => {
    setFieldsSortOrder({
      ...fieldsSortOrder,
      [sort]: fieldsSortOrder[sort] === 'asc' ? 'desc' : 'asc',
    });
    sortByKey(sort, selectedVesselsList, fieldsSortOrder[sort]);
    setSortSelectedVesselsList(selectedVesselsList);
  };

  const selectedVesselsList =
    vesselsList && vesselsList.length > 0 && vesselsList.filter((v) => formValues.vessels.objectIds.includes(v.id));

  selectedVesselsList.forEach((v) => {
    v.interestCodes = v.interestCodes.filter((c) => formValues.vessels.interestCodesByObjectId[v.id].includes(c.name));
  });

  return (
    <Wrapper>
      <Title>Company</Title>
      <CompanyInfo>
        <div>
          <div>Company name</div>
          <div>{formValues.company.name}</div>
        </div>
        <div>
          <div>Address</div>
          <div>
            {formValues.company.legalAddress.addressLine}
            {formValues.company.legalAddress.addressLine && ', '}
            {formValues.company.legalAddress.country.displayName}
          </div>
        </div>
        <div>
          <div>Org.Number</div>
          <div>{formValues.company.organizationNumber}</div>
        </div>
        <div>
          <div>LEI Number</div>
          <div>{formValues.company.lei}</div>
        </div>
      </CompanyInfo>
      <Title>Role</Title>
      <SectionWrapper>
        <div>{formValues.company.clientRoleType.roleType.roleName}</div>
      </SectionWrapper>
      <Title>Vessels</Title>
      <VesselsTableForMultipleChoice
        vesselsList={sortSelectedVesselsList || selectedVesselsList}
        handleSort={handleSort}
        claims={claims}
      />
      <NavigateButtons>
        {!isSubmitted ? (
          <BoxButton
            data-test-id="navigate-back"
            aria-label="Back"
            inverted
            onClick={handleStepBack}
            padding="10px 20px"
            startIcon={<ArrowBack />}
            type="button"
          >
            BACK
          </BoxButton>
        ) : (
          <div></div>
        )}
        <BoxButton
          data-test-id="submit"
          aria-label="SUBMIT"
          disabled={isSubmitted}
          onClick={() => handleSubmit(formValues)}
          padding="10px 20px"
          title=""
          type="button"
        >
          SUBMIT
        </BoxButton>
      </NavigateButtons>
      <ErrorWrapper>
        {isError && (
          <ErrorMessage>
            <div>Failed to save company data!</div>
            <div>{errorMessage}</div>
          </ErrorMessage>
        )}
      </ErrorWrapper>
      {isModalDialogVisible && (
        <ProgressModalDialog
          closeModal={() => showModal(false)}
          savingProgress={savingProgress}
          isError={isError}
          errorMessage={errorMessage}
        />
      )}
    </Wrapper>
  );
};

NewCompanyForMultipleVesselsConfirmation.propTypes = {
  handleStepBack: PropTypes.func,
  fleetId: PropTypes.string,
  fleetCovers: PropTypes.array,
  formValues: PropTypes.object,
  vesselsList: PropTypes.array,
  isCompanyNew: PropTypes.bool,
  fleetEtag: PropTypes.string,
  fieldsSortOrder: PropTypes.object,
  setFieldsSortOrder: PropTypes.func,
  claims: PropTypes.array,
};
