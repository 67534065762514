import React, { Fragment, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { ReadOnlyInput } from './formFields/InputField';
import { Label } from './formFields/Label';
import { TextAreaField } from './formFields/TextAreaField';
import { BoxButton, Dropdown } from '@instech/components';
import { Formik } from 'formik';
import { DatePicker } from './datePicker/DatePicker';
import { Divider } from './Divider';
import { ValidationSchemaAcceptDialog } from '../company/validationSchema';
import { getDateISOString, getFriendlyDateTime } from '../../utils/date';
import { useAccount } from '../../providers/authenticationProvider';
import { getUser } from '../../services/getUser';
import { AcceptanceReviewStatus } from "../../utils/AcceptanceType";
import { getReviewersWithCondition } from "../../services/getReviewers";
import { distinct } from "../../utils/distinctBy";
import { useIsMounted } from "../../utils/useIsMounted";
import { useFleetOverviewContext } from '../fleetDetails/FleetOverviewContext';
import { useCompanyContext } from '../company/CompanyContext';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: scroll;

  .acceptMissingInfoButton {
    background-color: ${(props) => props.theme.yellow};
    color: ${(props) => props.theme.marineBlue};
    &:hover {
      color: ${(props) => props.theme.white};
    }
    :disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .acceptHighRiskButton {
    background-color: ${(props) => props.theme.status.red};
    color: ${(props) => props.theme.marineBlue};
    &:hover {
      color: ${(props) => props.theme.white};
    }
    :disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`;

const Dialog = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  left: 30%;
  right: 30%;
  top: 50px;
  height: fit-content;
  margin: auto;
  background: white;
  padding: 0;
`;

const FormWrapper = styled.div`
  padding: 0 16px 16px 16px;
  color: ${(props) => props.theme.marineBlue};
  background: ${(props) => props.theme.white};
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const TopBox = styled.div`
  border: 2px solid ${(props) => props.theme.background.primary};
  display: grid;
  grid-template-columns: 1fr;
  margin: 20px 20px 0px 20px;
  align-items: start;
`;

const TopHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 20px;
  padding: 20px 20px 0px 20px;
  align-items: start;
`;

const TopDetail = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 20px;
  padding: 0px 20px 20px 20px;
  align-items: start;
`;

const DateRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 20px 20px 0px 20px;
  align-items: start;
  text-align: left;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding: 20px;
  align-items: start;
`;

const ButtonRow = styled.div`
  display: flex;
  column-gap: 20px;
  padding: 20px;
  align-items: stretch;
  justify-content: space-between;
`;

const ButtonRowSection = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: stretch;
  justify-content: space-between;
`;


const Button = styled.button`
  margin: 5px;
  align-self: flex-end;
  justify-self: flex-end;
  padding-left: 6px;
  padding-right: 6px;
  background: none;
  color: #de350b;
  font-size: 24px;
  line-height: 24px;
  outline: none;
  border: none;
  border-radius: 2px;
  align-items: center;
  transition: all 0.2s ease;

  &:hover {
    background-color: #ffbdad;
    transform: scale(1.3);
  }
`;

export const AcceptModalDialog = ({
  isMissingInfo,
  forType,
  forDescription,
  riskNumber,
  coverNote,
  text,
  labelText,
  reviewLabelText,
  pendingReview,
  highRisks,
  cancelButtonText,
  acceptButtonText,
  rejectButtonText,
  onCancel,
  onAccept,
}) => {
  const { user } = useAccount();
  const isReview = !!pendingReview;
  const isProposal = !isMissingInfo && !isReview;
  const reviewers = getReviewersWithCondition(isProposal);
  const reviewerOptions = reviewers?.map(r => ({label: r.name, value: r.subjectId})) || [];

  const [userNames, setUserNames] = useState({});
  const [proposedBy, setProposedBy] = useState('');

  const isMounted = useIsMounted();

  useEffect(() => {
    if (!pendingReview?.userId) return;
    getUser(pendingReview.userId).then(user => {
      if (isMounted.current) setProposedBy(user.name);
    });
  });

  useEffect(() => {
    if (!highRisks) return;
    const userIds = distinct(highRisks.map(risk => risk.userId));
    userIds.forEach(userId => {
      getUser(userId).then(user => {
        if (isMounted.current) setUserNames(prev => ({...prev, [userId]: user.name}));
      });
    });
  }, [highRisks]);

  const initialValues = {
    forDescription: forDescription,
    riskNumber: riskNumber,
    coverNote: coverNote,
    acceptanceDateFrom: pendingReview?.acceptancePeriod.form ?? getDateISOString(moment()),
    acceptanceDateTo: pendingReview?.acceptancePeriod.to ?? getDateISOString(moment().add(18, 'M')),
    reviewedBy: isReview ? user.name : null,
    acceptedBy: isReview ? null : user.name,
    additionalComments: ''
  };

  const { mutateHasAcceptanceLog } = useFleetOverviewContext();
  const { companyListMutate } = useCompanyContext();

  const handleSubmit = useCallback(async (setFieldValue, status, submitForm) => {
    setFieldValue( 'acceptanceReviewStatus', status );
    await submitForm();
    if (status === AcceptanceReviewStatus.Pending || status === AcceptanceReviewStatus.Accepted) {
      setTimeout( () => {
        mutateHasAcceptanceLog?.();
        companyListMutate?.();
      }, 2000); //give server some time to return the right data
    }
  }, [mutateHasAcceptanceLog]);

  return (
    <Wrapper>
      <Dialog>
        <Button type='button' onClick={onCancel}>
          x
        </Button>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchemaAcceptDialog}
          initialTouched={{
            acceptanceDateFrom: true,
            AcceptanceDateTo: true,
            additionalComments: true,
          }}
          onSubmit={onAccept}
        >
          {({ values, handleChange, handleBlur, errors, touched, dirty, isValid, setFieldValue, submitForm }) => {
            return (
              <FormWrapper>
                <Title>
                  <span>{text}</span>
                </Title>
                {forType && (
                  <TopBox>
                    <TopHeader>
                      <Label label={forType.roleName} fontSize='12px' />
                      <Label label='RISK NUMBER' fontSize='12px' />
                      <Label label='COVER NOTE' fontSize='12px' />
                    </TopHeader>
                    <Divider />
                    <TopDetail>
                      <Label label={values.forDescription} fontWeight='normal' />
                      <Label label={values.riskNumber} fontWeight='normal' />
                      <Label label={values.coverNote} fontWeight='normal' />
                    </TopDetail>
                  </TopBox>
                )}

                {highRisks?.length && (
                  <TopBox>
                      <TopHeader>
                        <Label label='Manual high risk' fontSize='12px'  />
                        <Label label='Added by' fontSize='12px' />
                        <Label label='Added at' fontSize='12px' />
                      </TopHeader>
                    {
                      highRisks.map((risk, index) => (
                        <Fragment key={index}>
                          <Divider />
                          <TopDetail>
                            <Label label={risk.comments} fontWeight='normal' />
                            <Label label={userNames[risk.userId]} fontWeight='normal' />
                            <Label label={getFriendlyDateTime(risk.createdAt)} fontWeight='normal' />
                          </TopDetail>
                        </Fragment>
                      ))
                    }
                  </TopBox>
                )}

                {isReview && (
                  <Row>
                    <div>
                      <Label label={labelText} />
                      <TextAreaField
                        name='reviewComments'
                        value={pendingReview.comments}
                        disabled
                      />
                    </div>
                  </Row>
                )}

                <DateRow>
                  <div>
                    <Label label='Acceptance Date' />
                    <DatePicker
                      name='acceptanceDateFrom'
                      placeholder='from'
                      disabled={isReview}
                      value={values.acceptanceDateFrom}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                  </div>

                  <div>
                    <Label label='To' />
                    <DatePicker
                      name='acceptanceDateTo'
                      placeholder='to'
                      disabled={isReview}
                      value={values.acceptanceDateTo}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </DateRow>

                <Row>
                  <div>
                    <Label label='User' />
                    <ReadOnlyInput value={isReview ? values.reviewedBy : values.acceptedBy} />
                  </div>
                </Row>

                {isReview && (
                  <Row>
                    <div>
                      <Label label='Proposed by' />
                      <ReadOnlyInput value={proposedBy} />
                    </div>
                  </Row>
                )}

                {isProposal && (
                  <Row>
                    <div>
                      <Dropdown
                        label='Request reviews from'
                        multiDropdown
                        name='reviewerSubjectIds'
                        placeholder='None selected'
                        options={reviewerOptions}
                      />
                    </div>
                  </Row>
                )}

                <Row>
                  <div>
                    <Label label={reviewLabelText || labelText} />
                    <TextAreaField
                      name='additionalComments'
                      value={values.additionalComments}
                      placeholder='Describe reason here'
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Row>

                <div>
                  <ButtonRow>
                    <BoxButton
                      aria-label='Cancel'
                      onClick={onCancel}
                      padding='10px 20px'
                      title=''
                      type='button'
                      inverted
                    >
                      {cancelButtonText.toUpperCase()}
                    </BoxButton>
                    <ButtonRowSection>
                      {isReview && (
                        <BoxButton
                          aria-label='Reject'
                          onClick={() => handleSubmit(setFieldValue, AcceptanceReviewStatus.Rejected, submitForm)}
                          disabled={!dirty || !isValid}
                          type='button'
                        >
                          {rejectButtonText.toUpperCase()}
                        </BoxButton>
                      )}
                      <BoxButton
                        aria-label='Accept missing info'
                        onClick={() => handleSubmit(setFieldValue, isReview ? AcceptanceReviewStatus.Accepted : AcceptanceReviewStatus.Pending, submitForm)}
                        disabled={!dirty || !isValid}
                        type='button'
                        className={isMissingInfo ? 'acceptMissingInfoButton' : 'acceptHighRiskButton'}
                      >
                        {acceptButtonText.toUpperCase()}
                      </BoxButton>
                    </ButtonRowSection>
                  </ButtonRow>
                </div>
              </FormWrapper>
            );
          }}
        </Formik>
      </Dialog>
    </Wrapper>
  );
};

AcceptModalDialog.propTypes = {
  text: PropTypes.string,
  labelText: PropTypes.node,
  reviewLabelText: PropTypes.node,
  forDescription: PropTypes.string,
  acceptanceDateFrom: PropTypes.string,
  acceptanceDateTo: PropTypes.string,
  riskNumber: PropTypes.number,
  coverNote: PropTypes.number,
  pendingReview: PropTypes.object,
  highRisks: PropTypes.array,
  cancelButtonText: PropTypes.string.isRequired,
  acceptButtonText: PropTypes.string.isRequired,
  rejectButtonText: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReject: PropTypes.func,
};
