import React from 'react';
import styled from 'styled-components';
import { OhNo, Birds, Wave } from '@instech/icons';

const IconsWrapper = styled.div`
  margin: 0 auto;
  margin-top: 48px;
`;
const StyledOhNo = styled(OhNo)``;
const StyledBirds = styled(Birds)`
  margin-bottom: 200px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  background-color: ${(props) => props.theme.border.white};
`;
const Content = styled.div`
  background-color: ${(props) => props.theme.border.blueGray};
  height: 100vh;
  margin-top: -48px;
`;
const Title = styled.h1`
  color: ${(props) => props.theme.marineBlue};
  font-size: 64px;
  text-align: center;
`;
const Text = styled.div`
  font-size: 2em;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

export const DefaultErrorMessage = () => (
  <Container>
    <IconsWrapper>
      <StyledOhNo />
      <StyledBirds />
    </IconsWrapper>
    <Wave />
    <Content>
      <Title>Oh no...</Title>
      <Text>Something went wrong</Text>
    </Content>
  </Container>
);
