import useSWR from 'swr';

export type ManualHighRiskForFleet = {
  id: string;
  createdAt: string;
  userId: string;
  comments: string;
  accepted: boolean;
  entityType: "Fleet";
  fleetId: string;
  _etag: string;
}

export const useManualHighRisksForFleet = (fleetId: string) =>
  useSWR<ManualHighRiskForFleet[]>(`fleets/${fleetId}/manual-high-risks`);
