import React from 'react';
import styled from 'styled-components';
import { SearchFleet } from './SearchFleet';
import { PostBindTabs } from '../fleetsList/PostBindTabs';

const Wrapper = styled.div`
  margin: 32px 64px;
  h1 {
    color: ${ (props) => props.theme.marineBlue };
    font-size: 24px;
    font-weight: bold;
  }
`;
const SearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  align-items: center;
  align-content: flex-end;
  margin: 32px 0;
  @media (max-width: 950px) {
    grid-template-columns: auto;
  }
`;

export const PostBindFleetsPage = () => {

  return (
    <Wrapper>
      <SearchWrapper>
        <h1>POST-BIND FLEETS</h1>
        <SearchFleet isQuoted={false} />
      </SearchWrapper>
      <PostBindTabs />
    </Wrapper>
  );
};
