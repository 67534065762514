import React from 'react';
import styled from 'styled-components';
import { Stop, Birds, Wave } from '@instech/icons';

const StyledStop = styled(Stop)`
  margin: 0 auto;
  margin-top: -48px;
`;
const StyledBirds = styled(Birds)`
  margin: 0 auto;
  padding: 32px 0 0 100px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  background-color: ${(props) => props.theme.border.white};
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.border.blueGray};
  height: 100vh;
  margin-top: -48px;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.marineBlue};
  font-size: 64px;
  text-align: center;
`;
const Text = styled.div`
  font-size: 2em;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;
const Subtext = styled.p`
  font-weight: bold;
`;

export const NotAuthorizedErrorMessage = () => (
  <Container>
    <StyledBirds />
    <StyledStop />
    <Wave />
    <Content>
      <Title>Unauthorized</Title>
      <Text>
        The request has not been applied because it lacks valid authentication credentials for the
        target resource.
        <Subtext>Contact Help-Desk</Subtext>
      </Text>
    </Content>
  </Container>
);
