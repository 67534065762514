import { useState } from 'react';
import { useAppContext } from '../appRouting/AppContext';
import { useFleetItemsList, createRequestParams } from '../../services/useFleetItemsList';
import { useQuotedFleetValidationTasks } from '../../services/useValidationTasks';

const setOffset = (currentPage, limit) => (currentPage - 1) * limit;

export const usePreBindFleets = () => {
  const { state } = useAppContext();
  const sections = state.sectionsForQuoted;
  const quotedFleetsCurrentPage = state.currentPage.forQuoted;

  const caseHandlerEmails = state.caseHandlerEmailsForQuoted;
  const riskLevel = state.riskLevel.forQuoted;

  const [sort, setSort] = useState('name');
  const [fieldsSortOrder, setFieldsSortOrder] = useState({
    name: 'asc',
    riskLevel: 'desc',
  });
  const sortOrder = fieldsSortOrder[sort] === 'asc' ? `entity.${ sort }` : `-entity.${ sort }`;

  const handleSort = (sort) => {
    setSort(sort);
    setOffset(0);
    setFieldsSortOrder({
      ...fieldsSortOrder,
      [sort]: fieldsSortOrder[sort] === 'asc' ? 'desc' : 'asc',
    });
  };

  const fleetsLimit = 8;

  const searchParams = {
    caseHandlerEmails,
    onlySnapshots: true,
    limit: fleetsLimit,
    sectionCodes: sections,
  };

  /////////////////////QUOTED FLEETS/////////////////////////////////

  const quotedFleetsParams = {
    riskLevel,
    sort: [sortOrder],
    offset: setOffset(quotedFleetsCurrentPage, fleetsLimit),
    onlySnapshots: true,
    ...searchParams,
    ...createRequestParams(riskLevel)
  };
  const {
    fleets: quotedFleets,
    mutate: mutateQuotedFleetsList,
    totalCount: quotedFleetsTotalCount,
  } = useFleetItemsList(quotedFleetsParams);

  /////////////////////LAST IMPORTED FLEETS/////////////////////////////////
  const lastImportedFleetsParams = {
    limit: 8,
    onlySnapshots: true,
    sort: ['-createdTime'],
  };
  const { fleets: lastImportedFleets, mutate: mutateLastImportedFleetsList } = useFleetItemsList(
    lastImportedFleetsParams
  );

  const {
    data: quotedFleetValidationTasks,
    mutate: mutateQuotedFleetValidationTasks,
  } = useQuotedFleetValidationTasks();

  return {
    fleetsLimit,
    quotedFleets,
    lastImportedFleets,
    mutateQuotedFleetsList,
    mutateLastImportedFleetsList,
    quotedFleetValidationTasks,
    mutateQuotedFleetValidationTasks,
    quotedFleetsTotalCount,
    handleSort
  };
}