import React, { useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DownArrow, Load, Add } from '@instech/icons';
import { Vessel } from './Vessel';
import { VesselData } from './VesselData';
import { VesselsListHeader } from './VesselsListHeader';
import { BoxButton } from '@instech/components';
import { useFleetOverviewContext } from '../fleetDetails/FleetOverviewContext';

const StyledLoad = styled(Load)`
  height: 20px;
  align-self: center;
  margin: 32px;
`;
const ArrowDown = styled(DownArrow)`
  margin-right: 10px;
  color: ${(props) => props.theme.marineBlue};
`;
const ArrowUp = styled(ArrowDown)`
  transform: rotate(180deg);
  color: ${(props) => props.theme.marineBlue};
`;
const Wrapper = styled.div`
  padding: 20px;
  margin-top: 32px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
`;
const VesselsTable = styled.div`
  border: 2px solid ${(props) => props.theme.marineBlue50};
`;
const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  transition: 0.3s;
  color: ${(props) => props.theme.marineBlue};
  background-color: ${(props) => (props.$isEvenRow ? props.theme.white : props.theme.flatWhite)};

  &:hover {
    cursor: pointer;
  }
`;
const ButtonsWrapper = styled.div`
  button {
    border: none;
    box-shadow: none;
  }
`;

export const VesselsList = () => {
  const {
    openVesselId,
    fleetName,
    fleetId,
    insuranceYear,
    clickVesselHandler,
    isCoAssured,
    mutateFleetDetails,
    fleetCovers,
    limit,
    insuredObjects,
    hasMore,
    loading,
    mutateVesselList,
    setOffset,
    handleSort,
    fleetClaims,
    handleBeneficialOwnerWizardOpen,
    mutateWizardValidationTasks,
    locationState,
    setLocationState,
    mutatePETIs
  } = useFleetOverviewContext();

  const observer = useRef();

  const lastInsuredObjectElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setOffset((prevOffset) => prevOffset + limit);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, setOffset, limit]
  );

  const onUpdate = () => {
    mutateVesselList && mutateVesselList();
    mutatePETIs && mutatePETIs();
  }

  return (
    <Wrapper>
      <ButtonsWrapper>
        <BoxButton
          data-test-id="add-owner-to-objects"
          aria-label="Add Owner to objects"
          onClick={handleBeneficialOwnerWizardOpen}
          padding="10px 20px"
          startIcon={<Add />}
          type="button"
          inverted
        >
          ADD OWNER TO OBJECTS
        </BoxButton>
        <Link to={{ pathname: `/newCompanyForMultipleVessels/${fleetId}` }} style={{ alignSelf: 'center' }}>
          <BoxButton
            data-test-id="add-other-role-to-objects"
            aria-label="Add Other Role To Objects"
            padding="10px 20px"
            startIcon={<Add />}
            type="button"
            inverted
          >
            ADD OTHER ROLE TO OBJECTS
          </BoxButton>
        </Link>
      </ButtonsWrapper>
      <VesselsListHeader handleSort={handleSort} />
      <VesselsTable>
        {insuredObjects &&
          insuredObjects.map((item, index) => {
            return (
              <div key={index}>
                <Item
                  data-test-id="vessel-info"
                  {...(insuredObjects.length === index + 1 ? { ref: lastInsuredObjectElementRef } : {})}
                  onClick={() => clickVesselHandler(item.id)}
                  $isEvenRow={index % 2 === 0}
                >
                  <Vessel item={item} claims={fleetClaims} fleetCovers={fleetCovers} />
                  {openVesselId === item.id ? <ArrowUp /> : <ArrowDown />}
                </Item>
                {openVesselId === item.id && (
                  <VesselData
                    fleetId={item.fleetId}
                    fleetName={fleetName}
                    insuranceYear={insuranceYear}
                    vesselId={item.id}
                    onUpdate={onUpdate}
                    mutateFleetDetails={mutateFleetDetails}
                    isCoAssured={isCoAssured}
                    fleetCovers={fleetCovers}
                    claims={fleetClaims}
                    mutateWizardValidationTasks={mutateWizardValidationTasks}
                    locationState={locationState}
                    setLocationState={setLocationState}
                  />
                )}
              </div>
            );
          })}
        {loading && <StyledLoad />}
      </VesselsTable>
    </Wrapper>
  );
};
