import React from 'react';
import styled from 'styled-components';
import { CompanyForm } from './CompanyForm/CompanyForm';
import { useFleetDetails } from '../../services/useFleetDetails';
import { useInsuredObjectDetails } from '../../services/useInsuredObjectDetails';
import { newCompanyInitialValues } from './newCompanyInitialValues';
import { useParams, Link } from 'react-router-dom';
import { LeftArrow } from '@instech/icons';
import { CompanyProvider } from './CompanyContext';

const Wrapper = styled.div`
  margin: 32px;
`;

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  margin-left: -16px;
`;
const BreadcrumbsItem = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue75};
`;
const Subtitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
`;
const BreadcrumbsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.marineBlue};
`;

export const NewCompany = () => {
  const activePeriod = {
    from: '',
    to: '',
  };

  const { fleetId, vesselId, roleHeader } = useParams();

  const { data } = useFleetDetails(fleetId);
  const fleet = data;
  const fleetName = fleet?.name;

  const { data: vesselDetails } = useInsuredObjectDetails(fleetId, vesselId);
  const organizations = vesselDetails && vesselDetails.organizations;
  const organizationsListForChecking =
    organizations && organizations.length > 0
      ? roleHeader === 'Co-assured'
        ? organizations.filter((org) => org.roleType.isCoAssured)
        : organizations.filter((org) => !org.roleType.isCoAssured)
      : null;

  return (
    <Wrapper>
      <BreadcrumbsWrapper>
        <Breadcrumbs>
          <Link
            data-test-id="link-to-fleet"
            to={{
              pathname: `/fleets/${fleetId}`,
              vesselId: vesselId,
            }}
          >
            <LeftArrow />
          </Link>
          <BreadcrumbsItem>
            <Title>/ Fleet</Title>
            <Subtitle>{fleetName}</Subtitle>
          </BreadcrumbsItem>
        </Breadcrumbs>
      </BreadcrumbsWrapper>
      <CompanyProvider
        newCompanyOrganization={newCompanyInitialValues}
        activePeriod={activePeriod}
        fleetId={fleetId}
        isNewCompany={true}
        vesselId={vesselId}
        fleetName={fleetName}
        roleHeader={roleHeader}
        isCoAssured={roleHeader === 'Co-assured'}
        isNewRoleType={true}
        organizationsListForChecking={organizationsListForChecking}
      >
        <CompanyForm />
      </CompanyProvider>
    </Wrapper>
  );
};
