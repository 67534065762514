import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useInsuredObjectsFullList } from '../../services/useInsuredObjects';
import { Formik, Form } from 'formik';
import { groupBy } from '../../utils/groupBy';
import { BoxButton } from '@instech/components';
import { ValidationSchemaForMultipleVesselsStepTwo } from './validationSchema';
import { VesselsTableForMultipleChoice } from './VesselsTableForMultipleChoice';
import { sortInterests } from '../../utils/sortInterests';
import { ArrowBack, ArrowForward } from '@instech/icons';

const Wrapper = styled.div`
  padding: 20px;
  margin-top: 32px;
  background: ${(props) => props.theme.white};
`;
const Checkbox = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.marineBlue};
  padding: 0 10px;
`;
const NavigateButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;
const Error = styled.div`
  color: red;
  padding-top: 20px;
  text-align: right;
`;

export const NewCompanyForMultipleVesselsStepTwo = ({
  handleStepForward,
  handleStepBack,
  fleetId,
  fleetCovers,
  formValues,
  setVesselsList,
  fieldsSortOrder,
  handleSort,
  sortOrder,
  claims,
}) => {
  const [objectIds, setObjectIds] = useState([]);
  const [interestCodesByObjectId, setInterestCodesByObjectId] = useState(null);

  const vesselsList = useInsuredObjectsFullList(fleetId, sortOrder, fieldsSortOrder);

  vesselsList && vesselsList.length > 0 && setVesselsList(vesselsList);

  const handleSubmit = (values) => {
    console.log(values);
  };

  const vesselsInterestsInitialValues = {};
  vesselsList &&
    vesselsList.length > 0 &&
    vesselsList.forEach((object) => {
      vesselsInterestsInitialValues[object.id] = object.interestCodes &&
        object.interestCodes.length > 0 && [...new Set(object.interestCodes.map((c) => c.name))];
    });

  const groupedFleetCovers =
    fleetCovers && fleetCovers.length > 0 ? groupBy(fleetCovers, (c) => c.interestCode.name) : [];

  groupedFleetCovers && groupedFleetCovers.length > 0 && groupedFleetCovers.sort((a, b) => sortInterests(a.key, b.key));

  const initialValues = formValues.vessels || {
    chooseAllVessels: false,
    objectIds,
    interestCodesByObjectId: interestCodesByObjectId || vesselsInterestsInitialValues,
  };
  const vesselsListIds = vesselsList && vesselsList.length > 0 ? vesselsList.map((v) => v.id) : null;
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={ValidationSchemaForMultipleVesselsStepTwo}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({ values, setFieldValue, handleChange }) => {
        const handleCheckVessel = (e) => {
          handleChange(e);
          !e.target.checked && setFieldValue('chooseAllVessels', false);
        };
        return (
          <>
            <Wrapper>
              <Form>
                <Checkbox>
                  <input
                    name="chooseAllVessels"
                    type="checkbox"
                    checked={values.chooseAllVessels}
                    onChange={() => {
                      if (!values.chooseAllVessels) {
                        setFieldValue('objectIds', vesselsListIds);
                      } else {
                        setFieldValue('objectIds', []);
                      }
                      setFieldValue('chooseAllVessels', !values.chooseAllVessels);
                    }}
                  />
                  select all vessels
                </Checkbox>
                <VesselsTableForMultipleChoice
                  vesselsList={vesselsList}
                  handleCheckVessel={handleCheckVessel}
                  values={values}
                  hasCheckBox={true}
                  handleSort={handleSort}
                  sortOrder={sortOrder}
                  claims={claims}
                  objectIds={values.objectIds}
                  setObjectIds={setObjectIds}
                  interestCodesByObjectId={values.interestCodesByObjectId}
                  setInterestCodesByObjectId={setInterestCodesByObjectId}
                />
              </Form>
            </Wrapper>
            {values.objectIds.length === 0 && <Error>At least one object must be selected in order to continue</Error>}
            <NavigateButtons>
              <BoxButton
                data-test-id="navigate-back"
                aria-label="Back"
                inverted
                onClick={handleStepBack}
                padding="10px 20px"
                startIcon={<ArrowBack />}
                type="button"
              >
                BACK
              </BoxButton>
              <BoxButton
                data-test-id="navigate-forward"
                aria-label="Next"
                disabled={values.objectIds.length === 0}
                onClick={() => {
                  handleStepForward(values);
                }}
                padding="10px 20px"
                endIcon={<ArrowForward />}
                type="button"
              >
                NEXT
              </BoxButton>
            </NavigateButtons>
          </>
        );
      }}
    </Formik>
  );
};

NewCompanyForMultipleVesselsStepTwo.propTypes = {
  handleStepForward: PropTypes.func,
  handleStepBack: PropTypes.func,
  fleetId: PropTypes.string,
  fleetCovers: PropTypes.array,
  formValues: PropTypes.object,
  setVesselsList: PropTypes.func,
  sortOrder: PropTypes.string,
  fieldsSortOrder: PropTypes.object,
  handleSort: PropTypes.func,
  claims: PropTypes.array,
};
