import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CompanyRow } from '../../components/beneficialOwnerWizard/CompanyRow';
import { Pagination } from '../shared/Pagination';
import { RadioButton } from '../shared/formFields/RadioButton';

const Wrapper = styled.div`
  text-align: left;
  margin: 0 32px;
`;

const CompaniesSection = styled.div`
  border: 1px solid ${ (props) => props.theme.lightBlue };
`;
const SectionTitle = styled.div`
  font-weight: bold;
  margin-top: 32px;
`;
const UncheckAllCompaniesWrapper = styled.div`
  margin: 24px 0 24px 9px;
  display: flex;
  align-items: center;
`;

export const SimilarOwners = ({
  selectedOwners,
  handleChange,
  checkedPages,
  setCheckedPages,
  groupedOwnersWithSimilar,
  unselectAllIds,
  handleUnselectAll,
  currentPage,
  setCurrentPage,
  mainCompanyId,
  companyIndex,
}) => {
  useEffect(() => {
    if (
      !!selectedOwners.find((s) => s.company.id === mainCompanyId) ||
      selectedOwners.some(
        (s) =>
          groupedOwnersWithSimilar[companyIndex].similarKycOrganizations.map((s) => s.id).includes(s.company.id) &&
          s.company.mainCompany === mainCompanyId
      )
    ) {
      !checkedPages.includes(currentPage) && setCheckedPages((prev) => [...prev, currentPage]);
    }
  }, [
    selectedOwners,
    groupedOwnersWithSimilar,
    companyIndex,
    currentPage,
    checkedPages,
    setCheckedPages,
    mainCompanyId,
  ]);
  return (
    <Wrapper>
      <Pagination
        numberOfPages={groupedOwnersWithSimilar?.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        checkedPages={checkedPages}
      />
      <SectionTitle>Either - Select existing company from the KYC-portal.</SectionTitle>
      <CompaniesSection>
        {groupedOwnersWithSimilar[companyIndex].similarKycOrganizations?.map((s, index) => {
          s.mainCompany = mainCompanyId;
          return (
            <CompanyRow
              key={index}
              index={index}
              company={s}
              companySource='KYC'
              name={mainCompanyId}
              handleChange={handleChange}
              checked={selectedOwners.some((c) => c.company.id === s.id && c.company.mainCompany === mainCompanyId)}
            />
          );
        })}
      </CompaniesSection>
      <SectionTitle>Or - Transfer new from LLI.</SectionTitle>
      <CompaniesSection>
        <div>
          <CompanyRow
            company={groupedOwnersWithSimilar[companyIndex]}
            companySource='LLI'
            name={mainCompanyId}
            handleChange={handleChange}
            checked={selectedOwners.some((c) => c.company.id === mainCompanyId)}
          />
        </div>
      </CompaniesSection>
      <UncheckAllCompaniesWrapper>
        <RadioButton
          name={mainCompanyId}
          onChange={() => handleUnselectAll(mainCompanyId, currentPage)}
          checked={
            unselectAllIds.includes(mainCompanyId) &&
            !selectedOwners.some((c) => c.company.id === mainCompanyId || c.company.mainCompany === mainCompanyId)
          }
        />
        <div>Unselect All</div>
      </UncheckAllCompaniesWrapper>
    </Wrapper>
  );
};

SimilarOwners.propTypes = {
  selectedOwners: PropTypes.array,
  handleChange: PropTypes.func,
  checkedPages: PropTypes.array,
  setCheckedPages: PropTypes.func,
  groupedOwnersWithSimilar: PropTypes.array,
  unselectAllIds: PropTypes.array,
  handleUnselectAll: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  mainCompanyId: PropTypes.string,
  companyIndex: PropTypes.number,
};
