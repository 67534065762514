import React from 'react';
import styled from 'styled-components';
import { Approved } from '@instech/icons';

const StyledApproved = styled(Approved)`
  color: ${(props) => props.theme.marineBlue};
  background: ${(props) => props.theme.status.green};
  padding: 10px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(115, 198, 161, 0.4);
  margin: 32px 0;
  color: ${(props) => props.theme.marineBlue};
`;

const Message = styled.div`
  margin-left: 8px;
`;

export const ApprovedNotification = ({ text }) => {
  return (
    <Wrapper>
      <StyledApproved />
      <Message>{text}</Message>
    </Wrapper>
  );
};
