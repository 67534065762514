import { postNewRole, putRole, deletePersonRole } from 'services/postPutPersonData';
import { formatErrorMessage } from '../../utils/errorMessageFormatter';

export const removePersonRole = async (index, remove, personRoleId, etag, isUbo, fleetId, objectId, organizationId=null, setIsRemoving, setIsDirty, mutate, mutateVesselList, mutatePersonRoles) => {
  const params = {isUbo, organizationId, fleetId, objectId, personRoleId};
  setIsRemoving && setIsRemoving(true);
  if (personRoleId) {
    try {
      await deletePersonRole(params, etag);      
      index && remove(index);
    } catch (error) {
      console.error('delete role error', error);
    }
  } else {
    (!!index || index === 0) && remove(index);
  }
  setIsRemoving && setIsRemoving(false);
  setIsDirty && setIsDirty(false);
  mutate && mutate();
  mutateVesselList && mutateVesselList();
  mutatePersonRoles && mutatePersonRoles();
};

export const processPostNewRole = async (
  restRoles,
  etag,
  setIsError,
  setErrorMessage,
  setIsSaving,
  setSubmittedIds,
  setErrorIds,
  setIsSubmitted
) => {
  let latestEtag = etag;
  setIsSaving && setIsSaving(true);
  setIsError && setIsError(false);
  setErrorMessage(null);
  for (const [index, role] of restRoles.entries()) {
    try {
      const response = await postNewRole(role, latestEtag);
      latestEtag = response._etag;
      setSubmittedIds &&
        setSubmittedIds((prevState) => (!!role.roleIndex ? [...prevState, role.roleIndex] : [...prevState, index]));
      setIsSaving && setIsSaving(false);
    } catch (error) {
      setErrorIds &&
        setErrorIds((prevState) => (!!role.roleIndex ? [...prevState, role.roleIndex] : [...prevState, index]));
      setIsError && setIsError(true);
      setIsSaving && setIsSaving(false);
      setErrorMessage(formatErrorMessage(error));
      console.log('post role error', error);
    }
  }
  setIsSubmitted && setIsSubmitted(true);
  return latestEtag;
};

export const processPutNewRole = async (updatePersonRoles, etag, setIsError, setErrorMessage, setIsSaving) => {
  let latestEtag = etag;
  setIsSaving && setIsSaving(true);
  setIsError && setIsError(false);
  setErrorMessage(null);
  for (const role of updatePersonRoles) {
    try {
      const response = await putRole(role, latestEtag);
      latestEtag = response._etag;
    } catch (error) {
      setIsError && setIsError(true);
      setErrorMessage(formatErrorMessage(error));
      console.log('put role error', error);
    }
  }

  return latestEtag;
};
