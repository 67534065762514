import styled, { css } from 'styled-components';

export const Tab = styled.div<{ $selected: boolean; $highlight?: string }>`
  border: none;
  background: ${(props) => (props.$selected ? props.theme.white : props.theme.flatWhite)};
  border-top: 5px solid ${(props) => props.$highlight};
  color: ${(props) => (props.$selected ? props.theme.marineBlue : props.theme.marineBlue50)};
  font-weight: ${(props) => (props.$selected ? '600' : 'normal')};
  font-size: 14px;
  white-space: nowrap;
  padding: 8px 24px;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 4px 4px 0 0;
  width: 12%;
  height: 60px;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
  margin-right: 6px;
  transition: height 0.1s linear;

  ${(props) =>
    props.$selected &&
    css`
      margin-top: 0;
      border-top: 5px solid ${props.$highlight};
      border-bottom: none;
      border-right: 2px solid ${props.theme.whiteBlue};
      height: 70px;
      color: ${props.theme.marineBlue};
    `}
`;
