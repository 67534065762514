import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ClaimsTag } from '../shared/ClaimsTag';
import { StatusIcons } from '../shared/kyc-icons/StatusIcons';
import { ParseValidationFlags } from "../../utils/validations/functions";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  padding: 0; 
`;
const FleetName = styled.div`
  display: flex;
  align-items: center;
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;
const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue75};
`;

export const FleetOverviewHeader = ({ item }) => {
  return (
    <Wrapper>
      <FleetName>
        <Item>
          <Title>Fleet</Title>
          <div>{`${item.name} (${item.insuranceYear})`}</div>
        </Item>
        {item.claims && item.claims.length >= 1 && (
          <ClaimsTag claimsCounter={item.claims.length} />
        )}
      </FleetName>
      <StatusIcons validations={ParseValidationFlags(item.validations)} entityType={'Fleet'} />
    </Wrapper>
  );
};

FleetOverviewHeader.propTypes = {
  item: PropTypes.object,
};
