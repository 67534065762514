import React from 'react';
import styled from 'styled-components';
import { PersonItem } from './PersonItem';
import { useSortableData } from '../../utils/useSortableData';
import { Enlarge } from '@instech/icons';
import { IconButton } from '@instech/components';
import { usePersonsPageContext } from '../projectTabs/PersonsPageContext';

const SortIcon = styled(Enlarge)`
  height: 15px;
  margin-top: 3px;
`;
const Wrapper = styled.div`
  color: ${(props) => props.theme.marineBlue};
  margin-top: 32px;
`;

const Header = styled.div`
  background: ${(props) => props.theme.flatWhite};
  padding: 16px 32px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr) 0.5fr 0.5fr;
  grid-gap: 32px;
  font-size: 14px;
  font-weight: bold;
`;
const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const Li = styled.li`
  transition: 0.3s;

  &:nth-child(even) {
    background: ${(props) => props.theme.flatWhite};
  }
  &:nth-child(odd) {
    background: ${(props) => props.theme.white};
  }
`;
const Span = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;
const NoResultMessage = styled.div`
  font-size: 36px;
  margin: 62px;
  text-align: center;
  color: ${(props) => props.theme.marineBlue};
`;

export const PersonItems = () => {
  const { persons } = usePersonsPageContext();

  const uniquePersons =
    persons && persons.length > 0
      ? [
          ...persons
            .reduce((a, c) => {
              a.set(c.id, c);
              return a;
            }, new Map())
            .values(),
        ]
      : [];

  const { items: sortedPersons, requestSort } = useSortableData(uniquePersons);

  return sortedPersons && sortedPersons.length > 0 ? (
    <Wrapper>
      <Header>
        <Span>
          Nationality
          <IconButton
            data-test-id="sort-by-nationality"
            ariaLabel="Sort by nationality"
            icon={<SortIcon />}
            onClick={() => requestSort('nationality.displayName')}
            type="button"
          />
        </Span>
        <Span>
          First Name
          <IconButton
            data-test-id="sort-by-name"
            ariaLabel="Sort by first name"
            icon={<SortIcon />}
            onClick={() => requestSort('firstName')}
            type="button"
          />
        </Span>
        <Span>
          Last Name
          <IconButton
            data-test-id="sort-by-surname"
            ariaLabel="Sort by last name"
            icon={<SortIcon />}
            onClick={() => requestSort('lastName')}
            type="button"
          />
        </Span>
        <span>Date of Birth</span>
        <span>PEP</span>
        <span></span>
      </Header>
      <Ul>
        {sortedPersons.map((role, index) => {
          return (
            <Li key={index}>
              <PersonItem item={role} />
            </Li>
          );
        })}
      </Ul>
    </Wrapper>
  ) : persons && persons.length === 0 ? (
    <NoResultMessage>no results found</NoResultMessage>
  ) : null;
};
