import { putJsonAsync, postJsonAsync } from './fetchConfig';

export const postNewLink = (organizationId, etag, values) =>
  postJsonAsync(`Organizations/${organizationId}/links`, values, {
    headers: { 'If-Match': etag },
  });

export const updateLink = (organizationId, linkId, etag, values) =>
  putJsonAsync(`Organizations/${organizationId}/links/${linkId}`, values, {
    headers: { 'If-Match': etag },
  });

export const deleteLink = (organizationId, linkId, etag) =>
  putJsonAsync(
    `Organizations/${organizationId}/links/${linkId}/delete`,
    { linkId },
    {
      headers: { 'If-Match': etag },
    }
  );
