import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BoxButton } from '@instech/components';
import { InputField } from '../shared/formFields/InputField';
import { Label } from '../shared/formFields/Label';
import { Formik } from 'formik';
import { ValidationSchemaLink } from '../company/validationSchema';
import { updateLink, postNewLink } from '../../services/useOrganizationLinks';
import { Load } from '@instech/icons';
import { formatErrorMessage } from '../../utils/errorMessageFormatter';
import { NIL as uuidNil } from 'uuid';

const StyledLoad = styled(Load)`
  height: 20px;
  align-self: center;
`;
const ErrorMessage = styled.div`
  color: red;
  grid-column: 3;
`;
const Wrapper = styled.div`
  color: ${ (props) => props.theme.marineBlue };
  width: 100%;
  background: ${ (props) => (props.isEmptyForm ? props.theme.white : null) };
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 16px;
  padding-bottom: 32px;
  align-items: start;
`;
const ButtonWrapper = styled.div`
  align-self: end;
  justify-self: left;
`;

export const LinkForm = ({ organizationId, etag, mutate, item, setEditLinkId, isEmptyForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const initialValues = {
    id: uuidNil,
    url: '',
    description: '',
  };

  const handleCreateLink = (values, resetForm) => {
    setIsLoading(true);
    setIsError(false);
    setErrorMessage(null);
    postNewLink(organizationId, etag, values).then(
      () => {
        setIsLoading(false);
        mutate();
        resetForm(initialValues);
      },
      (error) => {
        setIsLoading(false);
        setIsError(true);
        setErrorMessage(formatErrorMessage(error));
        console.log('post error', error);
      }
    );
  };

  const handleUpdateLink = (values, resetForm) => {
    setIsLoading(true);
    setIsError(false);
    setErrorMessage(null);
    updateLink(organizationId, item.id, etag, values).then(
      () => {
        setIsLoading(false);
        mutate();
        resetForm(item);
      },
      (error) => {
        setIsLoading(false);
        setIsError(true);
        setErrorMessage(formatErrorMessage(error));
        console.log('update error', error);
      }
    );
    setTimeout(() => {
      setEditLinkId(null);
    }, 1000);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={isEmptyForm ? initialValues : item}
      validationSchema={ValidationSchemaLink}
      validateOnBlur={true}
    >
      {({ values, handleChange, handleBlur, errors, touched, dirty, isValid, resetForm, setFieldTouched }) => {
        return (
          <Wrapper>
            <Row>
              <div>
                <Label label='Link to:' />
                <InputField
                  name='url'
                  value={values.url}
                  type='url'
                  placeholder='https://External Link Company'
                  handleChange={(e) => {
                    setFieldTouched('url');
                    handleChange(e);
                  }}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
              </div>
              <div>
                <Label label='Text to display  (optional):' />
                <InputField
                  name='description'
                  value={values.description}
                  type='text'
                  placeholder='Name this link (optional)'
                  handleChange={(e) => {
                    setFieldTouched('url');
                    handleChange(e);
                  }}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
              </div>

              <ButtonWrapper>
                {isLoading ? (
                  <StyledLoad />
                ) : (
                  <BoxButton
                    aria-label='Create link'
                    onClick={() =>
                      isEmptyForm ? handleCreateLink(values, resetForm) : handleUpdateLink(values, resetForm)
                    }
                    disabled={!dirty || !isValid}
                    type='button'
                    height='30'
                  >
                    {isEmptyForm ? 'CREATE LINK' : 'SAVE'}
                  </BoxButton>
                )}
              </ButtonWrapper>
              {isError && (
                <ErrorMessage>
                  <div>- Failed to save data.</div>
                  <div>{errorMessage}</div>
                </ErrorMessage>
              )}
            </Row>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

LinkForm.propTypes = {
  organizationId: PropTypes.string,
  etag: PropTypes.string,
  mutate: PropTypes.func,
  item: PropTypes.object,
  setEditLinkId: PropTypes.func,
  isEmptyForm: PropTypes.bool,
};
