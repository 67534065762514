import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const connection = new HubConnectionBuilder()
  .withUrl('/hubs/events')
  .withAutomaticReconnect()
  .configureLogging(LogLevel.Information)
  .build();

const start = async (retryCount = 0, navigate) => {
  try {
    await connection.start();
    console.info('Events hub connected');
  } catch (err) {
    console.error('Connection to events hub failed', err);
    if (retryCount <= 5) {
      const retryIn = 5000 * (retryCount + 1);
      console.info(`Trying to reconnect to events hub in ${retryIn / 1000}s`);
      setTimeout(() => start(retryCount + 1), retryIn);
    } else {
      navigate(0);
    }
  }
};

connection.onclose(async () => {
  console.info('Connection to events hub closed. Trying to reconnect.');
  await start();
});

// Use global start promise in order to only start connection once
let startPromise = null;

const useEventStream = (topic) => {
  const navigate = useNavigate()
  let onEventHandler = null;
  
  const onEvent = async (handler) => {
    if (onEventHandler) {
      connection.off('event', onEventHandler);
    }

    onEventHandler = (event) => {
      const startsWithTopic = topic?.replace('/*', '/');
      if (handler && event.topic.indexOf(startsWithTopic) === 0) {
        handler(event);
      }
    };

    if (connection.state !== HubConnectionState.Connected) {
      await startPromise;
    }

    connection.on('event', onEventHandler);
  };

  const eventHandlerCleanup = () => {
    if (onEventHandler) {
      connection.off('event', onEventHandler);
    }
  };

  let onConnectHandler = null;
  const onConnect = (handler) => {
    onConnectHandler = handler;
  };

  let onDisconnectHandler = null;
  const onDisconnect = (handler) => {
    onDisconnectHandler = handler;
  };

  const startConnection = useCallback(async () => {
    if (startPromise === null) {
      startPromise = start(0, navigate);
    }

    await startPromise;

    if (onConnectHandler) {
      onConnectHandler();
    }

    connection.onclose(() => {
      if (onDisconnectHandler) {
        onDisconnectHandler();
      }
    });

    connection.onreconnected(() => {
      if (onConnectHandler) {
        onConnectHandler();
      }

      if (connection.state === HubConnectionState.Connected) {
        connection.send('subscribe', topic);
      }
    });

    if (connection.state === HubConnectionState.Connected) {
      connection.send('subscribe', topic);
    }
  }, [topic, onConnectHandler, onDisconnectHandler]);

  useEffect(() => {
    if (topic) {
      startConnection();
    }
    return () => {
      if (topic) {
        startPromise.then(() => {
          if (connection.state === HubConnectionState.Connected) {
            connection.send('unsubscribe', topic);
          }
        });
      }
    };
  }, [topic, startConnection]);

  return { onEvent, onConnect, onDisconnect, eventHandlerCleanup };
};

export { useEventStream };
