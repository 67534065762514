import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InterestCodes } from '../interests/InterestCodes';
import { Field } from 'formik';
import { ClaimsTag } from '../shared/ClaimsTag';
import { StatusIcons } from '../shared/kyc-icons/StatusIcons';
import {ParseValidationFlags} from "../../utils/validations/functions";

const Wrapper = styled.div`
  display: grid;
  width: 92%;
  grid-template-columns:
    minmax(15rem, 30rem) minmax(8rem, 12rem) repeat(3, minmax(3rem, 10rem)) minmax(5rem, 10rem)
    minmax(15rem, 30rem); 
  align-content: center;
  align-items: center;
  grid-gap: 2rem;
  padding: 10px;
  position: relative;
`;
const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  align-items: center;
`;
const VesselNameWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.$grid ? '1.5fr 1fr' : '1fr')};
`;
const VesselName = styled.div`
  display: flex;
  align-items: center;
`;
const More = styled.span`
  color: #82b5ba;
  font-size: 12px;
  font-weight: bolder;
  align-self: center;
`;
const ClaimsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding-right: 8px;
`;
const LastClaimTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Vessel = ({ item, hasCheckBox, handleCheckVessel = null, claims, fleetCovers }) => {
  const fleetClaims = claims;
  const vesselClaims =
    item.claims &&
    item.claims.map((item) => {
      item.sectionCode = fleetClaims?.filter((x) => x.id === item.id)[0]?.sectionCode;
      return item;
    });
  return (
    <Wrapper>
      <VesselNameWrapper $grid={item.claims && item.claims.length >= 1}>
        <VesselName>
          {hasCheckBox && (
            <Checkbox>
              <Field type="checkbox" name="objectIds" value={item.id} onChange={handleCheckVessel} />
            </Checkbox>
          )}
          {item.name}
        </VesselName>
        {vesselClaims && vesselClaims.length >= 1 && (
          <ClaimsWrapper>
            <ClaimsTag
              tagWithClaimInfo
              claimNumber={vesselClaims[0]?.number}
              claimSection={vesselClaims[0]?.sectionCode}
            />
            {vesselClaims.length > 1 && (
              <LastClaimTagWrapper>
                <ClaimsTag
                  tagWithClaimInfo
                  claimNumber={vesselClaims[1]?.number}
                  claimSection={vesselClaims[1]?.sectionCode}
                />
                {item.claims.length > 2 && <More>+ {item.claims.length - 2}</More>}
              </LastClaimTagWrapper>
            )}
          </ClaimsWrapper>
        )}
      </VesselNameWrapper>
      <StatusIcons validations={ParseValidationFlags(item.validations)} entityType={"Vessel"} />
      <span>{item.imo}</span>
      <span>{item.flag}</span>
      <span>{item.type}</span>
      <span>{item.validations.riskLevel}</span>
      <span>
        <InterestCodes covers={item.covers || item.interestCodes} fleetCovers={fleetCovers} />
      </span>
    </Wrapper>
  );
};

Vessel.propTypes = {
  item: PropTypes.object,
  hasCheckBox: PropTypes.bool,
  handleCheckVessel: PropTypes.func,
  claims: PropTypes.array,
};
