import React from 'react';
import styled from 'styled-components';
import { UserMenuLoader } from './UserMenuLoader';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  a {
    text-decoration: none;
  }
`;

export const UserSection = () => {
  return (
    <Wrapper>
      <UserMenuLoader />
    </Wrapper>
  );
};

