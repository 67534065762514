import React from 'react';
import { useCompanyContext } from '../CompanyContext';
import { ConfirmModalDialog } from '../../shared/ConfirmModalDialog';
import { useCompanyFormHandlers } from '../useCompanyFormHandlers';

export const CompanyFormConfirmDialog = ({ dirty, formState, formDispatch }) => {
  const {
    showConfirmClose,
    showConfirmModal,
    setOpenClientId,
    setShowConfirmModal,
    setShowConfirmClose,
    mutate,
  } = useCompanyContext();
  const [
    keepChangesHandler,
    refreshHandler,
    keepConflictChangesHandler,
    refreshConflictHandler,
  ] = useCompanyFormHandlers(formDispatch, mutate);

  const handleModalCancelButton = () => {
    setShowConfirmModal(false);
  };
  const handleModalOkButton = () => {
    setOpenClientId(null);
    setShowConfirmModal(false);
    setShowConfirmClose(false);
  };
  const showConfirmRefreshNotification = formState.updatedBy ? `The company was updated on the server by ${ formState.updatedBy }` : 'The company was updated on the server.';
  const showSaveConflictNotification = 'Your updates to the company could not be saved because the company was updated by someone else while you did your updates.';
  const additionalNotification = 'Do you want to refresh with the latest data from the server, or keep your unsaved changes?';

  return (
    <>
      {showConfirmClose && showConfirmModal && (
        <ConfirmModalDialog
          text='Are you sure you want to leave? You have unsaved changes.'
          cancelButtonText='Cancel'
          confirmButtonText='Leave'
          onCancel={handleModalCancelButton}
          onConfirm={handleModalOkButton}
        />
      )}
      {dirty && formState.showConfirmRefresh && (
        <ConfirmModalDialog
          text={showConfirmRefreshNotification}
          additionalText={additionalNotification}
          cancelButtonText='Keep unsaved changes'
          confirmButtonText='Refresh'
          onCancel={keepChangesHandler}
          onConfirm={refreshHandler}
        />
      )}
      {formState.showSaveConflict && (
        <ConfirmModalDialog
          text={showSaveConflictNotification}
          additionalText={additionalNotification}
          cancelButtonText='Keep unsaved changes'
          confirmButtonText='Refresh'
          onCancel={keepConflictChangesHandler}
          onConfirm={refreshConflictHandler}
        />
      )}
    </>
  );
};
