import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.marineBlue50};
`;
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  text-align: left;
  margin-top: 16px;
`;
const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  margin: 8px 0;
  padding: 8px 0;
`;
const RowItem = styled.span`
  background: ${(props) => props.theme.ultraLightGray};
  width: 80px;
  height: 16px;
`;

export const TableTemplate = () => {
  return (
    <Wrapper>
      <TableHeader>
        <span>Interest Code</span>
        <span>Risk Status</span>
        <span>Risk Number</span>
      </TableHeader>
      <TableRow>
        <RowItem></RowItem>
        <RowItem></RowItem>
        <RowItem></RowItem>
      </TableRow>
      <TableRow>
        <RowItem></RowItem>
        <RowItem></RowItem>
        <RowItem></RowItem>
      </TableRow>
      <TableRow>
        <RowItem></RowItem>
        <RowItem></RowItem>
        <RowItem></RowItem>
      </TableRow>
    </Wrapper>
  );
};
