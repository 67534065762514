import React, { ReactNode, useMemo, useState } from 'react';
import { Pagination } from './Pagination';

type PagedContentProps<TItem> = {
  itemsPerPage: number;
  items: TItem[] | null | undefined;
  children: (pageItems: TItem[], pageNumber: number) => ReactNode
}
export const PagedContent = <TItem,>({ items, itemsPerPage, children }: PagedContentProps<TItem>) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const totalItemCount = items?.length || 0;
  const totalPageCount = totalItemCount % itemsPerPage > 0
  ? Math.floor(totalItemCount / itemsPerPage) + 1
  : Math.floor(totalItemCount / itemsPerPage);

  const currentPageItems = useMemo(() => {
    const startItemIndex = (currentPageNumber - 1) * itemsPerPage;
    return items ? items.slice(startItemIndex, startItemIndex + itemsPerPage) : [];
  }, [items, currentPageNumber, itemsPerPage]);

  return (
    <>
      {children(currentPageItems, currentPageNumber)}
      {totalItemCount > itemsPerPage && (
        <Pagination
          numberOfPages={totalPageCount}
          currentPage={currentPageNumber}
          setCurrentPage={setCurrentPageNumber}
          pageSize={itemsPerPage}
          totalCount={totalItemCount}
          />
      )}
    </>
  )
};
