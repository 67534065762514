import React, { useState, useCallback, useEffect } from 'react';
import { useEventStream } from '../../services/useEventStream';
import styled from 'styled-components';
import { ProgressBar } from '../shared/Progress';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  margin-top: 8px;
`;

export const BackgroundTaskProgressBar = ({
  eventStreamTopic,
  mutate,
  mutateVesselList,
  mutateVesselListForQuotedFleet,
  mutateQuotedFleetsList,
  mutateLastImportedFleetsList,
  titleText,
  eventName,
  forWizard,
  forQuotedFleet,
}) => {
  const [savingProgress, setSavingProgress] = useState({
    value: eventStreamTopic.progress,
    message: '',
    timestamp: null,
  });
  const eventStreamLink = forWizard
    ? `/${ eventName }/${ eventStreamTopic.data.commandSubject.id }/${ eventStreamTopic.data.commandSubject.id }/CommandProgress/${ eventStreamTopic.data.commandId }`
    : forQuotedFleet
      ? `/${ eventName }/${ eventStreamTopic.data.commandSubject.partitionKey }/${ eventStreamTopic.data.commandSubject.id }/CommandProgress/${ eventStreamTopic.data.commandId }`
      : `/${ eventName }/${ eventStreamTopic.data.entityId }/${ eventStreamTopic.data.entityId }/CommandProgress/${ eventStreamTopic.taskId }`;

  const { onEvent, onConnect, onDisconnect, eventHandlerCleanup } = useEventStream(eventStreamLink);

  onConnect(
    useCallback(() => {
      console.log('Connected to event stream for', eventStreamLink);
    }, [eventStreamLink])
  );

  onDisconnect(
    useCallback(() => {
      console.log('Disconnected from event stream for', eventStreamLink);
    }, [eventStreamLink])
  );

  useEffect(() => {
    onEvent((event) => {
      if (!!event && event.type.endsWith('ProgressReportEvent')) {
        //console.info('Received event on topic', eventStreamTopic, event);
        setSavingProgress((prev) =>
          prev.value > event.progress || (prev.timestamp && prev.timestamp > event.timestamp)
            ? prev
            : {
              value: event.progress,
              message: event.details || event.action,
              timestamp: event.timestamp,
            }
        );
        parseInt(event.progress) === 100 && mutateVesselList && mutateVesselList();
        parseInt(event.progress) === 100 && mutateVesselListForQuotedFleet && mutateVesselListForQuotedFleet();
        parseInt(event.progress) === 100 && mutateQuotedFleetsList && mutateQuotedFleetsList();
        parseInt(event.progress) === 100 && mutateLastImportedFleetsList && mutateLastImportedFleetsList();
      }
    });

    return eventHandlerCleanup;
  }, [mutateQuotedFleetsList, mutateLastImportedFleetsList, eventStreamTopic]);

  savingProgress.value === 100 && mutate();

  return (
    <Wrapper>
      <span>{titleText}</span>
      <ProgressBar
        color={'#003C71'}
        value={savingProgress.value}
        max={100}
        width='50%'
        message={savingProgress.message}
      />
    </Wrapper>
  );
};

BackgroundTaskProgressBar.propTypes = {
  eventStreamTopic: PropTypes.object,
  titleText: PropTypes.string,
  eventName: PropTypes.string,
  forWizard: PropTypes.bool,
  mutate: PropTypes.func,
  mutateVesselList: PropTypes.func,
  mutateVesselListForQuotedFleet: PropTypes.func,
  mutateQuotedFleetsList: PropTypes.func,
};
