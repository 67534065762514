import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { getIn } from 'formik';
import { baseTheme } from '@instech/components';

const Error = styled.span`
  color: red;
  font-size: 14px;
`;
export const AsyncSelectField = ({
  name,
  value,
  placeholder,
  handleChange,
  handleBlur,
  loadOptions,
  touched,
  errors,
  addName = null,
  isDisabled = false,
  formatOptionLabel = null,
  controlShouldRenderValue = true,
  forSearchBox,
  cachedOptions
}) => {
  const isError = getIn(touched, name) && getIn(errors, name);
  const isAddError =
    addName && getIn(touched, addName) && getIn(errors, addName);

  const customStyles = {
    container: provided => ({
      ...provided,
      fontFamily: 'Calibri',
      fontSize: '14px',
      color: baseTheme.marineBlue,
      outline: 'none',
      minWidth: '150px',
    }),
    option: provided => ({
      ...provided,
      'padding': '5px',
      'width': forSearchBox ? 'none' : '690px',
      //option styles
      //styles for spans inside options
      '& span': {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        width: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '& span:nth-of-type(1)': {
        width: '170px'
      },
      '& span:nth-of-type(2)': {
        width: '90px'
      },
      '& span:nth-of-type(3)': {
        width: '170px'
      },
      '& span:nth-of-type(4)': {
        width: '240px'
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: baseTheme.marineBlue50,
    }),
    control: (provided, state) => ({
      // select wrapper
      ...provided,
      'fontSize': '14px',
      'fontFamily': 'Calibri',
      'boxShadow': state.isFocused ? 0 : 0,
      'border':
        isError || isAddError
          ? '1px solid red'
          : state.isFocused || state.isSelected
            ? '1px solid  #003c71'
            : forSearchBox ? '1px solid transparent' : '1px solid#7f9db8',
      'boxSizing': 'border-box',
      'padding': '0',
      'borderRadius': '2px',
      'textAlign': 'left',
      'width': '100%',
      'outline': 'none',
      'transition': '0.4s',
      '&:hover': {
        border: '1px solid #003c71'
      },
      '& input': {
        font: 'inherit'
      }
    }),
    singleValue: provided => ({
      // selected value
      ...provided
    }),
    multiValue: provided => ({
      ...provided,
      background: 'none',
      fontSize: '18px'
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: baseTheme.marineBlue50,
      '&:hover': {
        color: baseTheme.marineBlue,
      },
    }),
    indicatorsContainer: provided => ({
      ...provided,
      '& div:last-of-type': {
        display: 'flex'
      },
      '& > div': {
        display: 'none'
      }
    }),
    valueContainer: provided => ({
      ...provided,
      fontSize: '16px',
      padding: '0',
      margin: '6px 5px 5px 5px'
    }),
    multiValueRemove: provided => ({
      ...provided,
      color: '#bccbd8',
      lineHeight: '14px'
    })
  };

  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (newValue, { action }) => {
    if (action !== 'input-blur' && action !== 'menu-close') {
      setInputValue(newValue);
    }
  };
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(cachedOptions); // Update options from cache when inputValue changes
  }, [cachedOptions, inputValue]);

  return (
    <div>
      <AsyncSelect
        controlShouldRenderValue={controlShouldRenderValue}
        formatOptionLabel={formatOptionLabel}
        isDisabled={isDisabled}
        placeholder={placeholder}
        value={value}
        name={name}
        loadOptions={loadOptions}
        styles={customStyles}
        onChange={option => {
          handleChange(name, option);
        }}
        onBlur={() => {
          handleBlur(name, true);
        }}
        forSearchBox={forSearchBox}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        defaultOptions={options}
      />
      {isError ? <Error>{getIn(errors, name)}</Error> : null}
      {isAddError ? <Error>{getIn(errors, addName)}</Error> : null}
    </div>
  );
};

AsyncSelectField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  loadOptions: PropTypes.func,
  borderBottom: PropTypes.string,
  placeholder: PropTypes.string,
  addName: PropTypes.string,
  isDisabled: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  controlShouldRenderValue: PropTypes.bool
};
