import React from 'react';
import PropTypes from 'prop-types';

const getSourceSystemName = (organization) => {

  // Organizations that have been imported from Gleif should show KYC in the first search result column
  if (organization.sourceSystem === 'Gleif' &&
      organization.id !== '00000000-0000-0000-0000-000000000000')
  {
    return 'KYC';
  }

  if (organization.sourceSystem === 'Ins2000')
  {
    return 'INS+';
  }

  switch (organization.sourceSystem.toUpperCase()) {
    case 'CUSTOMERFORM':
      return 'KYC-Form';
    default:
      return organization.sourceSystem.toUpperCase();
  }
};

// returns jsx with markup we need in options list
export const OrganizationLabel = (
  { organization },
  { context }
) => {
  if (context === 'value') return organization.name;
  return (
    <>
      <span>{getSourceSystemName(organization)} {organization.sourceSystem === 'Ins2000' ? organization.sourceId : ''}</span>
      <span>{organization.organizationNumber}</span>
      <span>{organization.lei}</span>
      <span>{organization.name}</span>
    </>
  );
};

OrganizationLabel.propTypes = {
  organizationNumber: PropTypes.string,
  lei: PropTypes.string,
  name: PropTypes.string,
};
