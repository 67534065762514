import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Label = styled.label`
  display: block;
  font-size: 1em;
`;
const Checkbox = styled.div`
  display: flex;
  border: 1px solid;
  border-color: ${(props) => props.theme.marineBlue};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 24px;
  max-width: 80px;
  cursor: pointer;
`;

const Yes = styled.div`
  background-color: ${(props) =>
    props.value === true ? props.theme.lightGreen : 'transparent'};
  border-radius: 2px;
`;

const No = styled.div`
  background-color: ${(props) =>
    props.value === false ? props.theme.lightRed : 'transparent'};
  border-radius: 2px;
  border-left: 1px solid;
  border-color: ${(props) => props.theme.marineBlue};
  padding-left: 2px;
`;

const Value = styled.div`
  height: 18px;
  width: 40px;
  text-align: center;
  user-select: none;
  &:hover {
    color: ${(props) => props.theme.marineBlue};
  }
`;

const setValue = (actualValue, expectedValue) => {
  if (actualValue === expectedValue) return null;
  if (actualValue === null) return expectedValue;
  return !actualValue;
};

export const TriStateCheckbox = ({ labelName, value, onClick }) => (
  <div>
    <Label>{labelName}</Label>
    <Checkbox>
      <Value>
        <Yes value={value} onClick={() => onClick(setValue(value, true))}>
          YES
        </Yes>
      </Value>
      <Value>
        <No value={value} onClick={() => onClick(setValue(value, false))}>
          NO
        </No>
      </Value>
    </Checkbox>
  </div>
);

TriStateCheckbox.propTypes = {
  labelName: PropTypes.string,
  value: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
