import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getIn } from 'formik';
import { baseTheme } from '@instech/components';

const Wrapper = styled.div`
  position: relative;
`;
const Input = styled.input`
  box-sizing: border-box;
  color: ${ baseTheme.marineBlue };
  font: inherit;
  border: 1px solid ${ (props) => (props.forSearchBox || props.forPersonSearch) ? 'transparent' : props.borderError || props.theme.marineBlue50 };
  padding: 9px;
  height: 40px;
  border-radius: 2px;
  text-align: left;
  width: 100%;
  outline: none;
  transition: 0.4s;
  &::placeholder {
    color: ${ baseTheme.marineBlue50 };
  }
  &:disabled {
    background-color: ${ baseTheme.lightGray };
  }
  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${ baseTheme.marineBlue };
  }
  :disabled {
    border: 1px solid ${ baseTheme.marineBlue50 };
  }
`;

const Error = styled.span`
  color: red;
  font-size: 14px;
  position: absolute;
  display: block;
  max-width: ${ (props) => (props.addressField ? '300px' : '200px') };
`;

export const InputField = ({
  name,
  value,
  type,
  placeholder,
  disabled,
  handleChange,
  handleBlur,
  onKeyDown,
  touched,
  errors,
  borderError,
  personError = null,
  shouldValidate = true,
  forSearchBox,
  forPersonSearch,
  setFieldTouched = null,
  addressField,
}) => {
  const showError = shouldValidate || (!shouldValidate && !!value);
  const border = getIn(touched, name) && getIn(errors, name) && showError && 'red';
  const isError = getIn(touched, name) && getIn(errors, name);
  return (
    <Wrapper>
      <Input
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          setFieldTouched && setFieldTouched(name);
          handleChange(e);
        }}
        onBlur={handleBlur}
        disabled={disabled}
        onKeyDown={onKeyDown}
        borderError={border || borderError}
        forSearchBox={forSearchBox}
        forPersonSearch={forPersonSearch}
      />
      {isError && showError && <Error addressField={addressField}>{getIn(errors, name)}</Error>}
      {personError ? personError : null}
    </Wrapper>
  );
};

export const ReadOnlyInput = ({ value }) => <Input value={value} disabled></Input>;

export const EmptyPlaceholderInputField = ({ placeholder }) => <Input placeholder={placeholder} disabled></Input>;

InputField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  borderError: PropTypes.string,
  personError: PropTypes.string,
  shouldValidate: PropTypes.bool,
};

EmptyPlaceholderInputField.propTypes = {
  placeholder: PropTypes.string,
};
