import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StatusIcon } from '../shared/kyc-icons/StatusIcon';
import { VesselOwners } from './VesselOwners';
import { MissingData } from '../../utils/validations/types';
import { HasFlag } from '../../utils/validations/functions';

const IconWrapper = styled.span`
  vertical-align: middle;
  margin-right: 5px;
  grid-column: 1;
`;
const Wrapper = styled.div`
  margin-bottom: 16px;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  align-content: center;
  align-items: center;
  grid-gap: 2rem;
  padding: 10px;
  position: relative;
  background-color: ${ (props) => (props.$isEvenRow ? props.theme.white : props.theme.flatWhite) };
`;
const VesselNameWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
`;
const VesselName = styled.div`
  display: flex;
  align-items: center;
  grid-column: 2;
`;

export const SelectedOwners = ({ selectedCompanies }) => {
  const selectedInsuredObjects = selectedCompanies.reduce((prev, current) => {
    const index = prev.findIndex((p) => p.object && p.object.id === current.objectId);
    if (index < 0) {
      const newItem = {
        object: {
          id: current.objectId,
          name: current.objectName,
          validations: current.validations,
        },
        beneficialOwners: current.roleType.roleName === 'Beneficial Owner' ? [current] : [],
        registeredOwners: current.roleType.roleName === 'Registered Owner' ? [current] : [],
      };
      prev.push(newItem);
    } else {
      current.roleType.roleName === 'Beneficial Owner'
        ? prev[index].beneficialOwners.push(current)
        : prev[index].registeredOwners.push(current);
    }
    return [...prev];
  }, []);

  return (
    <Wrapper>
      {selectedInsuredObjects &&
        selectedInsuredObjects.map((item, index) => {
          return (
            <Row key={index} $isEvenRow={index % 2 === 0}>
              <VesselNameWrapper>
                {HasFlag(item.object.validations?.missingData, MissingData.BeneficialOwner) && (
                  <IconWrapper>
                    <StatusIcon missingBOIcon text='Missing Beneficial Owner' />
                  </IconWrapper>
                )}
                <VesselName>{item.object.name}</VesselName>
              </VesselNameWrapper>
              <VesselOwners owners={item} roleType='beneficial' />
              <VesselOwners owners={item} roleType='registered' />
            </Row>
          );
        })}
    </Wrapper>
  );
};

SelectedOwners.propTypes = {
  selectedCompanies: PropTypes.array,
};
