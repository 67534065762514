import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BoxButton } from '@instech/components';
import { useCompanyContext } from '../CompanyContext';
import { Link } from 'react-router-dom';
import { ArrowBack, ArrowForward } from '@instech/icons';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CompanyFormNavigateButtons = ({ handleReset, values, notValidRoleType }) => {
  const { fleetId, isStepBack, currentStep, handleStepForward } = useCompanyContext();
  return (
    <Wrapper>
      {isStepBack && (
        <Link
          data-test-id="link-to-fleet"
          to={{
            pathname: `fleets/${fleetId}`,
          }}
        >
          <BoxButton
            data-test-id="navigate-back"
            aria-label="Back"
            inverted
            onClick={handleReset}
            padding="10px 20px"
            startIcon={<ArrowBack />}
            type="button"
          >
            BACK
          </BoxButton>
        </Link>
      )}
      {!(currentStep === 1) ||
        (isStepBack && (
          <BoxButton
            data-test-id="navigate-back"
            aria-label="Back"
            inverted
            onClick={handleReset}
            padding="10px 20px"
            startIcon={<ArrowBack />}
            type="button"
          >
            BACK
          </BoxButton>
        ))}
      <div></div>
      <BoxButton
        data-test-id="navigate-forward"
        aria-label="Next"
        disabled={notValidRoleType}
        onClick={() => {
          handleStepForward(values);
        }}
        padding="10px 20px"
        endIcon={<ArrowForward />}
        type="button"
      >
        NEXT
      </BoxButton>
    </Wrapper>
  );
};

CompanyFormNavigateButtons.propTypes = {
  handleReset: PropTypes.func,
  values: PropTypes.object,
  notValidRoleType: PropTypes.bool,
};
