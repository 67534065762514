import { GetApiData } from './fetchConfig';
import useSWR from 'swr';

export const useInsuredObjectsFullList = (fleetId, sort) => {
  return GetApiData(`fleets/${fleetId}/insured-objects/overview?&sort=${sort}&includeExpired=true`);
};

export const useInsuredObjectsForQuotedFleet = (fleetId, sort, shouldFetch) => {
  return useSWR(shouldFetch ? `fleets/${fleetId}/insured-objects/overview?&sort=${sort}` : null);
};
