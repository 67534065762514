import React from 'react';
import styled from 'styled-components';
import { CompanyForm } from './CompanyForm/CompanyForm';
import { ErrorMessage } from 'components/shared/datePicker/Components';
import { CompanyLinks } from '../externalCompanyLinks/CompanyLinks';
import { ActivityLog } from '../activityLogs/ActivityLog';
import { CompanyRoleList } from './CompanyRoleList';
import { Load } from '@instech/icons';
import { useCompanyContext } from './CompanyContext';
import { ProofOfRegistration } from './ProofOfRegistration';
import { TabbedContent } from '../shared/TabbedContent';

const StyledLoad = styled(Load)`
  height: 20px;
  align-self: center;
  margin: 8px;
`;

const formTab = {
  key: "form",
  heading: "Company Information",
};
const roleTab = {
  key: "roles",
  heading: "Company Roles",
};
const linksTab = {
  key: "links",
  heading: "Company Links",
};
const logTab = {
  key: "logs",
  heading: "Activity Log",
};
const proofOfRegTab = {
  key: "proofOfReg",
  heading: "Proof of Registration",
};

const companyTabs = [formTab, roleTab, linksTab, logTab, proofOfRegTab]

export const CompanyData = () => {
  const { error, organization } = useCompanyContext();

  if (error) return <ErrorMessage>Failed to load company data!</ErrorMessage>;
  if (!organization) return <StyledLoad />;

  return (
    <TabbedContent tabs={companyTabs}>
      {(activeTab) => (
        <>
          {activeTab === formTab && <CompanyForm />}
          {activeTab === linksTab && (
            <CompanyLinks
              organizationId={organization.id}
              etag={organization._etag}
              externalLinks={organization.links}
            />
          )}
          {activeTab === roleTab && <CompanyRoleList organization={organization} />}
          {activeTab === logTab && <ActivityLog organizationId={organization.id} />}
          {activeTab === proofOfRegTab && <ProofOfRegistration organizationId={organization.id}/>}
        </>
      )}
    </TabbedContent>
  );
};
