import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  progress {
    margin-right: 16px;
    padding-top: 3px;
  }

  progress[value] {
    width: ${(props) => props.width};
    appearance: none;
  }

  progress[value]::-webkit-progress-bar {
    height: 10px;
    border-radius: 20px;
    background-color: #eee;
  }

  progress[value]::-webkit-progress-value {
    height: 10px;
    border-radius: 20px;
    background-color: ${(props) => props.color};
  }
`;

export const ProgressBar = ({ value, max, color, width, message }) => {
  return (
    <Container color={color} width={width}>
      <progress value={value} max={max} />
      <span> {Math.round((value / max) * 100)}% </span>
      <span> {message} </span>
    </Container>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.string,
};
