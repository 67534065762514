import { useEffect, useState } from 'react';
import { postJsonAsync } from './fetchConfig';

export const useCaseHandlerEmail = (caseHandlerEmails, section, onlySnapshots) => {
    const [caseHandlerEmailsFullList, setCaseHandlerEmailsFullList] = useState([]);
    useEffect(() => {
      const payLoad = {
          "facetSearchRequest": {
              "columnName": "CaseHandlerEmail",
              "filter": "",
              "maxCount": 1000
          },
          "searchRequest": {
              caseHandlerEmails,
              "sectionCodes": section,
              "onlySnapshots": onlySnapshots,
              "hasUnhandledMissingData": null,
              "isMissingData": null,
              "hasUnhandledHighRisk": null,
              "riskLevel": null,
              "searchField": 'keywords',
              "searchText": '',
              "offset": 0,
              "limit": 100,
              "sort": [
              ]
          }
      };
      postJsonAsync(`fleets/facets`, payLoad).then((response) => {
          setCaseHandlerEmailsFullList(response.items.filter(item => !!item));
      });

  }, [section, caseHandlerEmails]);

  return caseHandlerEmailsFullList;
}