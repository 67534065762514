import React from 'react';
import { SlimButton, BoxButton, ButtonGroup } from '@instech/components';
import { useActionsForQuotedFleetsContext } from './ActionsForQuotedFleetsContext';
import { QuotedFleetModalDialog } from './QuotedFleetModalDialog';

export const QuotedFleetActions = () => {
  const {
    isRemoveDialogVisible,
    setIsRemoveDialogVisible,
    isAcceptDialogVisible,
    setIsAcceptDialogVisible,
    isSubmitted,
    setIsSubmitted,
  } = useActionsForQuotedFleetsContext();
  return (
    <div>
      <ButtonGroup buttonGap='16px' alignRight>
        <SlimButton
          ariaLabel='Accept Risks'
          padding='10px'
          height=''
          onClick={() => {
            setIsAcceptDialogVisible(true);
            setIsSubmitted(false);
          }}
          title=''
          type='button'
          variant='primary'
          width=''
        >
          ACCEPT RISKS
        </SlimButton>
        <BoxButton
          inverted
          aria-label='Remove Fleet'
          padding='10px 20px'
          onClick={() => {
            setIsRemoveDialogVisible(true);
            setIsSubmitted(false);
          }}
          type='button'
        >
          REMOVE
        </BoxButton>
      </ButtonGroup>
      {(isRemoveDialogVisible || isAcceptDialogVisible) && !isSubmitted && <QuotedFleetModalDialog />}
    </div>
  );
};
