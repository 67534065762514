export const checkOverlappingDates = (item, dateFrom, dateTo) => {
  const inputFrom = dateFrom && new Date(dateFrom),
    inputTo = dateTo && new Date(dateTo),
    coassuredFrom = item.activePeriod.from && new Date(item.activePeriod.from),
    coassuredTo = item.activePeriod.to && new Date(item.activePeriod.to);

  if ((!coassuredFrom && !coassuredTo) || (!inputFrom && !inputTo)) return true;
  if (
    coassuredFrom &&
    !coassuredTo &&
    (inputFrom >= coassuredFrom || inputTo >= coassuredFrom || (inputFrom <= coassuredFrom && !inputTo))
  )
    return true;
  if (
    !coassuredFrom &&
    coassuredTo &&
    ((inputFrom && inputFrom <= coassuredTo) ||
      (inputTo && inputTo <= coassuredTo) ||
      (inputTo >= coassuredTo && !inputFrom))
  )
    return true;
  if (
    coassuredFrom &&
    coassuredTo &&
    ((inputFrom >= coassuredFrom && inputFrom <= coassuredTo) || //new date FROM in existing dates range
    (inputTo >= coassuredFrom && inputTo <= coassuredTo) || //new date TO in existing dates range
    (inputFrom <= coassuredFrom && inputTo >= coassuredFrom) || //new date FROM before existing date FROM, new date TO after existing date FROM
    (inputFrom <= coassuredFrom && !inputTo) || //new date FROM before existing date FROM, but no new date TO
      (inputTo >= coassuredTo && !inputFrom)) // new date TO after existing date TO, but no new date FROM
  )
    return true;
  return false;
};
