import React from 'react';
import styled from 'styled-components';
import { TabbedContent, ContentTabDefinition } from '../shared/TabbedContent';
import { FleetCoversAndClaims } from './FleetCoversAndClaims';
import { FleetActivityLog } from './FleetActivityLog';

const AboutFleetWrapper = styled.div`
  background: ${(props) => props.theme.white};
  margin-bottom: 32px;
  margin-top: -16px;
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
  padding: 0 14px 14px;
`;

const CoversAndClaimsTab: ContentTabDefinition = {
  key: "coversAndClaims",
  heading: "Covers and Claims",
  content: <FleetCoversAndClaims />
};

const ActivityLogTab: ContentTabDefinition = {
  key: "activityLog",
  heading: "Activity Log",
  content: <FleetActivityLog />
};

const AboutFleetTabs = [CoversAndClaimsTab, ActivityLogTab];

export const AboutFleet = () => {
  return (
    <AboutFleetWrapper>
      <TabbedContent tabs={AboutFleetTabs} />
    </AboutFleetWrapper>
  );
};
