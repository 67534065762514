import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CompanyRow } from './CompanyRow';
import { Pagination } from '../shared/Pagination';
import { RadioButton } from '../shared/formFields/RadioButton';

const Wrapper = styled.div``;

const CompaniesSection = styled.div`
  border: 1px solid ${ (props) => props.theme.lightBlue };
  padding: 16px;
`;
const SectionTitle = styled.div`
  font-weight: bold;
  margin-top: 32px;
`;
const UncheckAllCompaniesWrapper = styled.div`
  margin: 24px;
  display: flex;
  align-items: center;
`;

export const SimilarCompanies = ({
  selectedCompanies,
  handleChange,
  checkedPages,
  setCheckedPages,
  groupedCompaniesWithSimilar,
  unselectAllIds,
  handleUnselectAll,
  companyIndex,
  currentPage,
  setCurrentPage,
}) => {
  const mainCompanyId = groupedCompaniesWithSimilar[companyIndex]?.organizationId;

  useEffect(() => {
    if (
      !!selectedCompanies.find((s) => s.organizationId === mainCompanyId) ||
      selectedCompanies.some(
        (s) =>
          groupedCompaniesWithSimilar[companyIndex].similarKycOrganizations
            .map((s) => s.id)
            .includes(s.organizationId) && s.mainCompany === mainCompanyId
      )
    ) {
      !checkedPages.includes(currentPage) && setCheckedPages((prev) => [...prev, currentPage]);
    }
  }, [
    selectedCompanies,
    groupedCompaniesWithSimilar,
    companyIndex,
    currentPage,
    checkedPages,
    setCheckedPages,
    mainCompanyId,
  ]);

  return (
    <Wrapper>
      <Pagination
        numberOfPages={groupedCompaniesWithSimilar?.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        checkedPages={checkedPages}
      />
      <SectionTitle>Either - Select existing company from the KYC-portal.</SectionTitle>
      <CompaniesSection>
        {groupedCompaniesWithSimilar[companyIndex]?.similarKycOrganizations.map((s, index) => {
          const company = {
            organizationId: s.id,
            name: s.displayName,
            displayAddress: s.displayAddress,
            organizationNumber: s.organizationNumber,
            objectId: groupedCompaniesWithSimilar[companyIndex].objectId,
            objectName: groupedCompaniesWithSimilar[companyIndex].objectName,
            roleType: groupedCompaniesWithSimilar[companyIndex].roleType,
            activePeriod: groupedCompaniesWithSimilar[companyIndex].activePeriod,
            mainCompany: mainCompanyId,
          };
          return (
            <CompanyRow
              key={index}
              index={index}
              company={company}
              companySource='KYC'
              name={mainCompanyId}
              handleChange={handleChange}
              checked={selectedCompanies.some(
                (c) => c.organizationId === company.organizationId && c.mainCompany === mainCompanyId
              )}
            />
          );
        })}
      </CompaniesSection>
      <SectionTitle>Or - Transfer new from LLI.</SectionTitle>
      <CompaniesSection>
        <CompanyRow
          company={groupedCompaniesWithSimilar[companyIndex]}
          companySource='LLI'
          name={mainCompanyId}
          handleChange={handleChange}
          checked={selectedCompanies.some((c) => c.organizationId === mainCompanyId)}
        />
      </CompaniesSection>
      <UncheckAllCompaniesWrapper>
        <RadioButton
          name={mainCompanyId}
          onChange={() => handleUnselectAll(mainCompanyId, currentPage)}
          checked={
            unselectAllIds.includes(mainCompanyId) &&
            !selectedCompanies.some((c) => c.organizationId === mainCompanyId || c.mainCompany === mainCompanyId)
          }
        />
        <div>Unselect All</div>
      </UncheckAllCompaniesWrapper>
    </Wrapper>
  );
};

SimilarCompanies.propTypes = {
  selectedCompanies: PropTypes.array,
  handleChange: PropTypes.func,
  checkedPages: PropTypes.array,
  setCheckedPages: PropTypes.func,
  groupedCompaniesWithSimilar: PropTypes.array,
  unselectAllIds: PropTypes.array,
  handleUnselectAll: PropTypes.func,
  companyIndex: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
};
