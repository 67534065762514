import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getFriendlyDate, getFriendlyDateTime } from '../../utils/date';
import { getUser } from '../../services/getUser';
import { getAcceptanceLogComments } from "../../utils/acceptanceLogUtils";

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 2fr) repeat(7, minmax(80px, 1fr)) minmax(150px, 2fr);
  grid-gap: 8px;
  align-items: center;
  padding: 16px 8px;
  word-wrap: break-word;
  &:nth-child(odd) {
    background: ${ (props) => props.theme.flatWhite };
  }
`;

export const LogDataCover = ({ item, covers }) => {
  const cover = covers && covers.find((c) => c.id === item.coverId);
  const [user, setUser] = useState({});

  useEffect(() => {
    item.userId &&
      getUser(item.userId).then((response) => {
        setUser(response);
      });
  }, [item.userId]);

  return (
    <Grid>
      <span>{cover ? cover.interestCode.name : item.name}</span>
      <span></span>
      <span>{cover.riskNumber}</span>
      <span>{cover.coverNote}</span>
      <span>{getFriendlyDate(item.acceptancePeriod.from)}</span>
      <span>{getFriendlyDate(item.acceptancePeriod.to)}</span>
      <span>{user.name}</span>
      <span>{getFriendlyDateTime(item.acceptedAt)}</span>
      <span>{getAcceptanceLogComments(item)}</span>
    </Grid>
  );
};

LogDataCover.propTypes = {
  item: PropTypes.object,
  covers: PropTypes.array,
};
