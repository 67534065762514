import React, { useState, useCallback } from 'react';
import { BoxButton } from '@instech/components';
import { ConfirmModalDialog } from '../shared/ConfirmModalDialog';
import { AcceptModalDialog } from '../shared/AcceptModalDialog';
import { useCompanyContext } from './CompanyContext';
import { postAcceptMissingInfo } from '../../services/postAcceptData';
import { useAppContext } from '../appRouting/AppContext';
import { constants } from 'utils/constants';
import { acceptMissingUbo } from "../../services/postAcceptMissingUbo";

export const AcceptMissingUbo = ({insuredObjectId, fleetId}) => {
    const {
        reference,
        clientRoleType,
        mutate,
        mutateFleetDetails,
        mutateVesselList,
    } = useCompanyContext();
    const { dispatch } = useAppContext();
    const setIsConfirmDialogVisible = (value) => {
        dispatch({ type: constants.SET_IS_CONFIRM_DIALOG_VISIBLE, value });
    }
    const [showAcceptMissingInfoError, setShowAcceptMissingInfoError] = useState(false);
    const [isModalDialogVisible, setIsModalDialogVisible] = useState(false);

    const acceptMissingUboHandler = useCallback(
        (values) => {
            const { acceptanceDateFrom, acceptanceDateTo, additionalComments } = values;
            const data = {
                acceptancePeriod: {
                    from: acceptanceDateFrom,
                    to: acceptanceDateTo,
                },
                comments: additionalComments,
            };
            acceptMissingUbo(fleetId, insuredObjectId, data)
                .then(() => {
                    mutate();
                    mutateFleetDetails && mutateFleetDetails();
                    mutateVesselList && mutateVesselList();
                })
                .catch((error) => {
                    console.error(error);
                    setIsModalDialogVisible(false);
                    setShowAcceptMissingInfoError(error);
                });
            setIsModalDialogVisible(false);
            setIsConfirmDialogVisible(true);
        },
        [mutate, mutateFleetDetails, mutateVesselList]
    );

    const handleShowModalDialog = () => {
        setIsModalDialogVisible(!isModalDialogVisible);
    }

    return (
        <>
            <BoxButton
                aria-label='Accept missing UBO'
                onClick={handleShowModalDialog}
                type='button'
                className='acceptMissingInfoButton'
            >
                ACCEPT MISSING UBO
            </BoxButton>

            {showAcceptMissingInfoError && (
                <ConfirmModalDialog
                    text='An error occurred when trying to accept missing UBO.'
                    onClick={() => setShowAcceptMissingInfoError(false)}
                    onCancel={() => setShowAcceptMissingInfoError(false)}
                />
            )}
            {isModalDialogVisible && (
                <AcceptModalDialog
                    isModalDialogVisible={isModalDialogVisible}
                    onClick={handleShowModalDialog}
                    isMissingInfo='true'
                    text='Fill in to accept missing UBO'
                    labelText='Reason for accepting missing UBO'
                    cancelButtonText='Cancel'
                    acceptButtonText='Accept missing UBO'
                    onCancel={handleShowModalDialog}
                    onAccept={acceptMissingUboHandler}
                />
            )}
        </>
    );
};
