import { useCallback, useEffect, useState } from 'react';
import qs from 'qs';
import useSWR, { mutate } from 'swr';

export function useCompaniesList(shouldFetch, query, offset, limit) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [companies, setСompanies] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [mutations, setMutations] = useState(0);

  useEffect(() => {
    setСompanies([]);
  }, [query?.countryCode, query?.searchString]); 

  // We need to load all objects up to offset + limit to be sure to update all objects
  if (mutations > 0) {
    limit += offset;
    offset = 0;
  }

  var params = qs.stringify({ offset, limit }, { arrayFormat: 'repeat' });

  const countryCode = query && query.countryCode ? `/${encodeURIComponent(query.countryCode)}` : '';

  const url =
    query &&
    `Organizations${countryCode}?query=${encodeURIComponent(query.searchString)}&${params}&ignoreExternalSources=true`;

  const { data, error: swrError } = useSWR(shouldFetch && url ? url : null);

  useEffect(() => {
    setLoading(true);
    setError(false);

    if (data) {
      setСompanies((prevObjects) => {
        data.forEach((o) => {
          var i = prevObjects.findIndex((x) => x.id === o.id);
          if (i >= 0) {
            prevObjects[i] = o;
          } else {
            prevObjects.push(o);
          }
        });
        return [...prevObjects];
      });
      setHasMore(data.length === limit);
      setLoading(false);
    }

    if (swrError) {
      setError(true);
    }
  }, [data, swrError, limit, mutations]);

  const mutateCallback = useCallback(() => {
    mutate(url);
    setMutations((prev) => ++prev);
  }, [url]);

  return { loading, error, companies, hasMore, mutate: mutateCallback };
}
