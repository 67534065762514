import { useEffect } from 'react';
import { useCompanyContext } from './CompanyContext';
import { constants } from '../../utils/constants';
import { checkTaxDataRequired, checkStateRequired } from '../../utils/countriesWithRequiredData';

export const FormikChangeComponent = ({
  values,
  setSubmitting,
  isSubmitting,
  dirty,
  notValidRoleType,
  countryPartitionKey,
  formDispatch,
}) => {
  useEffect(() => {
    setSubmitting(false);
  }, [values, setSubmitting]);

  const { setShowConfirmClose } = useCompanyContext();

  useEffect(() => {
    setShowConfirmClose && setShowConfirmClose((dirty && !isSubmitting) || notValidRoleType);
  }, [dirty, isSubmitting, setShowConfirmClose, notValidRoleType]);

  useEffect(() => {
    formDispatch({ type: constants.SET_IS_DIRTY, value: dirty });
  }, [dirty]);

  const isTaxData = checkTaxDataRequired(countryPartitionKey);
  const isState = checkStateRequired(countryPartitionKey);

  useEffect(() => {
    formDispatch({ type: constants.SET_IS_TAX_DATA_REQUIRED, value: isTaxData });
    formDispatch({ type: constants.SET_IS_STATE_REQUIRED, value: isState });
  }, [isTaxData, isState, formDispatch]);

  return null;
};
