import { useCallback, useEffect, useState } from 'react';
import qs from 'qs';
import useSWR, { mutate } from 'swr';

export function useInsuredObjectList(fleetId, offset, limit, sort, includeExpired = true, shouldFetch) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [insuredObjects, setInsuredObjects] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [mutations, setMutations] = useState(0);

  useEffect(() => {
    setInsuredObjects([]);
  }, [sort]);

  // We need to load all objects up to offset + limit to be sure to update all objects
  if (mutations > 0) {
    limit += offset;
    offset = 0;
  }
  var params = qs.stringify({ offset, limit, sort, includeExpired }, { arrayFormat: 'repeat' });
  const url = `fleets/${fleetId}/insured-objects?${params}`;

  const { data, error: swrError } = useSWR(shouldFetch ? url : null);

  useEffect(() => {
    setLoading(true);
    setError(false);

    if (data) {
      setInsuredObjects((prevObjects) => {
        data.forEach((o) => {
          var i = prevObjects.findIndex((x) => x.mdmReference.mdmExternalId === o.mdmReference.mdmExternalId);
          if (i >= 0) {
            prevObjects[i] = o;
          } else {
            prevObjects.push(o);
          }
        });
        return [...prevObjects];
      });
      setHasMore(data.length === limit);
      setLoading(false);
    }

    if (swrError) {
      setError(true);
    }
  }, [data, swrError, limit, mutations]);

  const mutateCallback = useCallback(() => {
    mutate(url);
    setMutations((prev) => ++prev);
  }, [url]);

  return { loading, error, insuredObjects, hasMore, mutate: mutateCallback };
}
