import React, { createContext, useContext, useState } from 'react';
import { useAppContext } from '../appRouting/AppContext';
import { constants } from '../../utils/constants';
import { useNationalities } from '../../services/useNationalities';
import { usePersonsList } from '../../services/usePersonsList';

const PersonsPageContext = createContext({});

export const PersonsPageContextProvider = ({ children }) => {
  const [isPersonUpdated, setIsPersonUpdated] = useState(false);
  const { state, dispatch } = useAppContext();
  const personQuery = state.personQuery;
  const nationalities = useNationalities();
  const initialValues = {
    nationalityId: personQuery?.nationalityId || null,
    firstName: personQuery?.firstName || '',
    lastName: personQuery?.lastName || '',
    dateOfBirth: personQuery?.dateOfBirth || '',
  };
  const isEmpty = personQuery && Object.values(personQuery).every((x) => x === null || x === '');
  const shouldFetch = personQuery && !isEmpty ? true : false;
  const { persons } = usePersonsList(shouldFetch, personQuery, isPersonUpdated);
  const handleSearch = (values) => {
    dispatch({ type: constants.SET_PERSON_QUERY, value: values });
    setIsPersonUpdated(false);
  };

  const value = {
    state,
    dispatch,
    personQuery,
    nationalities,
    initialValues,
    handleSearch,
    persons,
    setIsPersonUpdated
  };

  return <PersonsPageContext.Provider value={value}>{children}</PersonsPageContext.Provider>;
};

export const usePersonsPageContext = () => useContext(PersonsPageContext);
