import { constants } from '../utils/constants';

export const appInitialState = {
   sections: [],
   sectionsForQuoted: [],
   isOnlyWithActiveClaims: false,
   newCompany: null,
   companyQuery: null,
   personQuery: null,
   singleCompanyId: null,
   offset: 0,
   caseHandlerEmails: [],
   caseHandlerEmailsForQuoted: [],
   riskLevel: {
      overdue: null,
      followUp: null,
      missingInfo: null,
      noMissingInfo: null,
      forQuoted: null
   },
   activeTab: 'Overdue',
   activeTabForQuoted: 'ImportedFleets',
   currentPage: {
      overdue: 1,
      followUp: 1,
      missingInfo: 1,
      noMissingInfo: 1,
      highRisk: 1,
      forQuoted: 1
   },
   isConfirmDialogVisible: false
}

export const reducer = (state, action) => {
   switch (action.type) {
      case constants.SET_SECTIONS:
         return { ...state, sections: action.value };
      case constants.SET_SECTIONS_FOR_QUOTED:
         return { ...state, sectionsForQuoted: action.value };
      case constants.SET_IS_ONLY_WITH_ACTIVE_CLAIMS:
         return { ...state, isOnlyWithActiveClaims: action.value };
      case constants.SET_NEW_COMPANY:
         return { ...state, newCompany: action.value };
      case constants.SET_COMPANY_QUERY:
         return { ...state, companyQuery: action.value };
      case constants.SET_PERSON_QUERY:
         return { ...state, personQuery: action.value };
      case constants.SET_SINGLE_COMPANY_ID:
         return { ...state, singleCompanyId: action.value };
      case constants.SET_OFFSET:
         return { ...state, offset: action.value };
      case constants.SET_CASE_HANDLER_EMAILS:
         return { ...state, caseHandlerEmails: action.value };
      case constants.SET_CASE_HANDLER_EMAILS_FOR_QUOTED:
         return { ...state, caseHandlerEmailsForQuoted: action.value };
      case constants.SET_RISK_LEVEL:
         return { ...state, riskLevel: { ...state.riskLevel, [action.name]: action.value } };
      case constants.SET_ACTIVE_TAB:
         return { ...state, activeTab: action.value };
      case constants.SET_ACTIVE_TAB_FOR_QUOTED:
         return { ...state, activeTabForQuoted: action.value };
      case constants.SET_CURRENT_PAGE:
         return { ...state, currentPage: { ...state.currentPage, [action.name]: action.value } };
      case constants.SET_IS_CONFIRM_DIALOG_VISIBLE:
         return { ...state, isConfirmDialogVisible: action.value };
      default:
         return state;
   }
};
