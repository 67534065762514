import React from 'react';
import styled from 'styled-components';

const ProgressLine = styled.div`
  width: 100%;
  height: 100%;
`;

const CompletedLine = styled.div`
  background-color: green;
  width: ${(props) => ((props.step + 1) / props.numSteps) * 100}%;
  height: 6px;
  border-radius: 2px 3px 3px 2px;
`;

const Steps = styled.ol`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  padding: 0 2em;
`;

const Step = styled.li`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  display: inline;
  color: ${(props) => (props.currentStep ? props.theme.marineBlue : props.theme.marineBlue50)};
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.white};
  height: 6px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
`;

export const ProgressBar = ({ steps, step, className }) => (
  <Container className={className}>
    <ProgressLine>
      <CompletedLine step={step} numSteps={Object.keys(steps).length} />
    </ProgressLine>
    <Steps>
      {steps &&
        steps.map((each, i) => (
          <Step key={i} currentStep={i === step}>
            {each.step}
            .&nbsp;
            {each.name}
          </Step>
        ))}
    </Steps>
  </Container>
);
