import React from 'react';
import styled from 'styled-components';
import { StatusIcon } from './StatusIcon';
import { RiskLevel, ValidationFlags } from '../../../utils/validations/types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RiskIcons = ({ validations }: { validations: ValidationFlags }) => {
  const { riskLevel, acceptedHighRisk } = validations;
  return (
    <Wrapper>
      {riskLevel === RiskLevel.High && !acceptedHighRisk && (
        <span>
          <StatusIcon highRiskIcon text='High Risk - Needs Approval' />
        </span>
      )}
      {riskLevel === RiskLevel.High && acceptedHighRisk && (
        <span>
          <StatusIcon acceptedHighRiskIcon text='High Risk - Approved' />
        </span>
      )}
      {riskLevel === RiskLevel.Moderate && (
        <span>
          <StatusIcon moderateRiskIcon text='Moderate Risk' />
        </span>
      )}
      {riskLevel === RiskLevel.Low && 'Low'}
    </Wrapper>
  );
};
