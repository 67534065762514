import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MenuDots } from '@instech/icons';
import { QuotedFleetMenu } from '../quotedFleets/QuotedFleetMenu';
import { FleetItemHeader } from './FleetItemHeader';
import { ActionsForQuotedFleetsContextProvider } from '../quotedFleets/ActionsForQuotedFleetsContext';

const StyledMenuDots = styled(MenuDots)`
  transform: rotate(90deg);
  position: absolute;
  top: -32px;
  left: -32px;
  height: 16px;
  margin-left: 8px;
  &:hover {
    cursor: pointer;
  }
`;
const Wrapper = styled.div`
  display: grid;
  align-items: center;
`;
const MenuWrapper = styled.div`
  justify-self: right;
  position: relative;
`;

export const FleetItem = ({ item, isQuotedFleet, mutateQuotedFleetsList, mutateLastImportedFleetsList }) => {
  const [openQuotedFleetMenu, setOpenQuotedFleetMenu] = useState(false);

  const node = useRef(null);

  const handleClickOutside = (event) => {
    if (node.current && !node.current.contains(event.target)) {
      setOpenQuotedFleetMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Wrapper>
      <Link to={`/fleets/${item.id}`} style={{ textDecoration: 'none' }}>
        <FleetItemHeader item={item} />
      </Link>
      {isQuotedFleet && (
        <MenuWrapper>
          <StyledMenuDots data-test-id="open-fleet-menu" onClick={() => setOpenQuotedFleetMenu(!openQuotedFleetMenu)} />
          {openQuotedFleetMenu && (
            <ActionsForQuotedFleetsContextProvider
              fleetId={item.id}
              fleetName={item.name}
              insuranceYear={item.insuranceYear}
              mutateQuotedFleetsList={mutateQuotedFleetsList}
              mutateLastImportedFleetsList={mutateLastImportedFleetsList}
              setOpenQuotedFleetMenu={setOpenQuotedFleetMenu}
            >
              <QuotedFleetMenu node={node} />
            </ActionsForQuotedFleetsContextProvider>
          )}
        </MenuWrapper>
      )}
    </Wrapper>
  );
};

FleetItem.propTypes = {
  item: PropTypes.object,
  isQuotedFleet: PropTypes.bool,
  mutateQuotedFleetsList: PropTypes.func,
  mutateLastImportedFleetsList: PropTypes.func,
};
