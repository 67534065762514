import * as Yup from 'yup';
import { checkOverlappingDates } from '../../utils/checkOverlappingDates';

const checkExistingRole = (roles, roleType, roleIndex, dateFrom, dateTo) =>
  roles.some(
    (role, index) => roleIndex !== index && role.type === roleType && checkOverlappingDates(role, dateFrom, dateTo)
  );

Yup.addMethod(Yup.array, 'checkRole', function (message1, message2) {
  const messages = [message1, message2];
  return this.test('unique', messages, function (list) {
    let errors = [];
    list?.forEach((item, index, array) => {
      if (checkExistingRole(array, item.type, index, item.activePeriod.from, item.activePeriod.to)) {
        const typeError = this.createError({
          path: `${this.path}[${index}].type`,
          message: messages[0],
        });
        const dateFromError = this.createError({
          path: `${this.path}[${index}].activePeriod.from`,
          message: messages[1],
        });
        const dateToError = this.createError({
          path: `${this.path}[${index}].activePeriod.to`,
          message: messages[1],
        });
        errors.push(typeError, dateFromError, dateToError);
      }
    });

    if (errors.length > 0) {
      throw new Yup.ValidationError(errors);
    }
    return true;
  });
});

const requiredErrorMessage = 'This field is required';
const trimmedString = Yup.string().trim();

const maxYears = new Date().getFullYear() - 18;
const minYears = new Date().getFullYear() - 120;
const months = new Date().getMonth();
const days = new Date().getDate();
const maxDate = new Date(maxYears, months, days + 1);
const minDate = new Date(minYears, months, days);

export const ValidationSchemaPerson = Yup.object({
  dateOfBirth: Yup.date()
    .min(minDate, 'The person cannot be older than 120 years old')
    .max(maxDate, 'The person cannot be younger than 18 years old')
    .nullable()
    .label('Date of Birth'),
  firstName: trimmedString.required(requiredErrorMessage),
  lastName: trimmedString.required(requiredErrorMessage),
  nationality: Yup.object().nullable().required(requiredErrorMessage),
  isPoliticallyExposed: Yup.boolean().nullable(),
});

export const ValidationSchemaRole = Yup.object({
  isPoliticallyExposed: Yup.boolean().nullable(),
  role: Yup.object().shape({
    type: trimmedString.nullable().required(requiredErrorMessage),
    activePeriod: Yup.object().shape({
      from: Yup.date().nullable().label('From'),
      to: Yup.date().min(Yup.ref('from'), "Must be after 'from' date.").nullable().label('To'),
    }),
  }),
});

export const ValidationSchemaEditPerson = Yup.object({
  person: Yup.object().shape({
    dateOfBirth: Yup.date()
      .min(minDate, 'The person cannot be older than 120 years old')
      .max(maxDate, 'The person cannot be younger than 18 years old')
      .nullable()
      .label('Date of Birth'),
    firstName: trimmedString.required(requiredErrorMessage),
    lastName: trimmedString.required(requiredErrorMessage),
    nationality: Yup.object().nullable().required(requiredErrorMessage),
    isPoliticallyExposed: Yup.boolean().nullable(),
  }),
  roles: Yup.array()
    .of(
      Yup.object().shape({
        type: trimmedString.nullable().required(requiredErrorMessage),
        activePeriod: Yup.object().shape({
          from: Yup.date().nullable().label('From'),
          to: Yup.date().min(Yup.ref('from'), "Must be after 'from' date.").nullable().label('To'),
        }),
      })
    )
    .checkRole('This role already exists, check dates', 'Dates overlap'),
});
