import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Load, Approved } from '@instech/icons';
import { ProgressBar } from './Progress';

const StyledApproved = styled(Approved)`
  color: ${(props) => props.theme.green};
`;
const StyledLoad = styled(Load)`
  height: 6px;
  margin-top: -5px;
`;

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;
const Dialog = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  left: 35%;
  right: 35%;
  top: 35%;
  bottom: 35%;
  height: fit-content;
  margin: auto;
  background: white;
  padding: 0;
`;

const Button = styled.button`
  align-self: flex-end;
  margin: 5px;
  padding-left: 6px;
  padding-right: 6px;
  background: none;
  color: #de350b;
  font-size: 24px;
  line-height: 24px;
  outline: none;
  border: none;
  border-radius: 2px;
  align-items: center;
  transition: all 0.2s ease;

  &:hover {
    background-color: #ffbdad;
    transform: scale(1.3);
  }
`;
const ItemsWrapper = styled.div`
  align-self: center;
  text-align: center;
  font-size: 24px;
  padding: 16px 0 32px 0;
`;
const ErrorWrapper = styled.div`
  align-self: center;
  text-align: center;
  font-size: 18px;
  color: red;
  padding: 16px 0 32px 0;
`;
const ErrorItem = styled.div`
  margin-bottom: 16px;
`;

export const ProgressModalDialog = ({ closeModal, savingProgress, isError, errorMessage }) => {
  return (
    <Wrapper>
      <Dialog>
        <Button onClick={closeModal}>x</Button>
        {savingProgress && !isError && (
          <ItemsWrapper>
            {savingProgress.value < 100 && (
              <div>
                Please wait <StyledLoad />
              </div>
            )}
            {savingProgress.value === 100 && <StyledApproved />}
            <p>{savingProgress.value < 100 && !isError ? savingProgress.message : 'Done'}</p>
            <ProgressBar color={'#003C71'} value={savingProgress.value} max={100} width='250' />
          </ItemsWrapper>
        )}
        {isError && (
          <ErrorWrapper>
            <ErrorItem>Failed to save company data!</ErrorItem>
            <ErrorItem>{errorMessage}</ErrorItem>
          </ErrorWrapper>
        )}
      </Dialog>
    </Wrapper>
  );
};

ProgressModalDialog.propTypes = {
  text: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};
