import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getIn } from 'formik';
import { baseTheme } from '@instech/components';

const Error = styled.span`
  color: red;
  font-size: 14px;
  position: absolute;
  display: block;
`;
export const SelectField = ({
  name,
  withDarkBackground,
  noBorder,
  placeholder,
  value,
  handleChange,
  handleBlur,
  displayNameSelector,
  valueNameSelector,
  selectedDisplayNameSelector = null,
  touched,
  errors,
  options,
  addName = null,
  isDisabled = false,
  formatOptionLabel = null,
  customOptionList = null,
  controlShouldRenderValue = true,
  isLoadingOptions = false,
}) => {
  const isError = getIn(touched, name) && getIn(errors, name);

  const isAddError = addName && getIn(touched, addName) && getIn(errors, addName);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontFamily: 'Calibri',
      fontSize: '14px',
      color: baseTheme.marineBlue,
      outline: 'none',
      minWidth: '150px',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused || isSelected ? '#F5F5F5' : 'white',
      color: baseTheme.marineBlue,
      padding: '5px',
      //option styles
      //styles for spans inside options
      '& span': {
        display: 'inline-grid',
        gridTemplateColumns: 'auto auto auto',
        columnGap: '10px',
      },
      '& span:nth-of-type(1)': { minWidth: '150px' },
      '& span:nth-of-type(2)': { minWidth: '200px' },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: baseTheme.marineBlue50,
    }),
    control: (provided, state) => ({
      // select wrapper
      ...provided,
      fontSize: '14px',
      fontFamily: 'Calibri',
      boxShadow: state.isFocused ? 0 : 0,
      border: noBorder
        ? '1px solid transparent'
        : isError || isAddError
        ? '1px solid red'
        : `1px solid ${baseTheme.marineBlue50}`,
      background: withDarkBackground ? baseTheme.marineBlue10 : baseTheme.white,
      boxSizing: 'border-box',
      padding: '0',
      borderRadius: '2px',
      textAlign: 'left',
      width: '100%',
      height: '40px',
      outline: 'none',
      transition: '0.4s',
      '&:hover': {
        border: `1px solid ${baseTheme.marineBlue}`,
      },
      '& input': {
        font: 'inherit',
      },
    }),
    singleValue: (provided) => ({
      // selected value
      ...provided,
      color: baseTheme.marineBlue,
    }),
    label: (provided) => ({
      // selected value
      ...provided,
    }),
    multiValue: (provided) => ({
      ...provided,
      background: 'none',
      fontSize: '18px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: baseTheme.marineBlue50,
      '&:hover': {
        color: baseTheme.marineBlue,
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      '& div:last-of-type': {
        display: 'flex',
      },
      '& > div': {
        display: 'none',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: '16px',
      padding: '0',
      margin: '5px',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#bccbd8',
      lineHeight: '14px',
    }),
  };

  const optionsList = customOptionList
    ? customOptionList
    : isLoadingOptions
    ? [{ value: '', label: 'Loading...' }]
    : [{ value: '', label: 'Not selected' }];

  if (options)
    options.map((option) => {
      const optionValue = valueNameSelector(option);
      const selected = value && selectedDisplayNameSelector ? value.includes(optionValue) : false;
      const label =
        selected && selectedDisplayNameSelector ? selectedDisplayNameSelector(option) : displayNameSelector(option);
      return optionsList.push({
        value: optionValue,
        label: label,
      });
    });

  const getValue = () => {
    if (optionsList.length > 0) {
      return optionsList.find((option) => option.value === value);
    } else {
      return null;
    }
  };

  return (
    <div>
      <Select
        controlShouldRenderValue={controlShouldRenderValue}
        formatOptionLabel={formatOptionLabel}
        isDisabled={isDisabled}
        placeholder={placeholder}
        value={getValue()}
        name={name}
        options={optionsList}
        styles={customStyles}
        onChange={(option) => {
          handleChange(name, option.value);
        }}
        onBlur={() => {
          handleBlur(name, true);
        }}
        withDarkBackground={withDarkBackground}
        noBorder={noBorder}
      />
      {isError ? <Error>{getIn(errors, name)}</Error> : null}
      {isAddError ? <Error>{getIn(errors, addName)}</Error> : null}
    </div>
  );
};

SelectField.propTypes = {
  name: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  displayNameSelector: PropTypes.func,
  valueNameSelector: PropTypes.func,
  selectedDisplayNameSelector: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  options: PropTypes.array,
  border: PropTypes.string,
  placeholder: PropTypes.string,
  addName: PropTypes.string,
  isDisabled: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  customOptionList: PropTypes.array,
  controlShouldRenderValue: PropTypes.bool,
};
