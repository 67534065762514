import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { HighRisk, ModerateRisk, Warning, MissingInfo, Approved, LeftArrow } from '@instech/icons';
import { useFleetDetails } from '../../services/useFleetDetails';
import { AcceptanceLogForFleet } from '../acceptanceLog/AcceptanceLogForFleet';
import { MissingData, RiskLevel } from '../../utils/validations/types';
import {AcceptanceType} from "../../utils/AcceptanceType";

const StyledApproved = styled(Approved)`
  color: ${(props) => props.theme.green};
`;
const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh - 60px;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
`;

const Dialog = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  left: 1%;
  right: 1%;
  top: 7%;
  bottom: 1%;
  margin: auto;
  background: ${(props) => props.theme.whiteBlue};
  padding: 0;
`;

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.marineBlue};
  margin-top: 10px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue75};
  align-self: flex-start;
`;
const Subtitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
`;

const InfoText = styled.div`
  align-self: flex-start;
  font-size: 18px;
  font-weight: bold;
  margin: 10px;
  padding: 20px 0px 0px 20px;
  color: ${(props) => props.theme.marineBlue};
`;

const Icon = styled.span`
  vertical-align: middle;
  margin-right: 5px;
`;

export const AcceptanceLogModalDialog = ({ fleetId, onClose }) => {
  const infoText = 'Here you can find history about accepted risks and accepted missing information about fleet';

  const { data: fleet } = useFleetDetails(fleetId);
  const { validations, covers } = fleet;

  return (
    <Wrapper>
      <Dialog>
        <Breadcrumbs>
          <LeftArrow data-test-id="go-to-fleet" onClick={onClose} cursor="pointer" />
          <Item>
            <Title>fleet</Title>
            <Subtitle>
              {validations.missingData > 0 &&
                (validations.missingData !== MissingData.CompanyData || !validations.acceptedMissingData) && (
                  <Icon>
                    <MissingInfo />
                  </Icon>
                )}
              {validations.riskLevel === RiskLevel.High &&
                !validations.acceptedHighRisk(
                  <Icon>
                    <HighRisk />
                  </Icon>
                )}
              {validations.riskLevel === RiskLevel.Moderate && (
                <Icon>
                  <ModerateRisk />
                </Icon>
              )}
              {validations.acceptedHighRisk && validations.riskLevel === RiskLevel.High && (
                <Icon>
                  <Warning />
                </Icon>
              )}
              {((validations.acceptedMissingData && validations.missingData === MissingData.CompanyData) ||
                validations.missingData === MissingData.None) &&
                (validations.acceptedHighRisk || validations.riskLevel !== RiskLevel.High) && (
                  <Icon>
                    <StyledApproved />
                  </Icon>
                )}
              {fleet.name} ({fleet.insuranceYear})
            </Subtitle>
          </Item>
        </Breadcrumbs>
        <InfoText>{infoText}</InfoText>
        <AcceptanceLogForFleet acceptanceType={AcceptanceType.RiskLevel} fleetId={fleetId} covers={covers} />
        <AcceptanceLogForFleet acceptanceType={AcceptanceType.MissingData} fleetId={fleetId} covers={covers} />
        <AcceptanceLogForFleet acceptanceType={AcceptanceType.MissingPersonRoles} fleetId={fleetId} covers={covers} />
      </Dialog>
    </Wrapper>
  );
};

AcceptanceLogModalDialog.propTypes = {
  fleetId: PropTypes.string,
};
