import React from 'react';
import styled from 'styled-components';
import { LeftArrow } from '@instech/icons';
import { AcceptanceLogForOrganization } from './AcceptanceLogForOrganization';
import { useNavigate, useParams } from 'react-router-dom';
import { useFleetDetails } from '../../services/useFleetDetails';
import { AcceptanceType } from "../../utils/AcceptanceType";

const Wrapper = styled.div`
  margin: 32px 64px;
  color: ${(props) => props.theme.marineBlue};
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue75};
`;
const Subtitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
`;
const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
`;
const LinkBack = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const AcceptanceLogPageForOrganization = () => {
  const { fleetId, organizationId } = useParams();
  const { data: fleetData } = useFleetDetails(fleetId);
  const fleetName = fleetData?.name;
  const insuranceYear = fleetData?.insuranceYear;

  let navigate = useNavigate();
  const navigateBack = () => (fleetId ? navigate(`/fleets/${fleetId}`, { replace: true }) : navigate(-1));

  return (
    <Wrapper>
      <Breadcrumbs>
        <LinkBack data-test-id="navigate-back" onClick={navigateBack}>
          <LeftArrow />
        </LinkBack>
        {fleetId ? (
          <Item>
            <Title>/ Fleet</Title>
            <Subtitle>
              {fleetName} ({insuranceYear})
            </Subtitle>
          </Item>
        ) : (
          <Item>
            <Title>/ Companies</Title>
            <Subtitle>Back to Company</Subtitle>
          </Item>
        )}
      </Breadcrumbs>
      <AcceptanceLogForOrganization acceptanceType={AcceptanceType.RiskLevel} organizationId={organizationId} />
      <AcceptanceLogForOrganization acceptanceType={AcceptanceType.MissingData} organizationId={organizationId} />
      <AcceptanceLogForOrganization acceptanceType={AcceptanceType.MissingPersonRoles} organizationId={organizationId} />
    </Wrapper>
  );
};
