import React, {
    createContext, useContext
} from 'react';
import useSWR from 'swr';
import { useAccount } from './authenticationProvider';
import { getJsonAsync } from '../services/fetchConfig';

const featureFlagRoute = '/configuration/featureFlags';

const FeatureFlagContext = createContext({});

export const FeatureFlagProvider = ({ children }) => {
  const { isAuthenticated, } = useAccount();
  const { data } = useSWR(isAuthenticated ? featureFlagRoute : null, getJsonAsync);

  const value = data || {};
  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlagsContext = () => useContext(FeatureFlagContext);

export const useFeatureFlag = (key) => {
  const ctx = useFeatureFlagsContext();
  return !!ctx[key];
};
