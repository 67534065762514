import React, { useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useField, useFormikContext } from 'formik';
import { getDateISOString, friendlyDateFormat, parseISODateString } from '../../../utils/date';
import { FieldLayout, FieldLabel, ErrorMessage } from './Components';
import { StyleOverrides } from './StyleOverrides';

export const DatePicker = ({
  name,
  label,
  emptyLabel,
  placeholder,
  noErrors,
  isFocused = false,
  handleDate = null,
  noBorder,
  disabled,
}) => {
  // Set isFocused to true if you want the calendar to be open by default
  const [focusedInput, setFocusedInput] = useState(isFocused);
  const [, meta, helpers] = useField(name);
  const { setFieldTouched } = useFormikContext();

  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const onFocusChange = (focused) => {
    setFocusedInput(focused);
    if (!focused) {
      setFieldTouched(name, true, false);
    }
  };

  const onTextInputChange = ({ target }) => {
    const momentDate = moment.utc(target.value, friendlyDateFormat, true);
    if (!momentDate.isValid()) {
      setValue(target.value === '' ? null : parseISODateString(target.value));
    }
  };

  const onChange = (date) => {
    const newDate = getDateISOString(date);

    if (newDate !== null && newDate !== value) {
      setValue(newDate);
    }
    !!handleDate && handleDate(newDate);
  };

  const momentStartDate = parseISODateString(value);
  return (
    <FieldLayout>
      {(label || emptyLabel) && (
        <FieldLabel as='label' htmlFor={name}>
          {label}
        </FieldLabel>
      )}
      <StyleOverrides
        $error={touched && error}
        onChange={onTextInputChange}
        noBorder={noBorder}
        disabled={disabled}
      >
        <SingleDatePicker
          date={momentStartDate.isValid() ? momentStartDate : null} // momentPropTypes.momentObj or null
          onDateChange={onChange} // PropTypes.func.isRequired
          focused={focusedInput} // PropTypes.bool
          onFocusChange={({ focused }) => onFocusChange(focused)} // PropTypes.func.isRequired
          id={name} // PropTypes.string.isRequired,
          isOutsideRange={() => false} // Makes all dates accessible in the calendar
          displayFormat={friendlyDateFormat}
          hideKeyboardShortcutsPanel
          firstDayOfWeek={1}
          numberOfMonths={1}
          placeholder={placeholder}
          disabled={disabled}
        />
      </StyleOverrides>
      {!noErrors && <ErrorMessage>{error && touched ? error : ''}</ErrorMessage>}
    </FieldLayout>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.number,
  emptyLabel: PropTypes.bool,
  noErrors: PropTypes.bool,
  isFocused: PropTypes.bool,
};
