import { postJsonAsync } from "./fetchConfig";

export type AcceptMissingUboData = {
  comments: string;
  acceptancePeriod: {from: string, to: string}
};

export const acceptMissingUbo = (fleetId: string, insuredObjectId: string, data: AcceptMissingUboData) => {
  return postJsonAsync(`fleets/${fleetId}/insured-objects/${insuredObjectId}/personRoles/accept-missing-ubo`, data);
};
