import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Tag = styled.div`
  margin: 4px;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 4px;
  background: #82b5ba;
  color: ${(props) => props.theme.white};
`;

export const ClaimsTag = ({ claimsCounter, claimNumber, claimSection, tagWithClaimInfo }) => {
  return (
    <Tag>
      <span>{claimsCounter > 1 ? 'claims' : 'claim '}</span>
      {tagWithClaimInfo && (
        <span>
          {claimNumber}\{claimSection}
        </span>
      )}
    </Tag>
  );
};

ClaimsTag.propTypes = {
  claimsCounter: PropTypes.number,
  claimNumber: PropTypes.number,
  claimSection: PropTypes.string,
  tagWithClaimInfo: PropTypes.bool,
};
