import React, { useState, createContext, useContext } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useFleetDetails } from '../../services/useFleetDetails';
import { useInsuredObjectList } from '../../services/useInsuredObjectsSearch';
import { useAcceptanceLogForFleet } from '../../services/useAcceptanceLogs';
import { useWizardValidationTasks } from '../../services/useValidationTasks';
import { usePETIs } from 'services/getPETIs';

const FleetOverviewContext = createContext({});

export const FleetOverviewContextProvider = ({ children }) => {
  const { id } = useParams();
  const location = useLocation();
  const vesselId = location.state?.vesselId;
  const isCoAssured = location?.isCoAssured;
  const [openVesselId, setOpenVesselId] = useState(vesselId);
  const [locationState, setLocationState] = useState({
    fleetId: id,
    ...location.state,
  });

  const navigate = useNavigate();
  const clickVesselHandler = (id) => {
    if (openVesselId === id) {
      setOpenVesselId(null);
      setLocationState((prev) => ({
        ...prev,
        vesselId: null,
      }));
    } else {
      setOpenVesselId(id);
      setLocationState((prev) => ({
        ...prev,
        vesselId: id,
      }));
    }
  };

  const { data: wizardValidationTasks, mutate: mutateWizardValidationTasks } = useWizardValidationTasks();

  const [isAcceptanceLinkVisible, setIsAcceptanceLinkVisible] = useState(null);

  const {data: hasAcceptanceLog, mutate: mutateHasAcceptanceLog} = useAcceptanceLogForFleet(id);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isBeneficialOwnerWizardOpen, setBeneficialOwnerWizardOpen] = useState(false);

  const approvedNotificationText = 'Removed the entry from the KYC portal.';

  const { data, mutate: mutateFleetDetails, loading, error } = useFleetDetails(id);

  const clickAboutHandler = () => {
    setIsAboutOpen((isAboutOpen) => !isAboutOpen);
  };

  const handleBeneficialOwnerWizardOpen = () => {
    mutateFleetDetails();
    setBeneficialOwnerWizardOpen((isBeneficialOwnerWizardOpen) => !isBeneficialOwnerWizardOpen);
  };

  const limit = 20;
  const [offset, setOffset] = useState(0);

  const [sort, setSort] = useState('name');

  const [fieldsSortOrder, setFieldsSortOrder] = useState({
    name: 'asc',
    imo: 'asc',
    flag: 'asc',
    type: 'asc',
    riskLevel: 'desc',
  });
  const sortOrder = fieldsSortOrder[sort] === 'asc' ? `entity.${ sort }` : `-entity.${ sort }`;

  const handleSort = (sort) => {
    setSort(sort);
    setOffset(0);
    setFieldsSortOrder({
      ...fieldsSortOrder,
      [sort]: fieldsSortOrder[sort] === 'asc' ? 'desc' : 'asc',
    });
  };

  const fleet = data;
  const fleetName = fleet?.name;
  const insuranceYear = fleet?.insuranceYear;
  const fleetEtag = fleet?._etag;
  const fleetCovers = fleet?.covers;
  const fleetClaims = fleet?.claims;
  const numberOfInsuredObjects = fleet?.numberOfInsuredObjects;

  const { data: petis, mutate: mutatePETIs } = usePETIs(id);

  const isMissingPetiInformation = fleet?.isMissingPetiInformation;

  const isQuotedFleet = fleet?.isSnapshot === true;

  const { insuredObjects, hasMore, loading: insuredObjectsLoading, mutate: mutateVesselList } = useInsuredObjectList(
    id,
    offset,
    limit,
    sortOrder,
    true && !isQuotedFleet,
    fleet
  );

  const value = {
    fleetId: id,
    fleetName,
    fleetEtag,
    fleetCovers,
    fleetClaims,
    insuranceYear,
    numberOfInsuredObjects,
    locationState,
    navigate,
    hasAcceptanceLog,
    mutateHasAcceptanceLog,
    setIsAcceptanceLinkVisible,
    wizardValidationTasks,
    mutateFleetDetails,
    mutateVesselList,
    mutateWizardValidationTasks,
    error,
    data,
    loading,
    approvedNotificationText,
    isQuotedFleet,
    fleet,
    clickAboutHandler,
    isAboutOpen,
    isBeneficialOwnerWizardOpen,
    sortOrder,
    fieldsSortOrder,
    handleBeneficialOwnerWizardOpen,
    setLocationState,
    petis,
    mutatePETIs,
    isMissingPetiInformation,
    isAcceptanceLinkVisible,
    openVesselId,
    clickVesselHandler,
    isCoAssured,
    limit,
    insuredObjects,
    hasMore,
    insuredObjectsLoading,
    setOffset,
    handleSort,
    showConfirmation,
    setShowConfirmation,
  };

  return <FleetOverviewContext.Provider value={value}>{children}</FleetOverviewContext.Provider>;
};

export const useFleetOverviewContext = () => useContext(FleetOverviewContext);
