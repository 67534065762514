import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { BoxButton } from '@instech/components';
import { ConfirmModalDialog } from '../shared/ConfirmModalDialog';
import { AcceptModalDialog } from '../shared/AcceptModalDialog';
import { AcceptanceLogModalDialog } from '../shared/AcceptanceLogModalDialog';
import { useCompanyContext } from './CompanyContext';
import { postAcceptMissingInfo } from '../../services/postAcceptData';
import { useAppContext } from '../appRouting/AppContext';
import { constants } from 'utils/constants';
import { getMyNhcUrl } from 'utils/environment';
import { MissingData, RiskLevel } from "../../utils/validations/types";
import { AcceptanceReviewStatus, AcceptanceType } from "../../utils/AcceptanceType";
import { useOrganizationAcceptanceReviews } from "../../services/useAcceptanceReviews";
import {
  proposeOrganizationHighRiskAcceptance,
  reviewOrganizationHighRiskAcceptance
} from "../../services/putAcceptanceReviews";

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-column: 6;
  gap: 1rem;
  align-items: center;

  .acceptMissingInfoButton {
    background-color: ${(props) => props.theme.yellow};
    color: ${(props) => props.theme.marineBlue};
    border: none;
    &:hover {
      color: ${(props) => props.theme.white};
    }
  }
  .acceptHighRiskButton {
    background-color: ${(props) => props.theme.status.red};
    color: ${(props) => props.theme.marineBlue};
    &:hover {
      color: ${(props) => props.theme.white};
    }
  } 
  .disabledButton {
    background-color: ${(props) => props.theme.status.grey};
    color: ${(props) => props.theme.darkGray};
  }  
`;

export const AcceptButtons = () => {
  const {
    fleetId,
    organization,
    reference,
    clientRoleType,
    mutate,
    mutateFleetDetails,
    mutateVesselList,
    validations
  } = useCompanyContext();

  const [showAcceptRiskError, setShowAcceptRiskError] = useState(false);
  const [showAcceptMissingInfoError, setShowAcceptMissingInfoError] = useState(false);
  const [isAcceptMissingInfoModalVisible, setIsAcceptMissingInfoModalVisible] = useState(false);
  const [isProposeHighRiskAcceptanceModalVisible, setIsProposeHighRiskAcceptanceModalVisible] = useState(false);
  const [isReviewHighRiskAcceptanceModalVisible, setIsReviewHighRiskAcceptanceModalVisible] = useState(false);
  const [isAcceptanceLogModalVisible, setIsAcceptanceLogModalVisible] = useState(false);

  const { dispatch, isReviewer } = useAppContext();

  const setIsConfirmDialogVisible = (value) => {
    dispatch({ type: constants.SET_IS_CONFIRM_DIALOG_VISIBLE, value });
  }

  const { data: acceptanceReviews, mutate: mutateReviews} = useOrganizationAcceptanceReviews(organization?.id);
  const pendingAcceptanceReview = acceptanceReviews?.find((review) =>
    review.acceptanceType === AcceptanceType.RiskLevel
    && review.status === AcceptanceReviewStatus.Pending);
  const hasPendingReview = !!pendingAcceptanceReview;

  const acceptMissingInfoHandler = useCallback(
    (values) => {
      const { acceptanceDateFrom, acceptanceDateTo, additionalComments } = values;
      const command = {
        subject: {
          id: organization.id,
          partitionKey: organization.id,
          entityType: 'Organization',
        },
        acceptancePeriod: {
          from: acceptanceDateFrom,
          to: acceptanceDateTo,
        },
        acceptanceType: 'MissingData',
        comments: additionalComments,
      };
      postAcceptMissingInfo(organization.id, command, organization._etag)
        .then(() => {
          mutateFleetDetails && mutateFleetDetails();
          mutate();
          mutateVesselList && mutateVesselList();
        })
        .catch((error) => {
          console.error(error);
          setShowAcceptMissingInfoError(error);
        });
      setIsAcceptMissingInfoModalVisible(false);
      setIsConfirmDialogVisible(true);
    },
    [organization, mutate, mutateFleetDetails, mutateVesselList]
  );

  const proposeHighRiskAcceptanceHandler = useCallback(
    (values) => {
      const { acceptanceDateFrom, acceptanceDateTo, additionalComments, reviewerSubjectIds } = values;
      const command = {
        organizationId: organization.id,
        data: {
          acceptancePeriod: {
            from: acceptanceDateFrom,
            to: acceptanceDateTo,
          },
          comments: additionalComments,
          reviewerSubjectIds: reviewerSubjectIds?.map(x => x.value) ?? [],
          fleetId: fleetId,
        },
        eTag: pendingAcceptanceReview?._etag ?? '*',
      };
      proposeOrganizationHighRiskAcceptance(command)
        .then(() => {
          mutate();
          mutateFleetDetails && mutateFleetDetails();
          mutateVesselList && mutateVesselList();
          mutateReviews && mutateReviews();
        })
        .catch((error) => {
          console.error(error);
          setShowAcceptRiskError(error);
        });
      setIsProposeHighRiskAcceptanceModalVisible(false);
    },
    [organization, mutate, mutateFleetDetails, mutateVesselList, pendingAcceptanceReview]
  );

  const reviewHighRiskAcceptanceHandler = useCallback(
    (values) => {
      const { additionalComments, acceptanceReviewStatus } = values;
      const command = {
        organizationId: organization.id,
        data: {
          reviewComments: additionalComments,
          acceptanceReviewStatus: acceptanceReviewStatus
        },
        eTag: pendingAcceptanceReview._etag,
      };

      reviewOrganizationHighRiskAcceptance(command)
        .then(() => {
          mutate();
          mutateFleetDetails && mutateFleetDetails();
          mutateVesselList && mutateVesselList();
          mutateReviews && mutateReviews();
          if (acceptanceReviewStatus === AcceptanceReviewStatus.Accepted) {
            setIsConfirmDialogVisible(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setShowAcceptRiskError(error);
        });
      setIsReviewHighRiskAcceptanceModalVisible(false);
    },
    [organization, mutate, mutateFleetDetails, mutateVesselList, pendingAcceptanceReview]
  );

  const showMissingInfoDialog = () => {
    setIsAcceptMissingInfoModalVisible(true);
  };

  const showProposeHighRiskAcceptanceDialog = () => {
    setIsProposeHighRiskAcceptanceModalVisible(true);
  };

  const showReviewHighRiskAcceptanceDialog = () => {
    setIsReviewHighRiskAcceptanceModalVisible(true);
  };

  const handleAcceptMissingInfo = () => {
    setIsAcceptMissingInfoModalVisible(false);
  };

  const handleProposeHighRiskAcceptanceInfo = () => {
    setIsProposeHighRiskAcceptanceModalVisible(false);
  };

  const handleReviewHighRiskAcceptanceInfo = () => {
    setIsReviewHighRiskAcceptanceModalVisible(false);
  };

  const handleAcceptLogClose = () => {
    setIsAcceptanceLogModalVisible(false);
  };

  const linkToMyNhc = useMemo(() => "https://" + getMyNhcUrl(organization.id), [organization]);

  return (
    <ButtonsWrapper>

      <a href={linkToMyNhc} target="_blank" rel='noreferrer'>
        <BoxButton>OPEN IN MYNHC</BoxButton>
      </a>

      {((validations.missingData - validations.acceptedMissingData) & MissingData.CompanyData) > MissingData.None && (
        <BoxButton
          aria-label='Accept missing info'
          onClick={showMissingInfoDialog}
          type='button'
          className='acceptMissingInfoButton'
        >
          ACCEPT MISSING INFO
        </BoxButton>
      )}
      {!validations.acceptedHighRisk && validations.riskLevel === RiskLevel.High && !hasPendingReview && (
        <BoxButton
          aria-label='Propose HR acceptance'
          onClick={showProposeHighRiskAcceptanceDialog}
          type='button'
          className='propseHighRiskAcceptanceButton'
        >
          PROPOSE HIGH RISK ACCEPTANCE
        </BoxButton>
      )}
      {!validations.acceptedHighRisk && validations.riskLevel === RiskLevel.High && hasPendingReview && (
        <BoxButton
          aria-label='Review HR acceptance'
          disabled={!isReviewer}
          onClick={showReviewHighRiskAcceptanceDialog}
          type='button'
          title={!isReviewer ? 'You do not have permission to review high risk acceptance' : ''}
          className={isReviewer ? 'acceptHighRiskButton' : 'disabledButton'}
        >
          REVIEW HIGH RISK ACCEPTANCE
        </BoxButton>
      )}

      {showAcceptRiskError && (
        <ConfirmModalDialog
          text='An error occurred when trying to accept the risk.'
          isModalDialogVisible={showAcceptRiskError}
          onClick={() => setShowAcceptRiskError(false)}
          onCancel={() => setShowAcceptRiskError(false)}
        />
      )}
      {showAcceptMissingInfoError && (
        <ConfirmModalDialog
          text='An error occurred when trying to accept the risk.'
          isModalDialogVisible={showAcceptMissingInfoError}
          onClick={() => setShowAcceptMissingInfoError(false)}
          onCancel={() => setShowAcceptMissingInfoError(false)}
        />
      )}
      {isAcceptMissingInfoModalVisible && (
        <AcceptModalDialog
          isModalDialogVisible={isAcceptMissingInfoModalVisible}
          onClick={() => setIsAcceptMissingInfoModalVisible(false)}
          isMissingInfo='true'
          forType={clientRoleType ?? 'Client'}
          forDescription={reference.displayName}
          text='Fill in to accept missing information'
          labelText='Reason for accepting missing info'
          cancelButtonText='Cancel'
          acceptButtonText='Accept missing info'
          onCancel={handleAcceptMissingInfo}
          onAccept={acceptMissingInfoHandler}
        />
      )}
      {isProposeHighRiskAcceptanceModalVisible && (
        <AcceptModalDialog
          isModalDialogVisible={isProposeHighRiskAcceptanceModalVisible}
          onClick={() => setIsProposeHighRiskAcceptanceModalVisible(false)}
          isHighRisk='true'
          forType={clientRoleType ?? 'Client'}
          forDescription={reference.displayName}
          text='Fill in to propse high risk acceptance'
          labelText='Reason for accepting high risk'
          cancelButtonText='Cancel'
          acceptButtonText='Propose high risk acceptance'
          onCancel={handleProposeHighRiskAcceptanceInfo}
          onAccept={proposeHighRiskAcceptanceHandler}
        />
      )}
      {isReviewHighRiskAcceptanceModalVisible && (
        <AcceptModalDialog
          isModalDialogVisible={isReviewHighRiskAcceptanceModalVisible}
          onClick={() => setIsReviewHighRiskAcceptanceModalVisible(false)}
          isHighRisk='true'
          forType={clientRoleType ?? 'Client'}
          forDescription={reference.displayName}
          text='Fill in to review high risk acceptance'
          labelText='Proposed reason for accepting high risk'
          reviewLabelText='Reason for accepting/rejecting proposal'
          cancelButtonText='Cancel'
          acceptButtonText='Accept high risk'
          rejectButtonText='Reject high risk'
          pendingReview={pendingAcceptanceReview}
          onCancel={handleReviewHighRiskAcceptanceInfo}
          onAccept={reviewHighRiskAcceptanceHandler}
        />
      )}
      {isAcceptanceLogModalVisible && (
        <AcceptanceLogModalDialog
          isModalDialogVisible={isAcceptanceLogModalVisible}
          fleetId={fleetId}
          onClose={handleAcceptLogClose}
        />
      )}
    </ButtonsWrapper>
  );
};
