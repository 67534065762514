import React, { useState } from 'react';
import styled from 'styled-components';

const CheckboxField = styled.div`
  display: flex;
  flex-direction: row;
  & label {
    display: flex;
    align-items: center;
    line-height: 21px;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    // This is needed to make the text truncation work in the children
    overflow: hidden;
  }
  & input[type='checkbox']:disabled {
    cursor: default;
  }
  & input[type='checkbox'] {
    position: relative;
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    line-height: 21px;
    box-sizing: content-box;
    appearance: none;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    &::before {
      content: '';
      display: inline-block;
      left: 0px;
      width: 20px;
      height: 20px;
      background: ${(props) => (props.disabled ? props.theme.lightGray : props.theme.white)};
      border: 1px solid
        ${(props) =>
          props.error ? props.theme.red : props.disabled ? props.theme.mediumGray : props.theme.border.gray};
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
    }
    &:checked::before {
      border-color: ${(props) => props.theme.marineBlue};
      background: ${(props) => props.theme.marineBlue};
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0px;
      height: 0px;
      bottom: 5px;
      left: 7px;
      border: 1px solid transparent;
      border-bottom-color: ${(props) => (props.disabled ? props.theme.lightGray : props.theme.white)};
      border-right-color: ${(props) => (props.disabled ? props.theme.lightGray : props.theme.white)};
      transform: rotate(45deg);
    }
    &:checked::after {
      width: 5px;
      height: 13px;
    }
  }
`;

export const Checkbox = ({ label, handleChange, checked = false }) => {
  const [value, setValue] = useState(checked);
  return (
    <div>
      <CheckboxField>
        <label>
          <input
            type='checkbox'
            onChange={() => {
              setValue((prev) => !prev);
              handleChange();
            }}
            checked={value}
          />
          {label}
        </label>
      </CheckboxField>
    </div>
  );
};
