export enum MissingData{
    None = 0,
    CompanyData = 1,
    Peti = 2,
    BeneficialOwner = 8,
    PersonRoles = 16,
    UltimateBeneficialOwner = 32,
    ProofOfRegistration = 64,
}

export enum MissingCompanyData{
    None = 0,
    Required = 1,
    LegalForm = 2,
    All = Required | LegalForm
}

export enum RiskLevel {
    Low = 0,
    Moderate = 1,
    High = 2
}

export type ValidationFlags = {
    riskLevel: RiskLevel,
    missingData: MissingData,
    acceptedMissingData: MissingData,
    acceptedHighRisk: boolean,
}
