import React, { ReactNode } from 'react';
import styled from 'styled-components';

type ContentBlockTypes = 'default' | 'warning' | 'secondary';

const ContentWrapper = styled.div<{ variant: ContentBlockTypes }>`
  background: ${({ theme }) => theme.white};
  border-top: ${({ variant }) => variant == 'secondary' ? '0' : '4px'} solid ${({ variant, theme }) => variant == 'warning' ? theme.yellow : theme.green};
  box-shadow: ${({ theme }) => theme.boxShadow.boxShadowMain};
  padding: 32px;
  margin-bottom: 32px;

  :last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.div<{ variant: ContentBlockTypes }>`
  font-weight: bold;
  margin-bottom: ${({ variant }) => variant == 'secondary' ? '4px' : '0'};
`;

type ContentBlockProps = {
  title?: ReactNode;
  type?: ContentBlockTypes;
  children: ReactNode;
}

export const ContentBlock = ({ title, type = 'default', children }: ContentBlockProps) => (
  <>
    {title && <Title variant={type}>{title}</Title>}
    <ContentWrapper variant={type}>
      {children}
    </ContentWrapper>
  </>
);
