import { getCountriesWithRequiredTaxData } from '../services/getCountriesWithRequiredTaxData';
import { getCountriesWithRequiredState } from '../services/getCountriesWithRequiredState';

export const checkTaxDataRequired = (countryCode) => {
  const countriesWithRequiredTaxData = getCountriesWithRequiredTaxData(countryCode);	
  return countriesWithRequiredTaxData ? countriesWithRequiredTaxData?.some((item) => item.value === countryCode) : false;
};

export const checkStateRequired = (countryCode) => {
  const countriesWithRequiredState = getCountriesWithRequiredState(countryCode);
  return countriesWithRequiredState ? countriesWithRequiredState?.some((item) => item.value === countryCode) : false;
};