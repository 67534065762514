import { useEffect, useState, useRef } from 'react'
import { getPersonRoles } from './postPutPersonData';
import { formatDateOfBirth } from '../utils/date';

export const usePersonsList = (shouldFetch, params, isPersonUpdated) => {
  const [personsList, setPersonsList] = useState({persons: []});
  const paramsRef = useRef(params);
  paramsRef.current = params;

  useEffect(() => {
    if (!shouldFetch) return;
    const searchData = {...params, dateOfBirth: formatDateOfBirth(params?.dateOfBirth)};
    getPersonRoles(searchData).then(data => {
      paramsRef.current === params && setPersonsList({ persons: data })
    });
  }, [shouldFetch, params, isPersonUpdated]);

  return personsList;
};
