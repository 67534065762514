import { isNullOrWhiteSpace } from 'utils/stringUtils';
import { ValidationSchema } from './validationSchema';
import { getStatesPromise } from '../../services/getStatesList';
import { yupToFormErrors } from 'formik';
import { constants } from '../../utils/constants';
import { checkOverlappingDates } from '../../utils/checkOverlappingDates';
import { HasFlag } from '../../utils/validations/functions';
import {MissingCompanyData, MissingData} from '../../utils/validations/types';

export const onKeyDown = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13 && keyEvent.target.type !== 'textarea') {
    keyEvent.preventDefault();
  }
};

export const setNamedCountryReference = (selected, options) => {
  if (selected && selected.id && options && options.length > 0) {
    const selectedOption = options.find((x) => x.id === selected.id);

    if (selectedOption) {
      selected.partitionKey = selectedOption.twoLetterCountryCode;
      selected.displayName = selectedOption.name;
    }
  }
};

export const setNamedStateReference = (selected, options) => {
  if (selected && selected.id && options && options.length > 0) {
    const selectedOption = options.find((x) => x.id === selected.id);

    if (selectedOption) {
      selected.partitionKey = selectedOption.twoLetterCountryCode;
      selected.displayName = !isNullOrWhiteSpace(selectedOption.name) ? selectedOption.name : selectedOption.localName;
      selected.subdivisionCode = selectedOption.subdivisionCode;
    }
  }
};

const statesCache = {};

export const getStates = (selectedCountryId, countries) => {
  if (statesCache[selectedCountryId]) {
    return Promise.resolve(statesCache[selectedCountryId]);
  }

  const selectedCountry = countries && selectedCountryId ? countries.find((x) => x.id === selectedCountryId) : null;

  if (!selectedCountry) {
    return Promise.resolve([]);
  }

  return getStatesPromise(selectedCountry)
    .then((states) => {
      statesCache[selectedCountryId] = states;
    })
    .catch(() => Promise.resolve([])); //to work with local files
};

export const shouldValidate = (validations, isNewCompany, isNewRoleType) => {
  return (
    ((validations.missingData === MissingCompanyData.None || //validate data if company has no missing data and no accepted risks
      validations.acceptedMissingData) &&
      !isNewCompany &&
      !isNewRoleType) ||
    (validations.missingData > MissingCompanyData.None && //validate data if company has missing data and no accepted missing data risk
      !validations.acceptedMissingData &&
      !isNewCompany &&
      !isNewRoleType) ||
    (validations.missingData > MissingCompanyData.None && //validate data if company is added as new co-assured with new role and has missing info
      !isNewCompany &&
      isNewRoleType) ||
    !isNewCompany
  ); // do not validate data if new company form
};

export const validate = async (values, countries, isTaxDataRequired, isStateRequired) => {
  // Provide companyStates and states as context in Yup validation
  const [states] = await Promise.all([getStates(values.legalAddress.country.id, countries)]);
  try {
    await ValidationSchema.validate(values, {
      abortEarly: false,
      context: {
        states: states,
        country: values.legalAddress.country,
        isTaxDataRequired: isTaxDataRequired,
        isStateRequired: isStateRequired,
      },
    });
  } catch (err) {
    if (err.name === 'ValidationError') {
      return yupToFormErrors(err);
    }
    console.error(err);
  }
};

export const getPersonNotification = (values, forMultipleVessels, isNewCompany, validations, executiveRolesComplete) => {
  const hasUBO = values?.roles.some((role) => role.isActive && role.type === 'UBO');

  let personNotification;
  if (!forMultipleVessels && !isNewCompany && HasFlag(validations.missingData, MissingData.UltimateBeneficialOwner)) {
    if (!hasUBO) {
      personNotification = 'UBO must be added to this company.';
    }
  } else if (!forMultipleVessels && !isNewCompany && HasFlag(validations.missingData, MissingData.PersonRoles)) {
    const rolesCount = values?.roles.filter(role => role.isActive).length;

    if (!rolesCount) {
      personNotification = 'At least one person must be added to this company.';
    }
    if (rolesCount && !executiveRolesComplete){
      personNotification = 'You must confirm that all people who exercise control are listed.';
    }
  }
  return personNotification;
};

export const checkExistingRole = (
  id,
  dateFrom,
  dateTo,
  orgId,
  organizationsListForChecking,
  organizationForCheckingIndex,
  formDispatch
) => {
  const shouldCheckExistingRole =
    organizationsListForChecking &&
    organizationsListForChecking.length > 0 &&
    organizationsListForChecking.some((c) => c.role?.organization.id === orgId);

  const isRoleTypeExists =
    shouldCheckExistingRole &&
    !!id &&
    organizationsListForChecking.some(
      (item, index) =>
        item.role.roleType.id === id &&
        checkOverlappingDates(item.role, dateFrom, dateTo) &&
        index !== organizationForCheckingIndex &&
        orgId === item.role.organization.id
    );
  formDispatch({ type: constants.SET_SHOW_ROLE_EXISTS_MESSAGE, value: isRoleTypeExists });
};
