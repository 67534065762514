import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useOrganizationRolesByOrganizationId } from 'services/useOrganizationRoles';
import { Load } from '@instech/icons';
import { groupBy } from '../../utils/groupBy';
import { distinctBy } from '../../utils/distinctBy';
import { ParseValidationFlags } from "../../utils/validations/functions";
import { StatusIcons } from "../shared/kyc-icons/StatusIcons";
import { ContentBlock } from '../shared/ContentBlock';
import {MissingCompanyData} from "../../utils/validations/types";

const StyledLoad = styled(Load)`
  height: 20px;
  align-self: center;
  padding: 10px;
`;
const Span = styled.div`
  padding: 10px;
`;
const RolesHeader = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.hasObjects ? '1fr 3fr 6fr 6fr' : '1fr 3fr 6fr'};
  grid-gap: 1em;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue50};
  font-weight: bold;
  font-size: 14px;
`;
const RolesTable = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.hasObjects ? '1fr 3fr 6fr 6fr' : '1fr 3fr 6fr'};
  row-gap: 0;
  column-gap: 16px;
  align-items: center;
  padding: 10px 20px;
  &:nth-child(even) {
    background: ${(props) => props.theme.flatWhite};
  }
`;
const CompanyLinkWrapper = styled.span`
  display: inline-grid;
  grid-template-columns: auto auto;
  
  a:first-of-type {
    margin-right: 4px;
  }
`;
const ShowMoreButton = styled.button`
  background: none;
  border: none;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  color: ${(props) => props.theme.marineBlue};
`;

export const CompanyRoleList = ({ organization }) => {
  let { data: roles } = useOrganizationRolesByOrganizationId(organization.id);
  roles = roles?.filter((r) => r.isActive) ?? [];

  const groupedRolesFleets = groupBy(roles, (r) => r.roleType.roleName + ':' + r.fleet.id).map((g) => ({
    key: g.key,
    ...g.value[0], // Will include 'id' property as well but it shouldn't matter
    objects: distinctBy(
      g.value.reduce((acc, next) => [...acc, ...(next.objects ?? [])], []),
      (x) => x.id
    ).sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())),
    relatedCompanies: distinctBy(
        g.value.reduce((acc, next) => [...acc, ...(next.clients ?? []).concat(next.petis ?? [])], [])
        .filter((x) => x.isActive),
        (x) => x.organization.id
    ).sort((a, b) => a.organization.displayName.toUpperCase().localeCompare(b.organization.displayName.toUpperCase())),
  }));

  const totalAmountOfRoles = groupedRolesFleets.reduce(
    (acc, next) => (next.objects.length > 0 ? acc + next.objects.length : acc + 1),
    0
  );
  const hasObjects = groupedRolesFleets.some((x) => x.objects.length > 0);
  const hasPetis = groupedRolesFleets.some((x) => x.roleType.roleName === 'PETI');
  const hasClients = groupedRolesFleets.some((x) => x.roleType.roleName === 'Client');
  const roleColumnRoles = [];
  hasPetis && roleColumnRoles.push('Clients');
  hasClients && roleColumnRoles.push('PETIs');

  const [expandedObjectsItemId, setExpandedObjectsItemId] = useState(null);
  const [expandedRolesItemId, setExpandedRolesItemId] = useState(null);

  const showAllObjects = (itemId) => setExpandedObjectsItemId(itemId);
  const hideAllObjects = () => setExpandedObjectsItemId(null);

  const isMissingInfo = organization.missingData > MissingCompanyData.None && !organization.acceptedMissingData;

  return (
    <ContentBlock
      title={"Roles For The Company " + (totalAmountOfRoles ? `(${totalAmountOfRoles})` : "")}
      type={isMissingInfo ? "warning" : "default"}
    >
      {roles && roles.length > 0 && (
        <RolesHeader hasObjects={hasObjects}>
          <span>Role</span>
          <span>Fleet</span>
          {hasObjects && <span>Objects</span>}
          <span>{roleColumnRoles.join('/')}</span>
        </RolesHeader>
      )}
      {roles &&
        groupedRolesFleets.length > 0 &&
        groupedRolesFleets.map((item, index) => {
          const numberOfObjects = item.objects.length;
          return (
            <RolesTable key={item.key + index} hasObjects={hasObjects}>
              <span>{item.roleType.roleName}</span>
              <span>
                <Link to={{ pathname: `/fleets/${item.fleet.id}` }}>
                  {item.fleet.name} ({item.fleet.insuranceYear})
                </Link>
              </span>
              {hasObjects && <span>
                {expandedObjectsItemId === item.key
                    ? (<span>
                      {item.objects.map(object => object.name).join(', ')}
                    </span>)
                    : (<span>
                      {item.objects.map(object => object.name).slice(0,5).join(', ')}
                    </span>)}
                {numberOfObjects > 5 && (
                  expandedObjectsItemId === item.key ? (
                    <ShowMoreButton type='button' onClick={() => hideAllObjects()}>
                      ...less
                    </ShowMoreButton>
                  ) : (
                    <ShowMoreButton type='button' onClick={() => showAllObjects(item.key)}>
                      ...more
                    </ShowMoreButton>
                  )
                )}
              </span>}
              <span>
                {
                    item.relatedCompanies.length > 0 &&
                      item.relatedCompanies.map(cmp =>
                        <CompanyLinkWrapper key={`${item.key}:${cmp.organization.id}`}>
                          <Link to={{ pathname: `/companies/${cmp.organization.id}` }}>
                            {cmp.organization.displayName}
                          </Link>
                          <StatusIcons
                              validations={ParseValidationFlags(cmp.validations)}
                              entityType='Company'
                              sourceType='CompanyRoles'
                          />
                        </CompanyLinkWrapper>)
                      .slice(0, expandedRolesItemId === item.key ? item.relatedCompanies.length : 5)
                      .reduce((prev, curr) => [prev, ', ', curr])
                }
                {item.relatedCompanies.length > 5 ? (
                    expandedRolesItemId === item.key ? (
                        <ShowMoreButton type='button' onClick={() => setExpandedRolesItemId(null)}>
                          ...less
                        </ShowMoreButton>
                    ) : (
                        <ShowMoreButton type='button' onClick={() => setExpandedRolesItemId(item.key)}>
                          ...more
                        </ShowMoreButton>
                    )
                ) : null}
              </span>
            </RolesTable>
          );
        })}
      {!roles && <StyledLoad />}
      {roles && roles.length === 0 && <Span>This company does not have any active roles.</Span>}
    </ContentBlock>
  );
};

CompanyRoleList.propTypes = {
  organization: PropTypes.object,
};
