import { AcceptanceReviewStatus } from "./AcceptanceType";
import { AcceptanceLog } from "../services/useAcceptanceLogs";

export const getAcceptanceLogComments = (acceptanceLog: AcceptanceLog) => {
    switch (acceptanceLog.acceptanceReviewStatus) {
        case AcceptanceReviewStatus.Pending:
            return 'Proposed: ' + acceptanceLog.comments;
        case AcceptanceReviewStatus.Accepted:
            return 'Accepted: ' + acceptanceLog.comments;
        case AcceptanceReviewStatus.Rejected:
            return 'Rejected: ' + acceptanceLog.comments;
        default:
            return acceptanceLog.comments;
    }
}
