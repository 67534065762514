import { baseTheme } from '@instech/components';

export const theme = {
  ...baseTheme,
    purple35: '#DECCE5',
    background: {
    primary: '#D9E2EA',
    red: '#EDD3CF',
  },
  boxShadow: {
    boxShadowMain: '2px 2px 3px rgba(134, 134, 134, 0.1), -2px 2px 3px rgba(134, 134, 134, 0.1)',
  },
};
