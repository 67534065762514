import { useBlocker } from 'react-router-dom';
import { ConfirmModalDialog } from '../shared/ConfirmModalDialog';

export const PromptUnsavedChanges = ({ isDirty }: { isDirty: boolean }) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => isDirty && currentLocation.pathname !== nextLocation.pathname
  );
  return (
    <>
      {blocker.state === 'blocked' ? (
        <ConfirmModalDialog
          text="Are you sure you want to quit without saving your changes?"
          additionalText={''}
          cancelButtonText="Cancel"
          confirmButtonText="Ok"
          onCancel={() => blocker.reset()}
          onConfirm={() => blocker.proceed()}
        />
      ) : null}
    </>
  );
};
