import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BoxButton,
  ComplexTable,
  ComplexTableHeaders,
  IconButton,
  TableCell,
  TableRow,
} from '@instech/components';
import { useProofOfRegistrationList } from 'services/useProofOfRegistrations';
import { Download } from '@instech/icons';
import { fetchConfig } from 'services/fetchConfig';
import { getMyNhcUrl } from 'utils/environment';
import { ContentBlock } from '../shared/ContentBlock';

const InfoWrapper = styled.div`
  background: ${(props) => props.theme.marineBlue10};
  border-top: 4px solid ${(props) => props.theme.marineBlue70};
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
  margin-top: 12px;
`;

const InfoText = styled.p`
  margin: 0;
  padding: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const tableHeaders = [
  {
    title: 'File Name',
    propertyName: 'information.fileName',
    parameterName: 'fileName',
  },
  {
    title: 'Uploaded Time',
    propertyName: 'uploadedTime',
    parameterName: 'uploadedTime',
  },
  { title: 'Download', propertyName: 'download', parameterName: 'download' },
];

const ProofOfRegistrationRow = ({
  proofOfRegistration,
  organizationId,
  even,
}) => {
  const download = async () => {
    const response = await fetchConfig.get(
      `proofOfRegistration/${organizationId}/${proofOfRegistration.information.id}`,
      { responseType: 'blob' }
    );
    const url = URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${proofOfRegistration.information.fileName}`
    );
    document.body.appendChild(link);

    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  return (
    <TableRow even={even}>
      <TableCell>{proofOfRegistration.information.fileName}</TableCell>
      <TableCell>{proofOfRegistration.uploadedTime}</TableCell>
      <TableCell>
        <IconButton
          onClick={download}
          smallIcon
          height={'24px'}
          icon={<Download />}
        />
      </TableCell>
    </TableRow>
  );
};

const getProperty = (proof, path) => {
  const pathArray = path.split('.');
  let value = proof;
  for (const key of pathArray) {
    if (key in value) {
      value = value[key];
    } else {
      return;
    }
  }
  return value;
};

const sortArrayOfObjects = (proofs, property, sortOrder) => {
  if (!proofs || !property || !sortOrder) return;
  const orderFactor = sortOrder === 'desc' ? -1 : 1;

  proofs?.sort((a, b) => {
    const valueA = getProperty(a, property)?.toLowerCase();
    const valueB = getProperty(b, property)?.toLowerCase();

    if (valueA < valueB) {
      return -1 * orderFactor; // Multiply by orderFactor to reverse the order if needed
    }
    if (valueA > valueB) {
      return 1 * orderFactor;
    }
    return 0;
  });
};

export const ProofOfRegistration = ({ organizationId }) => {
  const [sortedHeader, setSortedHeader] = useState();
  const { data } = useProofOfRegistrationList(organizationId);
  const linkToMyNhc = useMemo(
    () => 'https://' + getMyNhcUrl(organizationId),
    [organizationId]
  );

  useMemo(() => {
    sortArrayOfObjects(
      data?.files,
      sortedHeader?.propertyName,
      sortedHeader?.sortOrder
    );
  }, [sortedHeader]);

  return (
    <ContentBlock title="Proof of Registration">
      <ButtonWrapper>
        <a href={linkToMyNhc} target='_blank' rel='noreferrer'>
          <BoxButton>OPEN IN MYNHC</BoxButton>
        </a>
      </ButtonWrapper>
      <InfoWrapper>
        <InfoText>Open in MyNHC to upload or delete documents. </InfoText>
      </InfoWrapper>
      {data?.files?.length > 0 && (
        <ComplexTable columns={3}>
          <ComplexTableHeaders
            headers={tableHeaders}
            sortedHeader={sortedHeader}
            setSortedHeader={setSortedHeader}
          />
          {data?.files?.map((proof, index) => (
            <ProofOfRegistrationRow
              key={proof.information.id}
              organizationId={organizationId}
              proofOfRegistration={proof}
              even={index % 2 == 0}
            />
          ))}
        </ComplexTable>
      )}
      {data?.files.length <= 0 && <p>There is no data available</p>}
    </ContentBlock>
  );
};
