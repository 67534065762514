import useSWR from 'swr';

// TODO: check that matched the topic
export const useWizardValidationTasks = () => useSWR(`/backgroundTasks/validation?entityType=fleet`);

export const useQuotedFleetValidationTasks = () => useSWR(`/backgroundTasks/create-quoted-fleet`);

export const useCountryValidationTasks = (shouldFetch) =>
  useSWR(shouldFetch ? `/backgroundTasks/validation?entityType=country` : null);

export const useInterestCodeValidationTasks = (shouldFetch) =>
  useSWR(shouldFetch ? `/backgroundTasks/validation?entityType=InterestCode` : null);

export const useInterestGroupValidationTasks = (shouldFetch) =>
  useSWR(shouldFetch ? `/backgroundTasks/validation?entityType=InterestGroup` : null);
