import {DefaultValidationFlags} from "./constants";
import {MissingCompanyData, MissingData, RiskLevel, ValidationFlags} from "./types";

export const ParseCompanyMissingDataFlags = (missingData: string) : MissingCompanyData => {
    return missingData?.split(", ")
        .map((x) => MissingCompanyData[x as keyof typeof MissingCompanyData])
        .reduce((a, b) => a | b, 0) ?? 0;
}

export const ParseCompanyValidationFlags = (flags: { riskLevel: string, missingData: string, acceptedMissingData: boolean, acceptedHighRisk: boolean }) : ValidationFlags => {
    const missingDataParsed = ParseCompanyMissingDataFlags(flags?.missingData);
    return !!flags
        ? {
            riskLevel: stringToRiskLevel(flags.riskLevel),
            missingData: missingDataParsed > MissingCompanyData.None ? MissingData.CompanyData : MissingData.None,
            acceptedHighRisk: flags.acceptedHighRisk,
            acceptedMissingData: flags.acceptedMissingData ? MissingData.CompanyData : MissingData.None}
        : DefaultValidationFlags;
}

export const ParseValidationFlags = (flags: { riskLevel: string, missingData: string, acceptedMissingData: string, acceptedHighRisk: boolean }) : ValidationFlags => {
    const missingDataParsed = flags?.missingData?.split(", ")
        .map((x) => MissingData[x as keyof typeof MissingData])
        .reduce((a, b) => a | b, 0);

    const acceptedMissingDataParsed = flags?.acceptedMissingData?.split(", ")
        .map((x) => MissingData[x as keyof typeof MissingData])
        .reduce((a, b) => a | b, 0);
    
    return !!flags
        ? {
            riskLevel: stringToRiskLevel(flags.riskLevel),
            missingData: missingDataParsed,
            acceptedHighRisk: flags.acceptedHighRisk,
            acceptedMissingData: acceptedMissingDataParsed}
        : DefaultValidationFlags;
}

export const AggregateValidationFlags = (flags: ValidationFlags[]) : ValidationFlags => {
    const riskLevel = getMaxRiskLevel(flags);
    const acceptedHighRisk = riskLevel === RiskLevel.High && flags.filter(x => x.riskLevel===RiskLevel.High).every(x => x.acceptedHighRisk);
    const missingData = flags.reduce((acc, cur) => acc | cur.missingData, MissingData.None);
    const acceptedMissingData = (missingData 
            ^ flags.reduce((acc, cur) => acc | (cur.missingData ^ cur.acceptedMissingData), MissingData.None))
        & (MissingData.CompanyData | MissingData.PersonRoles);
    
    return {riskLevel, acceptedHighRisk, missingData, acceptedMissingData};
};

const getMaxRiskLevel = (flags: ValidationFlags[]) : RiskLevel => {
    if(flags.some(x => x.riskLevel === RiskLevel.High)) return RiskLevel.High;
    if(flags.some(x => x.riskLevel === RiskLevel.Moderate)) return RiskLevel.Moderate;
    return RiskLevel.Low;
}

const stringToRiskLevel = (riskLevel: string) => {
    if(riskLevel === "High")
        return RiskLevel.High;
    if(riskLevel === "Moderate")
        return RiskLevel.Moderate;
    return RiskLevel.Low;
}

export const HasFlag = (value:MissingData, expected:MissingData) => (value & expected) === expected; 
