import React from 'react';
import styled from 'styled-components';
import { SlimButton } from '@instech/components';
import { Formik, Form } from 'formik';
import { SelectField } from '../shared/formFields/SelectField';
import { InputField } from '../shared/formFields/InputField';
import { useCompaniesPageContext } from './CompaniesPageContext';

const SearchForm = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 0.5fr;
  grid-gap: 4px;
  align-items: stretch;
  padding: 16px 72px;
  max-width: 800px;
  margin: 0 auto;
`;

export const SearchCompany = () => {
  const { companyQuery, handleSearch, countries } = useCompaniesPageContext();

    const handleOnSubmit = (values) => {
      values = {
          ...values,
          searchString: values.searchString
              .replaceAll('--', '')
              .replaceAll('&&', '')
              .replaceAll('||', '')
              .replaceAll('#', '')
      };
      handleSearch(values);
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          countryCode: companyQuery?.countryCode || '',
          searchString: companyQuery?.searchString || '',
        }}
        onSubmit={handleOnSubmit}
      >
        {({ values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => {
          return (
            <Form>
              <SearchForm>
                <InputField
                  forSearchBox
                  placeholder='Search by company name, number or lei'
                  name='searchString'
                  value={values.searchString}
                  type='text'
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <SelectField
                  noBorder
                  name='countryCode'
                  placeholder='Filter by Country'
                  options={countries}
                  displayNameSelector={(x) => x.name}
                  valueNameSelector={(x) => x.twoLetterCountryCode}
                  handleChange={(name, value) => setFieldValue(name, value)}
                  handleBlur={setFieldTouched}
                  value={values.countryCode || ''}
                />
                <SlimButton aria-label='Search company' type='submit'>
                  SEARCH
                </SlimButton>
              </SearchForm>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
