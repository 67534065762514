import React from 'react';
import styled from 'styled-components';
import {
  Question,
  Approved,
  HighRisk,
  MissingBo,
  MissingInfo,
  MissingPeti,
  MissingRole,
  MissingUbo,
  ModerateRisk,
  MissingPoR,
} from '@instech/icons';

const CircleCheck = styled(Approved)`
  background-color: ${(props) => props.theme.green};
  border-radius: 50%;
  padding: 2px;
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.white};
`;
const QuestionIcon = styled(Question)`
  color: ${(props) => props.theme.marineBlue};
`;
const StyledHighRisk = styled(HighRisk)`
  color: ${(props) => props.theme.status.red};
`;
const StyledAcceptedHighRisk = styled(HighRisk)`
  color: transparent;
`;
const StyledMissingInfo = styled(MissingInfo)`
  color: ${(props) => props.theme.yellow};
`;
const StyledAcceptedMissingInfo = styled(MissingInfo)`
  color: transparent;
`;
const StyledAcceptedMissingRole = styled(MissingRole)`
  color: transparent;
`;
const StyledMissingBO = styled(MissingBo)`
  color: ${(props) => props.theme.yellow};
`;
const StyledMissingPETI = styled(MissingPeti)`
  color: ${(props) => props.theme.yellow};
`;
const StyledMissingRole = styled(MissingRole)`
  color: ${(props) => props.theme.yellow};
`;
const StyledMissingUBO = styled(MissingUbo)`
  color: ${(props) => props.theme.yellow};
`;
const StyledAcceptedMissingUBO = styled(MissingUbo)`
  color: transparent;
`;
const StyledMissingPOR = styled(MissingPoR)`
  color: ${(props) => props.theme.yellow};
`;
const Wrapper = styled.div`
  font-weight: normal;
  transition: all 0.5s;
  margin: 0 2px;
  display: flex;
  align-items: center;
`;
const Tooltip = styled.div`
  position: relative;
  cursor: pointer;

  &:hover span {
    top: -150%;
    visibility: visible;
    opacity: 1;
  }
`;
const TooltipText = styled.span`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background: ${(props) => props.theme.marineBlue};
  color: ${(props) => props.theme.white};
  white-space: nowrap;
  padding: 4px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -14px;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: ${(props) => props.theme.marineBlue} transparent transparent transparent;
  }
`;

type StatusIconProps = {
  text: string;
  questionIcon?: boolean;
  missingInfoIcon?: boolean;
  missingBOIcon?: boolean;
  missingPetiIcon?: boolean;
  missingPersonRolesIcon?: boolean;
  missingUboIcon?: boolean;
  acceptedMissingInfoIcon?: boolean;
  acceptedMissingRolesIcon?: boolean;
  acceptedMissingUboIcon?: boolean;
  highRiskIcon?: boolean;
  moderateRiskIcon?: boolean;
  acceptedHighRiskIcon?: boolean;
  missingPorIcon?: boolean;
  approvedIcon?: boolean;
};
export const StatusIcon = ({
  text,
  questionIcon,
  missingInfoIcon,
  acceptedMissingInfoIcon,
  acceptedMissingRolesIcon,
  missingBOIcon,
  missingPetiIcon,
  missingPersonRolesIcon,
  acceptedMissingUboIcon,
  missingUboIcon,
  highRiskIcon,
  moderateRiskIcon,
  acceptedHighRiskIcon,
  missingPorIcon,
  approvedIcon,
}: StatusIconProps) => {
  return (
    <Wrapper>
      <Tooltip>
        <TooltipText>{text}</TooltipText>
        {questionIcon && <QuestionIcon />}
        {approvedIcon && <CircleCheck />}
        {highRiskIcon && <StyledHighRisk />}
        {acceptedHighRiskIcon && <StyledAcceptedHighRisk />}
        {missingPetiIcon && <StyledMissingPETI />}
        {missingBOIcon && <StyledMissingBO />}
        {missingPersonRolesIcon && <StyledMissingRole />}
        {missingUboIcon && <StyledMissingUBO />}
        {missingInfoIcon && <StyledMissingInfo />}
        {acceptedMissingInfoIcon && <StyledAcceptedMissingInfo />}
        {acceptedMissingRolesIcon && <StyledAcceptedMissingRole />}
        {acceptedMissingUboIcon && <StyledAcceptedMissingUBO />}
        {missingPorIcon && <StyledMissingPOR />}
        {moderateRiskIcon && <ModerateRisk />}
      </Tooltip>
    </Wrapper>
  );
};
