import { useEffect } from 'react';
import { useAccount } from '../providers/authenticationProvider';
import { useUser, useUserRoles } from './useUser';
import { constants } from '../utils/constants';

const hasRole = (roles, roleName) => !!(roles && roles.find((role) => role.name.startsWith(roleName)));

export const useUserInfo = (dispatch) => {
  const { isAuthenticated, user } = useAccount();
  const userInfo = useUser(user);
  const userSectionCode = userInfo ? userInfo.sectionCode : null;
  const userRoles = useUserRoles(user);
  const isUserRoleAllowed = hasRole(userRoles, 'ComplianceOfficer') || hasRole(userRoles, 'Administrator');
  const isDataAdministrator = hasRole(userRoles, 'KycDataAdmin');
  const isAdministrator = hasRole(userRoles, 'Administrator');
  const isReviewer = hasRole(userRoles, 'Reviewer');

  useEffect(() => {
    if (userSectionCode) {
      dispatch({ type: constants.SET_SECTIONS, value: [userSectionCode] });
      dispatch({ type: constants.SET_SECTIONS_FOR_QUOTED, value: [userSectionCode] });
    } else {
      dispatch({ type: constants.SET_SECTIONS, value: [] });
      dispatch({ type: constants.SET_SECTIONS_FOR_QUOTED, value: [] });
    }
  }, [userSectionCode, dispatch]);

  return { isAuthenticated, user, userSectionCode, isUserRoleAllowed, isAdministrator, isDataAdministrator, isReviewer };
};
