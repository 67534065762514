import React, { useEffect } from 'react';
import { Company } from '../company/Company';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { constants } from '../../utils/constants';
import { CompanyProvider } from '../company/CompanyContext';
import { useAppContext } from '../appRouting/AppContext';

const Wrapper = styled.div`
  margin: 32px 128px;
`;
const CompanyWrapper = styled.div`
  margin: 32px 64px;
`;
export const SingleCompanyPage = () => {
  const { dispatch, singleCompanyId } = useAppContext();
  const { companyId } = useParams();

  useEffect(() => {
    dispatch({ type: constants.SET_COMPANY_QUERY, value: null });
    dispatch({ type: constants.SET_SINGLE_COMPANY_ID, value: companyId });
  }, [dispatch, companyId]);

  return (
    <Wrapper>
      <CompanyWrapper>
        {!!singleCompanyId && (
          <CompanyProvider roleHeader='Company'>
            <Company />
          </CompanyProvider>
        )}
      </CompanyWrapper>
    </Wrapper>
  );
};
