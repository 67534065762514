import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { groupBy } from '../../utils/groupBy';
import { sortInterests } from '../../utils/sortInterests';

const InterestsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Span = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const Interest = styled.div`
  margin: 4px 4px 4px 0px;
  padding: 0 5px;
  border-radius: 4px;
  background: ${(props) =>
    props.$isFutureCover
      ? props.theme.purple35
      : props.$isActiveCover
      ? props.theme.background.primary
      : props.theme.background.red};
  color: ${(props) => props.theme.marineBlue};
  position: relative;

  span {
    color: ${(props) => props.theme.white};
    position: absolute;
    bottom: 16px;
    left: 16px;
    padding: 5px 7px;
    z-index: -1;
    font-size: 14px;
    border-radius: 4px;
    background: ${(props) => props.theme.marineBlue};
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    z-index: 999;
    width: fit-content;
    white-space: nowrap;
  }

  span:before {
    content: '';
    position: absolute;
    left: 30%;
    bottom: -14px;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: ${(props) => props.theme.marineBlue} transparent transparent transparent;
  }
  &:hover span {
    bottom: 24px;
    visibility: visible;
    opacity: 1;
    width: fit-content;
    white-space: nowrap;
  }
`;
const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  align-items: center;
`;

export const InterestCodes = ({ covers, fleetCovers, checkExistingRole, editable = false, values = null }) => {
  covers &&
    covers.length > 0 &&
    covers.sort((a, b) => sortInterests(a.interestCodeDisplayName || a.name, b.interestCodeDisplayName || b.name));

  useEffect(() => {
    if (checkExistingRole) checkExistingRole(values);
  }, [values, checkExistingRole]);

  const extendedСovers = covers.map((c) => {
    //covers we show at company header do not include isWithinInsurancePeriod and isBeforeInsurancePeriod properties
    //so we check those missing properties in fleet covers
    if (!!c.coverId) {
      c.isFutureCover = fleetCovers?.some(
        (fc) => fc.id === c.coverId && !fc.isWithinInsurancePeriod && fc.isBeforeInsurancePeriod
      );
      c.isActive = fleetCovers?.some((fc) => fc.id === c.coverId && fc.isActive);
    } else {
      c.isFutureCover = !c.isWithinInsurancePeriod && c.isBeforeInsurancePeriod;
    }
    return c;
  });

  const groupedCovers = groupBy(extendedСovers, (c) => c.name || c.interestCodeDisplayName);

  return (
    <InterestsWrapper>
      <Span>
        {!editable &&
          groupedCovers.map((item, index) => {
            const isActiveCover = item.value.some((x) => x.isActive && !x.isFutureCover);
            const isFutureCover = !isActiveCover && item.value.some((v) => v.isActive && v.isFutureCover);
            return (
              <Checkbox key={index}>
                <Interest $isActiveCover={isActiveCover} $isFutureCover={isFutureCover}>
                  {item.key} {!isActiveCover && <span>Cover is inactive</span>}
                  {isFutureCover && <span>Cover becomes active in the future</span>}
                  {isActiveCover && <span>Cover is active</span>}
                </Interest>
              </Checkbox>
            );
          })}
      </Span>
    </InterestsWrapper>
  );
};

InterestCodes.propTypes = {
  covers: PropTypes.array,
  fleetCovers: PropTypes.array,
  checkExistingRole: PropTypes.func,
  editable: PropTypes.bool,
  values: PropTypes.array,
};
