import {useState, useMemo} from 'react';

const getProperty = (obj, prop) => prop.split('.').reduce((r, val) => { return r ? r[val] : undefined; }, obj);

export const useSortableData = (items, config = null) => {

  const [sortConfig, setSortConfig] = useState(config);
  const sortedItems = useMemo(() => {
    let sortableItems = [...items];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {

        if (getProperty(a,sortConfig.key).toUpperCase() < getProperty(b,sortConfig.key).toUpperCase()) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
      }
        if (getProperty(a,sortConfig.key).toUpperCase() > getProperty(b,sortConfig.key).toUpperCase()) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
      }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};