import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SelectField } from '../shared/formFields/SelectField';
import { Label } from '../shared/formFields/Label';
import { DatePicker } from '../shared/datePicker/DatePicker';
import { Trash, ArrowForward, Approved } from '@instech/icons';

const StyledArrowForward = styled(ArrowForward)`
  color: ${(props) => props.theme.marineBlue50};
  margin-bottom: 10px;
`;
const StyledTrash = styled(Trash)`
  color: ${(props) => props.theme.lightRed};
  align-self: center;
  margin-bottom: 10px;
  transition: color 1s ease-out;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.red};
  }
`;
const StyledApproved = styled(Approved)`
  color: ${(props) => props.theme.green};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 24px;
  align-items: start;
  margin: 32px 0;
`;
const SelectRoleWrapper = styled.div`
  grid-row: 1;
`;
const InRoleSection = styled.div`
  display: flex;
  align-items: center;
  grid-row: 1;
`;
const ErrorMessage = styled.div`
  color: red;
  padding-left: 20px;
  margin-top: -36px;
`;
const SuccessMessage = styled.div`
  color: ${(props) => props.theme.green};
  display: flex;
  align-items: center;
  margin-top: -36px;
  padding-left: 20px;
`;

export const PersonRoleSection = ({
  role,
  roles,
  index = 0,
  setFieldValue,
  setFieldTouched,
  roleTypes,
  handleRemove,
  errors,
  errorMessage,
  touched,
  isDisabled,
  errorIds = [],
  submittedIds = [],
  setErrorIds,
  setSubmittedIds,
  setIsDirty,
  setIsSubmitted,
  isNewPersonFormOpen,
}) => {
  const isSuccess = submittedIds ? submittedIds.includes(index) : null;
  const isError = errorIds ? errorIds.includes(index) : null;

  const handleChangeRole = (name, value) => {
    setFieldValue(name, value);
    setSubmittedIds && setSubmittedIds([]);
    setErrorIds && setErrorIds([]);
    setIsDirty && setIsDirty(true);
    setIsSubmitted && setIsSubmitted(false);
  };
  const handleChangeDate = () => {
    setSubmittedIds && setSubmittedIds([]);
    setErrorIds && setErrorIds([]);
    setIsDirty && setIsDirty(true);
    setIsSubmitted && setIsSubmitted(false);
  };

  return (
    <div>
      <Grid>
        <SelectRoleWrapper>
          <Label label="Role" />
          <SelectField
            name={`roles[${index}].type`}
            placeholder="Not selected"
            value={role.type}
            handleChange={(name, value) => {
              setFieldTouched(name);
              handleChangeRole(name, value);
            }}
            handleBlur={setFieldTouched}
            options={roleTypes}
            displayNameSelector={(x) => x.name}
            selectedDisplayNameSelector={(x) => x.shortName}
            valueNameSelector={(x) => x.type}
            touched={touched}
            errors={errors}
            isDisabled={isDisabled}
          />
        </SelectRoleWrapper>
        <InRoleSection>
          <div>
            <Label label="In role" />
            <DatePicker
              name={`roles[${index}].activePeriod.from`}
              placeholder="Select a date from"
              value={role && role.activePeriod && role.activePeriod.from}
              touched={touched}
              errors={errors}
              handleDate={handleChangeDate}
            />
          </div>
          <StyledArrowForward />
          <div style={{ alignSelf: 'flex-end' }}>
            <Label label="" />
            <DatePicker
              name={`roles[${index}].activePeriod.to`}
              placeholder="Select a date to"
              value={role && role.activePeriod && role.activePeriod.to}
              touched={touched}
              errors={errors}
              handleDate={handleChangeDate}
            />
          </div>
        </InRoleSection>
        {(roles?.length > 1 || isNewPersonFormOpen) && (
          <StyledTrash data-test-id="remove-role" onClick={handleRemove} />
        )}
      </Grid>
      {isSuccess && (
        <SuccessMessage>
          <StyledApproved />
          <span>The role has been saved</span>
        </SuccessMessage>
      )}
      {isError && (
        <ErrorMessage>
          <div>Failed to add this role.{errorMessage}</div>
        </ErrorMessage>
      )}
    </div>
  );
};

PersonRoleSection.propTypes = {
  role: PropTypes.object,
  roles: PropTypes.array,
  index: PropTypes.number,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  roleTypes: PropTypes.array,
  handleRemove: PropTypes.func,
  errors: PropTypes.object,
  errorMessage: PropTypes.object,
  touched: PropTypes.object,
  isDisabled: PropTypes.bool,
  errorIds: PropTypes.array,
  submittedIds: PropTypes.array,
  setErrorIds: PropTypes.func,
  setSubmittedIds: PropTypes.func,
  setIsDirty: PropTypes.func,
  setIsSubmitted: PropTypes.func,
  isNewPersonFormOpen: PropTypes.bool,
};
