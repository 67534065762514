import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAccount } from '../../providers/authenticationProvider';

const AuthenticationHandler = () => {
  const { isAuthenticated, login } = useAccount();
  if (isAuthenticated !== true) {
    login();
  }

  return <Outlet />;
};

export const ProtectedRoute = () => (
  <AuthenticationHandler>
    <Outlet />
  </AuthenticationHandler>
);

export default ProtectedRoute;
