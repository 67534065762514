export const distinct = <T> (list: T[]) => distinctBy(list, x => x);

export const distinctBy = <T> (list: T[], keyGetter: (x: T) => any) => {
  const keys = new Set<any>();
  const values: T[] = [];
  list.forEach((item) => {
    const key = keyGetter(item);
    if (!keys.has(key)) {
      keys.add(key);
      values.push(item);
    }
  });
  return values;
}
