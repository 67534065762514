import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BoxButton } from '@instech/components';
import { getFriendlyDate } from '../../utils/date';
import { SelectField } from '../shared/formFields/SelectField';
import { DatePicker } from '../shared/datePicker/DatePicker';
import { Formik } from 'formik';
import { ValidationSchemaRole } from './validationSchema';
import { Load } from '@instech/icons';
import { Label } from '../shared/formFields/Label';

const StyledLoad = styled(Load)`
  height: 20px;
`;

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-gap: 16px;
  align-items: start;
  padding: 5px 20px;
  border-bottom: 1px solid ${( props ) => props.theme.border.white};
  background: ${(props) => props.theme.flatWhite};

  @media (max-width: 1700px) {
    grid-template-columns: repeat(5, minmax(100px, 1fr));
  }
`;
const Span = styled.span`
  padding: 10px 0;
  color: black;
`;
const ButtonWrapper = styled.div`
  align-self: center;
  margin-top: -8px;
`;
const WarningMessage = styled.div`
  background-color: ${(props) => props.theme.background.red};
  padding: 10px;
  color: ${(props) => props.theme.red};
  text-align: right;
`;

export const PersonTable = ({
  person,
  buttonText,
  selectPerson,
  roleTypes,
  errorMessage,
  isPersonNew,
  isSaving,
  isUbo,
}) => {
  const initialValues = {
    ...person,
    role: {
      type: isUbo ? 'UBO' : '',
      activePeriod: {
        to: null,
        from: null,
      },
    },
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialTouched={{
        role: { type: true },
      }}
      validationSchema={ValidationSchemaRole}
      validateOnMount={true}
    >
      {({ values, setFieldValue, setFieldTouched, errors, touched, dirty, isValid }) => {
        return (
          <>
            <TableWrapper>
              <Span>
                <Label label="Nationality" />
                {person.nationality?.displayName}
              </Span>
              <Span>
                <Label label="First Name" />
                {person.firstName}
              </Span>
              <Span>
                <Label label="Last Name" />
                {person.lastName}
              </Span>
              <Span>
                <Label label="Date Of Birth" />
                {!!person.dateOfBirth ? getFriendlyDate(person.dateOfBirth) : '-'}
              </Span>
              <Span>
                <Label label="PEP" />
                {person.isPoliticallyExposed
                  ? person.acceptedHighRisk ? 'YES - Accepted HR' : 'YES'
                  : person.isPoliticallyExposed === false ? 'NO' : '-'}
              </Span>
              <Span>
                <Label label="Select Role" />
                <SelectField
                  name={`role.type`}
                  placeholder="Select Role"
                  value={values.role.type}
                  handleChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  handleBlur={setFieldTouched}
                  options={roleTypes}
                  displayNameSelector={(x) => x.name}
                  selectedDisplayNameSelector={(x) => x.shortName}
                  valueNameSelector={(x) => x.type}
                  touched={touched}
                  errors={errors}
                  isDisabled={isUbo}
                />
              </Span>
              <Span>
                <Label label="In Role From" />
                <DatePicker
                  name={`role.activePeriod.from`}
                  placeholder="In role from"
                  value={values.role.activePeriod.from}
                  touched={touched}
                  errors={errors}
                />
              </Span>
              <Span>
                <Label label="In Role To" />
                <DatePicker
                  name={`role.activePeriod.to`}
                  placeholder="In role to"
                  value={values.role.activePeriod.to}
                  touched={touched}
                  errors={errors}
                />
              </Span>
              <ButtonWrapper>
                {isSaving && (isSaving.id === person.id || (isPersonNew && isSaving.id === 'new')) ? (
                  <StyledLoad />
                ) : (
                  <BoxButton
                    disabled={!isValid || (!dirty && !isUbo)}
                    onClick={() => selectPerson(values)}
                    type="button"
                    padding="4px 20px"
                    height="40px"
                  >
                    {buttonText.toUpperCase()}
                  </BoxButton>
                )}
              </ButtonWrapper>
            </TableWrapper>
            {errorMessage && (errorMessage.id === person.id || (isPersonNew && errorMessage.id === 'new')) && (
              <WarningMessage>{errorMessage.text}</WarningMessage>
            )}
          </>
        );
      }}
    </Formik>
  );
};

PersonTable.propTypes = {
  person: PropTypes.object,
  buttonText: PropTypes.string,
  selectPerson: PropTypes.func,
  roleTypes: PropTypes.array,
  errorMessage: PropTypes.object,
  isPersonNew: PropTypes.bool,
};
