import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import PropTypes, { object } from 'prop-types';
import { FleetItem } from './FleetItem';
import { Enlarge, ChevronDown } from '@instech/icons';
import { IconButton, SlimButton } from '@instech/components';
import { FilterCaseHandlers } from 'components/filters/FilterCaseHandlers';
import { Pagination } from 'components/shared/Pagination';
import { FilterUserSection } from '../filters/FilterUserSection';
import { FilterRiskLevel } from '../filters/FilterRiskLevel';
import { useAppContext } from '../appRouting/AppContext';
import { useSections } from '../../services/useSections';

const StyledEnlarge = styled(Enlarge)`
  width: 14px;
  height: 14px;
`;
const Wrapper = styled.div`
  background: white;
  color: ${(props) => props.theme.marineBlue};
`;
const Header = styled.div`
  padding: ${(props) => (props.isLastImported ? '32px 0 8px 0' : '0')};
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(60px, 0.8fr) repeat(3, minmax(60px, 0.5fr)) repeat(
      2,
      minmax(80px, 1fr)
    );
  grid-gap: 8px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  align-items: center;
`;
const HeaderItem = styled.span`
  padding-left: 10px;
`;
const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 2px solid ${(props) => props.theme.marineBlue75};
`;
const Li = styled.li`
  transition: 0.3s;
  background-color: ${(props) => (props.$isEven ? props.theme.flatWhite : 'transparent')};

  &:hover {
    background: ${(props) => props.theme.border.blueGray};
    cursor: pointer;
  }
`;
const FilterRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  padding-left: 10px;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.green};
  }
`;
const FilterWrapper = styled.div`
  position: relative;
`;
const SectionFilterWrapper = styled.div`
  position: relative;
  padding: 16px;
  span {
    line-height: 32px;
  }
`;
const NoResultMessage = styled.div`
  font-size: 36px;
  text-align: center;
  color: ${(props) => props.theme.marineBlue};
  grid-column: 1/3;
  padding: 32px 0;
`;
const PaginationWrapper = styled.div`
  padding: 16px 0;
`;

export const FleetItems = ({
  fleets,
  isQuotedFleet,
  isLastImported = false,
  mutateQuotedFleetsList,
  mutateLastImportedFleetsList,
  totalCount,
  pageSize,
  currentPage,
  setCurrentPage,
  handleSort,
  name,
  riskLevelFiltering = true,
}) => {
  const { state } = useAppContext();
  const sections = isQuotedFleet ? state.sectionsForQuoted : state.sections;
  const sectionsList = useSections();

  const sectionsLabel = useMemo(() => {
        const sectionDetails = !sections?.length
        ? ' all'
        : sections
          .map((item) => ` ${sectionsList?.find((section) => section.code === item)?.name || ''}`);

      return `Sections ${sectionDetails}`;
  }, [sections, sectionsList]);

  const caseHandlers = isQuotedFleet ? state.caseHandlerEmailsForQuoted : state.caseHandlerEmails;

  const [isCaseHandlerFiltersOpen, setIsCaseHandlerFiltersOpen] = useState(false);
  const handleOpenCaseHandlerFilters = () => {
    setIsCaseHandlerFiltersOpen(!isCaseHandlerFiltersOpen);
  };
  const [isRiskLevelFiltersOpen, setIsRiskLevelFiltersOpen] = useState(false);
  const handleOpenRiskLevelFilters = () => {
    setIsRiskLevelFiltersOpen(!isRiskLevelFiltersOpen);
  };

  const [isSectionFiltersOpen, setIsSectionFiltersOpen] = useState(false);
  const handleOpenSectionFilters = () => {
    setIsSectionFiltersOpen(!isSectionFiltersOpen);
  };
  return (
    <Wrapper>
      {!isLastImported ? (
        <>
          <SectionFilterWrapper>
            <SlimButton
              data-test-id="open-filters-section"
              ariaLabel="filter section"
              onClick={() => handleOpenSectionFilters()}
              type="button"
              variant="secondary"
              endIcon={<ChevronDown />}
            >
              {sectionsLabel}
            </SlimButton>
            {isSectionFiltersOpen && (
              <FilterUserSection
                onlySnapshots={!!isQuotedFleet}
                handleOpenSectionFilters={handleOpenSectionFilters}
                sectionsList={sectionsList}
                setCurrentPage={setCurrentPage}
              />
            )}
          </SectionFilterWrapper>
          <Header>
            <FilterRow
              data-test-id="sort-by-name"
              onClick={() => {
                handleSort('name');
              }}
            >
              <span>Fleet name</span>
              <IconButton ariaLabel="Sort" icon={<StyledEnlarge />} type="button" />
            </FilterRow>
            <HeaderItem>Status</HeaderItem>
            { !riskLevelFiltering && <HeaderItem>Risk Level</HeaderItem>}
            { riskLevelFiltering &&
            <FilterWrapper>
              <FilterRow data-test-id="sort-by-risk" onClick={handleOpenRiskLevelFilters}>
                <span>Risk Level</span>
                <IconButton ariaLabel="Sort" icon={<StyledEnlarge />} type="button" />
              </FilterRow>
              {isRiskLevelFiltersOpen && (
                <FilterRiskLevel
                  handleOpenRiskLevelFilters={handleOpenRiskLevelFilters}
                  name={name}
                  setCurrentPage={setCurrentPage}
                  handleSort={handleSort}
                />
              )}
            </FilterWrapper>
            }

            <FilterRow
              data-test-id="sort-by-date"
              onClick={() => {
                handleSort('inceptionDate');
              }}
            >
              <span>Inception Date</span>
              <IconButton ariaLabel="Sort" icon={<StyledEnlarge />} type="button" />
            </FilterRow>
            <HeaderItem>Objects in fleet</HeaderItem>
            <HeaderItem>Interests</HeaderItem>
            <FilterRow data-test-id="sort-by-case-handlers" onClick={handleOpenCaseHandlerFilters}>
              <span>INS+ Case Handler(s)</span>
              {<span>({caseHandlers?.length}) selected </span>}
              <IconButton ariaLabel="Sort" icon={<StyledEnlarge />} type="button" />
            </FilterRow>
            {isCaseHandlerFiltersOpen && (
              <FilterCaseHandlers
                onlySnapshots={!!isQuotedFleet}
                handleOpenCaseHandlerFilters={handleOpenCaseHandlerFilters}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Header>{' '}
        </>
      ) : (
        <Header isLastImported>
          <HeaderItem>Fleet name</HeaderItem>
          <HeaderItem>Status</HeaderItem>
          <HeaderItem>Risk Level</HeaderItem>
          <HeaderItem>Inception Date</HeaderItem>
          <HeaderItem>Objects in fleet</HeaderItem>
          <HeaderItem>Interests</HeaderItem>
          <HeaderItem>INS+ Case Handler(s)</HeaderItem>
        </Header>
      )}
      <Ul>
        {fleets &&
          fleets.length > 0 &&
          fleets.map((fleet, index) => (
            <Li key={index} $isEven={index % 2 === 1}>
              <FleetItem
                item={fleet}
                isQuotedFleet={isQuotedFleet}
                mutateQuotedFleetsList={mutateQuotedFleetsList}
                mutateLastImportedFleetsList={mutateLastImportedFleetsList}
              />
            </Li>
          ))}
      </Ul>
      {fleets?.length !== 0 && (
        <PaginationWrapper>
          <Pagination
            totalCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </PaginationWrapper>
      )}
      {fleets?.length === 0 && <NoResultMessage>no results found</NoResultMessage>}
    </Wrapper>
  );
};

FleetItems.propTypes = {
  fleets: PropTypes.arrayOf(object),
  isQuotedFleet: PropTypes.bool,
  isLastImported: PropTypes.bool,
  mutateQuotedFleetsList: PropTypes.func,
  mutateLastImportedFleetsList: PropTypes.func,
  totalCount: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  handleSort: PropTypes.func,
};
