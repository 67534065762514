import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PersonTable } from './PersonTable';

const Wrapper = styled.div`
  padding: 32px 0;
`;
const Title = styled.div`
  font-weight: bold;
  background: ${(props) => props.theme.whiteBlue};
  padding: 16px;
`;

export const CreatePerson = ({ selectPerson, values, roleTypes, isSaving, errorMessage, isUbo }) => {
  return (
    <Wrapper>
      <Title>Create a new person role</Title>
      <div>
        <PersonTable
          person={values}
          buttonText="Create Person"
          selectPerson={selectPerson}
          roleTypes={roleTypes}
          errorMessage={errorMessage}
          isPersonNew={true}
          isSaving={isSaving}
          isUbo={isUbo}
        />
      </div>
    </Wrapper>
  );
};

CreatePerson.propTypes = {
  selectPerson: PropTypes.func,
  values: PropTypes.object,
  roleTypes: PropTypes.array,
  isSaving: PropTypes.object,
  errorMessage: PropTypes.object,
};
