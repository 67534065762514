import React from 'react';
import styled from 'styled-components';
import PropTypes, { object } from 'prop-types';
import { VesselOwnersRow } from './VesselOwnersRow';
import { Load } from '@instech/icons';
import { Checkbox } from '../shared/formFields/CheckboxForGroup';

const StyledLoad = styled(Load)`
  height: 20px;
  align-self: center;
  margin: 32px;
`;
const Wrapper = styled.div``;
const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 1fr));
  grid-gap: 2rem;
  font-size: 0.9em;
  font-weight: bold;
  align-items: flex-start;
  padding: 10px;

  border-bottom: 1px solid ${(props) => props.theme.marineBlue};
  background: ${(props) => props.theme.flatWhite};
`;
const Span = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectCompanies = ({
  ownersFromLLI,
  checkedCompanies,
  separatedFromLLIOwners,
  handleSelectAll,
  beneficialRoleName,
  registeredRoleName,
  insuredObjects,
  existingOwners,
  setCheckedCompanies,
  setSelectedCompanies,
  roleTypes,
  isLoading,
}) => {
  return (
    <Wrapper>
      <Header>
        <Span style={{ marginLeft: '30px' }}>Object Name</Span>
        <Span>Beneficial Owner In The KYC-portal</Span>
        <Span>Registered Owner In The KYC-portal</Span>
        <span>
          Transfer Beneficial Owner From LLI
          {ownersFromLLI && (
            <CheckboxWrapper>
              <Checkbox
                name='selectAllBeneficial'
                checked={
                  checkedCompanies.length > 0 &&
                  separatedFromLLIOwners.beneficialOwners.every((b) =>
                    checkedCompanies.some(
                      (s) =>
                        s.organizationId === b.organizationId &&
                        s.roleType.roleName === beneficialRoleName &&
                        s.objectId === b.objectId
                    )
                  )
                }
                onChange={(e) => handleSelectAll(e.target, beneficialRoleName)}
                label='Select All'
              />
            </CheckboxWrapper>
          )}
        </span>
        <span>
          Transfer Registered Owner From LLI
          {ownersFromLLI && (
            <CheckboxWrapper>
              <Checkbox
                name='selectAllRegistered'
                checked={
                  checkedCompanies.length > 0 &&
                  separatedFromLLIOwners.registeredOwners.every((b) =>
                    checkedCompanies.some(
                      (s) =>
                        s.organizationId === b.organizationId &&
                        s.roleType.roleName === registeredRoleName &&
                        s.objectId === b.objectId
                    )
                  )
                }
                onChange={(e) => handleSelectAll(e.target, registeredRoleName)}
                label='Select All'
              />
            </CheckboxWrapper>
          )}
        </span>
      </Header>
      {isLoading ? (
        <StyledLoad />
      ) : (
        <VesselOwnersRow
          insuredObjects={insuredObjects}
          existingOwners={existingOwners}
          ownersFromLLI={ownersFromLLI}
          checkedCompanies={checkedCompanies}
          setCheckedCompanies={setCheckedCompanies}
          setSelectedCompanies={setSelectedCompanies}
          roleTypes={roleTypes}
        />
      )}
    </Wrapper>
  );
};

SelectCompanies.propTypes = {
  ownersFromLLI: PropTypes.arrayOf(object),
  checkedCompanies: PropTypes.array,
  separatedFromLLIOwners: PropTypes.object,
  handleSelectAll: PropTypes.func,
  beneficialRoleName: PropTypes.string,
  registeredRoleName: PropTypes.string,
  insuredObjects: PropTypes.array,
  existingOwners: PropTypes.object,
  setCheckedCompanies: PropTypes.func,
  setSelectedCompanies: PropTypes.func,
  roleTypes: PropTypes.object,
  isLoading: PropTypes.bool,
};
