import {MissingCompanyData} from "../../utils/validations/types";

export const newCompanyInitialValues = {
  id: '00000000-0000-0000-0000-000000000000',
  sourceSystem: 'Kyc',
  sourceId: '',
  lei: '',
  organizationNumber: '',
  vatNumber: '',
  fiscalNumber: '',
  name: '',
  riskLevel: 'Low',
  missingData: MissingCompanyData.None,
  hasUnhandledHighRisk: false,
  hasUnhandledMissingData: false,
  industries: [],
  type: null,
  legalAddress: {
    addressLine: '',
    state: {},
    country: {
      displayName: null,
      entityType: 'Country',
      id: null,
      partitionKey: null,
    },
  },
  roles: [],
  legalForm: {
    name: ''
  }
};
