import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SelectedOwners } from './SelectedOwners';
import { ApprovedNotification } from '../shared/ApprovedNotification';
import { WarningNotification } from '@instech/components';

const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-gap: 2rem;
  font-size: 0.9em;
  font-weight: bold;
  padding: 10px;
  align-items: flex-start;
`;
const Span = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;
const NotSelectedCompaniesWrapper = styled.div`
  padding: 16px;
`;

export const ApproveTransfer = ({ selectedCompanies, isSubmitted, groupedCompaniesWithSimilar }) => {
  const notSelectedCompanies = groupedCompaniesWithSimilar.filter(
    (g) => !selectedCompanies.some((s) => s.organizationId === g.organizationId || s.mainCompany === g.organizationId)
  );
  return (
    <>
      <Header>
        <Span style={{ marginLeft: '30px' }}>Object Name</Span>
        <Span>Transfer Beneficial Owner From LLI</Span>
        <Span>Transfer Registered Owner From LLI</Span>
      </Header>
      <SelectedOwners selectedCompanies={selectedCompanies} />
      {notSelectedCompanies.length === 0 && !isSubmitted ? (
        <WarningNotification
          size='small'
          headingText='Approve the transfer to make owner role be a part of the KYC-portal.'
        />
      ) : (
        !isSubmitted && (
          <WarningNotification
            size='small'
            headingText='One or more companies were not selected a version of to be used in KYC. The following companies will not be imported to the objects on the fleet:'
          />
        )
      )}
      {isSubmitted && (
        <ApprovedNotification text='Transferred successfully. It will take some time to update objects in the system.' />
      )}
      {notSelectedCompanies.length > 0 && !isSubmitted && (
        <NotSelectedCompaniesWrapper>
          {notSelectedCompanies.map((el, index) => {
            return (
              <span key={index}>
                {el.name.trim()}
                {(index !== notSelectedCompanies.length - 1 || !notSelectedCompanies.length === 1) && ', '}
              </span>
            );
          })}
        </NotSelectedCompaniesWrapper>
      )}
    </>
  );
};

ApproveTransfer.propTypes = {
  selectedCompanies: PropTypes.array,
  groupedCompaniesWithSimilar: PropTypes.array,
  isSubmitted: PropTypes.bool,
};
