import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getFriendlyDate, getFriendlyDateTime } from '../../utils/date';
import { getUser } from '../../services/getUser';
import { MissingInfo, HighRisk, DownArrow, MissingRole } from '@instech/icons';

const ArrowDown = styled(DownArrow)`
  justify-self: right;
  color: ${(props) => props.theme.marineBlue};
  cursor: pointer;
`;
const ArrowUp = styled(DownArrow)`
  justify-self: right;
  transform: rotate(180deg);
  color: ${(props) => props.theme.marineBlue};
  cursor: pointer;
`;
const MissingInfoAccepted = styled(MissingInfo)`
  color: transparent;
`;
const HighRiskAccepted = styled(HighRisk)`
  color: transparent;
`;
const HighRiskRejected = styled(HighRisk)`
  color: ${(props) => props.theme.status.red};
`;
const HighRiskPending = styled(HighRisk)`
  color: ${(props) => props.theme.status.red};
  rect {
    fill-opacity: 0.35;
  }
`;
const MissingRoleAccepted = styled(MissingRole)`
  color: transparent;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(80px, 1fr)) minmax(150px, 2fr) 50px;
  grid-gap: 8px;
  align-items: center;
  padding: 10px 20px;
  word-wrap: break-word;
  &:nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;
const LogDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    padding-left: 8px;
  }
`;
const Item = styled.div`
  margin: 8px 0;
`;
const Title = styled.span`
  display: inline-block;
  width: 120px;
  margin: 0 32px;
  text-transform: uppercase;
  color: ${(props) => props.theme.marineBlue50};
`;
const AdditionalInfoWrapper = styled.div`
  grid-column: span 4;
`;

export const LogDataOrganization = ({ item }) => {
  const [user, setUser] = useState({});
  const [isAdditionalInfoOpen, setAdditionalInfoOpen] = useState(false);

  const handleOpenAdditionalInfo = () => setAdditionalInfoOpen(!isAdditionalInfoOpen);

  useEffect(() => {
    item.userId &&
      getUser(item.userId).then((response) => {
        setUser(response);
      });
  }, [item.userId]);

  return (
    <Grid>
      <span>
        {item?.acceptanceType === 'MissingData' ? (
          <LogDescription>
            <MissingInfoAccepted />
            <span>Missing Info Accepted</span>
          </LogDescription>
        ) : item?.acceptanceType === 'RiskLevel' && item?.acceptanceReviewStatus === 'Pending' ? (
          <LogDescription>
            <HighRiskPending />
            <span>High Risk Pending</span>
          </LogDescription>
        ) : item?.acceptanceType === 'RiskLevel' && item?.acceptanceReviewStatus === 'Rejected' ? (
          <LogDescription>
            <HighRiskRejected />
            <span>High Risk Rejected</span>
          </LogDescription>
        ) : item?.acceptanceType === 'RiskLevel' ? (
          <LogDescription>
            <HighRiskAccepted />
            <span>High Risk Accepted</span>
          </LogDescription>
        ) : item?.acceptanceType === 'MissingPersonRoles' ? (
          <LogDescription>
            <MissingRoleAccepted />
            <span>Missing Person Role</span>
          </LogDescription>
        ) : (
          <span>Other</span>
        )}
      </span>
      <span>{getFriendlyDateTime(item.acceptedAt)}</span>
      <span>{user.name}</span>
      <span>{item.comments}</span>
      {isAdditionalInfoOpen ? (
        <ArrowUp data-test-id="open-info" onClick={handleOpenAdditionalInfo} />
      ) : (
        <ArrowDown data-test-id="close-info" onClick={handleOpenAdditionalInfo} />
      )}
      {isAdditionalInfoOpen && (
        <AdditionalInfoWrapper>
          <Item>
            <Title>Accepted From</Title>
            <span>{getFriendlyDate(item.acceptancePeriod.from)}</span>
          </Item>
          <Item>
            <Title>Accepted To</Title>
            <span>{getFriendlyDate(item.acceptancePeriod.to)}</span>
          </Item>
        </AdditionalInfoWrapper>
      )}
    </Grid>
  );
};

LogDataOrganization.propTypes = {
  item: PropTypes.object,
};
