import { useCallback } from 'react';
import { constants } from '../../utils/constants';

export const useCompanyFormHandlers = (formDispatch, mutate) => {
  const keepChangesHandler = useCallback(() => {
    formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
    formDispatch({ type: constants.SET_UPDATED_BY, value: null });
  }, [formDispatch]);

  const refreshHandler = useCallback(() => {
    formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
    formDispatch({ type: constants.SET_UPDATED_BY, value: null });
    mutate();
  }, [mutate, formDispatch]);

  const keepConflictChangesHandler = useCallback(() => {
    formDispatch({ type: constants.SET_SHOW_SAVE_CONFLICT, value: false });
  }, [formDispatch]);

  const refreshConflictHandler = useCallback(() => {
    formDispatch({ type: constants.SET_SHOW_SAVE_CONFLICT, value: false });
    mutate();
  }, [mutate, formDispatch]);

  return [keepChangesHandler, refreshHandler, keepConflictChangesHandler, refreshConflictHandler];
};
