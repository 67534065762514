import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Checkbox } from '../shared/formFields/CheckboxForGroup';

const CountrySpan = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.marineBlue50};
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const VesselOwners = ({ owners, roleType, existingOwners, hasCheckBox, handleCheck, checkedCompanies }) => {
  const roleTypeOwners = roleType === 'beneficial' ? owners?.beneficialOwners : owners?.registeredOwners;
  const roleTypeExistingOwners =
    roleType === 'beneficial' ? existingOwners?.beneficialOwners : existingOwners?.registeredOwners;
  return (
    <div>
      {roleTypeOwners && roleTypeOwners.length > 0
        ? roleTypeOwners.map((item, index) => {
            const isAlreadyInPortalOwner =
              roleTypeExistingOwners &&
              roleTypeExistingOwners.some((owner) => owner.organizationId === item.organizationId);
            return (
              <Wrapper key={index}>
                {hasCheckBox && (
                  <Checkbox
                    name='chosenCompaniesIds'
                    checked={checkedCompanies.some(
                      (c) =>
                        c.objectId === item.objectId &&
                        c.organizationId === item.organizationId &&
                        c.roleType.id === item.roleType.id
                    )}
                    onChange={(e) => {
                      handleCheck(e.target, item, owners, existingOwners);
                    }}
                    value={item.organizationId}
                    disabled={isAlreadyInPortalOwner}
                    infoText='The role is already a part of the KYC-portal'
                  />
                )}
                <div>
                  <span>{item.kycOrganization ? item.kycOrganization.displayName : item.name}</span>
                  <CountrySpan>{item.kycOrganization ? item.kycOrganization.displayAddress : item.country}</CountrySpan>
                </div>
              </Wrapper>
            );
          })
        : '-'}
    </div>
  );
};

VesselOwners.propTypes = {
  owners: PropTypes.object,
  roleType: PropTypes.string,
  existingOwners: PropTypes.object,
  hasCheckBox: PropTypes.bool,
  handleCheck: PropTypes.func,
  checkedCompanies: PropTypes.array,
};
