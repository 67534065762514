import { postJsonAsync } from './fetchConfig';


export type User = {
  name: string;
  userId: string;
  section: string;
};

export const getUser = (email: string) => postJsonAsync<User>(`users/by-email`, {email});
