import React from 'react';
import styled from 'styled-components';
import { preBindTabs } from '../fleetsList/fleetTabs';
import { usePreBindFleets } from '../projectTabs/usePreBindFleets';
import { FleetItems } from '../fleetsList/FleetItems';
import { BackgroundTaskProgressBar } from '../shared/BackgroundTaskProgressBar';
import { CreateFleetEntry } from './CreateFleetEntry';
import { Tab } from '../shared/Tab';
import { useAppContext } from '../appRouting/AppContext';
import { constants } from 'utils/constants';

const ProgressBarWrapper = styled.div`
  margin: 16px 0;
  color: ${ (props) => props.theme.marineBlue };
`;
const TabsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100px;
`;
const TotalCountWrapper = styled.div`
  font-size: 32px;
`;

export const PreBindTabs = ({ openImportFleet, closeImportFleet }) => {
  const {
    lastImportedFleets,
    quotedFleets,
    mutateQuotedFleetsList,
    mutateLastImportedFleetsList,
    quotedFleetValidationTasks,
    mutateQuotedFleetValidationTasks,
    quotedFleetsTotalCount,
    fleetsLimit,
    handleSort
  } = usePreBindFleets();

  const { state, dispatch } = useAppContext();
  const quotedFleetsCurrentPage = state.currentPage.forQuoted;
  const setQuotedFleetsCurrentPage = (value) => dispatch({
    type: constants.SET_CURRENT_PAGE,
    value: value,
    name: 'forQuoted'
  });
  const activeTab = state.activeTabForQuoted;
  const changeTab = tab => dispatch({ type: constants.SET_ACTIVE_TAB_FOR_QUOTED, value: tab });

  const lastImportedFleetsTotalCount = lastImportedFleets?.length < 8 ? lastImportedFleets?.length : 8;

  return (
    <>
      <ProgressBarWrapper>
        {quotedFleetValidationTasks &&
          quotedFleetValidationTasks.length > 0 &&
          quotedFleetValidationTasks.map((task) => {
            return (
              <BackgroundTaskProgressBar
                key={task.taskId}
                eventStreamTopic={task}
                eventName='Fleet'
                titleText={`Importing ${ task.data.fleetName } ( ${ task.data.insuranceYear })`}
                mutate={mutateQuotedFleetValidationTasks}
                mutateQuotedFleetsList={mutateQuotedFleetsList}
                mutateLastImportedFleetsList={mutateLastImportedFleetsList}
                forQuotedFleet
              />
            );
          })}
      </ProgressBarWrapper>
      <TabsWrapper>
        {preBindTabs.map((t) => (
          <Tab
            key={t.key}
            onClick={() => changeTab(t.key)}
            $selected={activeTab === t.key}
            $highlight={t.highlight}
          >
            <TotalCountWrapper>
              {t.key === 'ImportedFleets' && quotedFleetsTotalCount}
              {t.key === 'LastImportedFleets' && lastImportedFleetsTotalCount}
            </TotalCountWrapper>
            {t.description}
          </Tab>
        ))}
      </TabsWrapper>
      {openImportFleet && (
        <CreateFleetEntry
          onClose={closeImportFleet}
          mutateQuotedFleetValidationTasks={mutateQuotedFleetValidationTasks}
        />
      )}

      {activeTab === 'LastImportedFleets' && (
        <FleetItems
          fleets={lastImportedFleets}
          isQuotedFleet
          isLastImported={true}
          mutateQuotedFleetsList={mutateQuotedFleetsList}
          mutateLastImportedFleetsList={mutateLastImportedFleetsList}
          currentPage={quotedFleetsCurrentPage}
          setCurrentPage={setQuotedFleetsCurrentPage}
          totalCount={8}
          pageSize={8}
          handleSort={handleSort}
        />
      )}

      {activeTab === 'ImportedFleets' && (
        <FleetItems
          fleets={quotedFleets}
          isQuotedFleet
          mutateQuotedFleetsList={mutateQuotedFleetsList}
          mutateLastImportedFleetsList={mutateLastImportedFleetsList}
          currentPage={quotedFleetsCurrentPage}
          setCurrentPage={setQuotedFleetsCurrentPage}
          totalCount={quotedFleetsTotalCount}
          pageSize={fleetsLimit}
          handleSort={handleSort}
          name={'forQuoted'}
        />
      )}
    </>
  );
};
