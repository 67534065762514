import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../shared/formFields/Checkbox';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  color: ${(props) => props.theme.marineBlue};
  background-color: ${(props) => props.theme.white};
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
`;
const Item = styled.div`
  padding: 8px;
`;

export const InterestMenu = ({ handleChange, node, checked }) => {
  const text = 'HIGH RISK';
  return (
    <Wrapper>
      <Item ref={node}>
        <Checkbox label={text} handleChange={handleChange} checked={checked} />
      </Item>
    </Wrapper>
  );
};

InterestMenu.propTypes = {
  node: PropTypes.object,
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
};
