import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import useSWR, { Key } from 'swr';

export const fetchConfig = axios.create({
  baseURL: '/api/',
  timeout: 60000,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
});

const USER_ROLE = 'ICS_USER_ROLE';

// add domains or remove this
const skippedDomains = [] as string[];

/**
 * Helper func for fetchConfig.interceptors to run down a list of domains that
 * should not have additional headers (auth bearer token, user role, etc.)
 * attached to it.
 */
const isSkippedDomain = (url?: string) => {
    const res = skippedDomains.filter(domain => url?.includes(domain));
    return !!res.length;
};

fetchConfig.interceptors.request.use(
  // Get the access token with userManager method
    config => {
        if (config.headers && !isSkippedDomain(config.url)) {
            const userRole = sessionStorage.getItem(USER_ROLE);
            if (userRole) {
                config.headers.set('x-ics-user-role', userRole);
            }
            config.headers.set('X-CSRF', '1');
        }
        return config;
    },
  (error) => Promise.reject(error)
);

fetchConfig.interceptors.response.use(
  (response) => {
    if (response.data && response.data instanceof Object) {
      response.data._etag = response.headers['etag'];
    }

    return response;
  },
  (error) => Promise.reject(error)
);

export const GetApiDataWithCondition = <TResponse = unknown>(url: Key, shouldFetch: boolean) => useSWR<TResponse>(shouldFetch ? url : null).data;

export const getJsonAsync = <TResponse = unknown>(endpoint: string, params?: AxiosRequestConfig) => fetchConfig.get<TResponse>(endpoint, params).then((response) => response.data);

export const GetApiData = <TResponse>(url: Key) => useSWR<TResponse>(url).data;

export const postJsonAsync = <TResponse = unknown, TPayload = unknown>(endpoint: string, payload: TPayload, config = {}) =>
  fetchConfig.post<TResponse>(endpoint, payload, config).then((response) => response.data);

export const putJsonAsync = <TPayload = unknown>(endpoint: string, payload: TPayload, config = {}) =>
  fetchConfig.put(endpoint, payload, config).then((response) => response.data);

export const deleteJsonAsync = (endpoint: string, params?: AxiosRequestConfig) =>
  fetchConfig.delete(endpoint, params).then((response) => response.data);
