import React from 'react';
import styled from 'styled-components';
import { FleetDetails } from './FleetDetails';
import { FleetOverviewContextProvider } from './FleetOverviewContext';

const Wrapper = styled.div`
  margin: 32px 64px;
`;

export const FleetOverviewPage = () => {
  return (
    <Wrapper>
      <FleetOverviewContextProvider>
        <FleetDetails />
      </FleetOverviewContextProvider>
    </Wrapper>
  );
};
