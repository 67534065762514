import React, { useState } from 'react';
import styled from 'styled-components';
import { SearchFleet } from './SearchFleet';
import { PreBindTabs } from '../quotedFleets/PreBindTabs';
import { BoxButton } from '@instech/components';
import { Add } from '@instech/icons';

const Wrapper = styled.div`
  margin: 32px 64px;
  h1 {
    color: ${(props) => props.theme.marineBlue};
    font-size: 24px;
    font-weight: bold;
  }
`;
const SearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  align-items: center;
  align-content: flex-end;
  margin: 32px 0 16px 0;
  @media (max-width: 950px) {
    grid-template-columns: auto;
  }
`;
const ButtonWrapper = styled.div`
  button {
    border: none;
    box-shadow: none;
    background: none;
  }
`;

export const PreBindFleetsPage = () => {
  const [openImportFleet, setOpenImportFleet] = useState(false);

  const closeImportFleet = () => {
    setOpenImportFleet(false);
  };

  return (
    <Wrapper>
      <SearchWrapper>
        <h1>PRE-BIND FLEETS</h1>
        <SearchFleet isQuoted={true} />
      </SearchWrapper>
      <ButtonWrapper>
        <BoxButton
          data-test-id="add-fleet"
          aria-label="Import Fleet"
          onClick={() => setOpenImportFleet(true)}
          title=""
          startIcon={<Add />}
          type="button"
          padding="0"
          inverted
        >
          IMPORT FLEET
        </BoxButton>
      </ButtonWrapper>
      <PreBindTabs openImportFleet={openImportFleet} closeImportFleet={closeImportFleet} />
    </Wrapper>
  );
};
