import React, { ComponentType, MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Load, Question } from '@instech/icons';

const StyledLoad = styled(Load)`
  &&& {
    height: 10px;
    margin: 4px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: end;
`;
const IconWrapper = styled.span<{ type?: 'default' | 'warning' }>`
  transition: all 0.3s;
  background: ${({ type, theme }) => (type == 'warning' ? theme.lightYellow : theme.ultraLightGray)};
  height: 32px;
  border-radius: 4px;
  font-weight: normal;
  display: flex;
  align-items: center;
  &:hover {
    background: ${({ type, theme }) => (type == 'warning' ? theme.yellow : theme.lightGray)};
  }
  > * {
    height: 18px;
    margin: 4px;
  }
`;

const Tooltip = styled.div<{ type?: 'default' | 'warning' }>`
  position: relative;
  cursor: pointer;
  padding: 4px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  &:hover span {
    top: -80%;
    visibility: visible;
    opacity: 1;
  }
`;
const TooltipText = styled.span`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background: ${({ theme }) => theme.marineBlue};
  color: ${({ theme }) => theme.white};
  white-space: nowrap;
  padding: 4px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease, top 0.3s ease;
  pointer-events: none;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: ${(props) => props.theme.marineBlue} transparent transparent transparent;
  }
`;

export type TooltipAction<T = unknown> = {
  text: string;
  payload?: T;
  handler: (payload: T) => Promise<unknown>;
  hidden?: boolean;
  buttonTestId?: string;
  type?: 'default' | 'warning';
  icon: ComponentType<{ onClick: MouseEventHandler }>;
};

export const TooltipButton = ({ handler, text, payload, buttonTestId, type, icon: Icon = Question }: TooltipAction) => {
  const mounted = useRef(true);

  const [isHandling, setIsHandling] = useState(false);

  const handleAction = useCallback<MouseEventHandler>(
    async (e) => {
      e.stopPropagation();
      if (isHandling) return;
      setIsHandling(true);
      try {
        await handler(payload);
      } catch (e) {
        console.error(e);
      }
      if (mounted.current) {
        setIsHandling(false);
      }
    },
    [handler, payload]
  );

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Tooltip onClick={handleAction}>
      <TooltipText>{text}</TooltipText>
      <IconWrapper type={type}>
        {isHandling ? <StyledLoad /> : <Icon data-test-id={buttonTestId} onClick={handleAction} />}
      </IconWrapper>
    </Tooltip>
  );
};

type TooltipButtonsProps = {
  actions?: TooltipAction[];
  children?: ReactNode;
};

export const TooltipButtons = ({ actions, children }: TooltipButtonsProps) => {
  return (
    <Wrapper>
      {actions
        ?.filter((action) => !action.hidden)
        .map((action, i) => (
          <TooltipButton key={i} {...action} />
        ))}
      {children}
    </Wrapper>
  );
};
