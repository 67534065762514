import styled, { css } from 'styled-components';

export const StyleOverrides = styled.div`
  .SingleDatePicker,
  .DateRangePicker {
    width: 100%;
  }
  .SingleDatePickerInput,
  .DateRangePickerInput,
  .DateRangePickerInput__withBorder {
    display: flex;
    background: none;
    border: none;
  }
  .DateRangePickerInput_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    flex-shrink: 0;
  }
  .SingleDatePickerInput .DateInput,
  .DateRangePickerInput .DateInput {
    width: 100%;
    min-width: 140px; /* Should be big enough to fit DD. Mon YYYY */
    height: 38px;
    border-radius: 2px;
    border: 1px solid ${(props) => (props.noBorder ? 'transparent' : props.theme.marineBlue50)};
    transition: 0.4s;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid ${(props) => (props.disabled ? props.theme.border.gray : props.theme.marineBlue)};
    }

    .DateInput_input::placeholder {
      color: ${(props) => props.theme.border.gray};
      margin-top: 1px;
    }
  }
  .SingleDatePickerInput .DateInput {
    ${(props) =>
      props.$error &&
      css`
        border-color: red;
        color: red;
        .DateInput_input::placeholder {
          color: ${props.theme.red};
          opacity: ${props.theme.opacity.placeholder};
        }
      `};
  }
  .DateRangePickerInput .DateInput:first-of-type {
    ${(props) =>
      props.$error &&
      props.$error.startDate &&
      css`
        border-color: red;
        color: red;
        .DateInput_input::placeholder {
          color: ${props.theme.red};
          opacity: ${props.theme.opacity.placeholder};
        }
      `};
  }
  .DateRangePickerInput .DateInput:last-of-type {
    ${(props) =>
      props.$error &&
      props.$error.endDate &&
      css`
        border-color: red;
        color: red;
        .DateInput_input::placeholder {
          color: ${props.theme.red};
          opacity: ${props.theme.opacity.placeholder};
        }
      `};
  }
  .DateInput_input {
    width: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: #333333;
    padding: 0px 8px;
    height: ${(props) => (props.forSearchBox ? '46px' : '38px')};
    background: none;
    box-sizing: border-box;
  }
  .DateInput_input__focused {
    border-bottom: 2px solid transparent;
  }
  .CalendarDay__selected {
    background: ${(props) => props.theme.marineBlue};
    color: white;
    border: 1px solid #e4e7e7;
  }
  .CalendarDay__selected_span {
    background: ${(props) => props.theme.marineBlue50};
    color: white;
    border: 1px solid #e4e7e7;
  }
  .CalendarDay__selected:hover,
  .CalendarDay__selected_span&:hover {
    background: ${(props) => props.theme.marineBlue75};
    color: white;
    border: 1px solid #e4e7e7;
  }
`;
