import React from 'react';
import styled from 'styled-components';
import { CompanyHeader } from '../company/CompanyHeader';
import { CompanyData } from '../company/CompanyData';
import { useCompanyContext } from './CompanyContext';

const Wrapper = styled.div`
  width: 100%;
  border-radius: 2px;
  margin-bottom: 16px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
`;
const DataWrapper = styled.div`
  background: ${ (props) => props.theme.white };
  box-shadow: ${ (props) => props.theme.boxShadow.boxShadowMain };
  padding: 14px;
`;

export const Company = () => {
  const { isClientOpen } = useCompanyContext();
  return (
    <Wrapper>
      <CompanyHeader />
      {isClientOpen && <DataWrapper><CompanyData /></DataWrapper>}
    </Wrapper>
  );
};
