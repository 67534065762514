import { baseTheme } from '@instech/components';

export type TabKey =
  | 'HighRisk'
  | 'MissingInfo'
  | 'Overdue'
  | 'FollowUp'
  | 'NoMissingInfo'
  | 'LastImportedFleets'
  | 'ImportedFleets';

export interface TabProps {
  key: TabKey;
  status: string;
  highlight?: string;
  description: string;
  noDataMessage: string;
}

export const preBindTabs: TabProps[] = [
  {
    key: 'ImportedFleets',
    status: 'ImportedFleets',
    highlight: baseTheme.status.green,
    description: 'Imported Fleets',
    noDataMessage: 'No data',
  },
  {
    key: 'LastImportedFleets',
    status: 'LastImportedFleets',
    highlight: baseTheme.status.blue,
    description: 'Last Imported Fleets',
    noDataMessage: 'No data',
  },
];
export const postBindTabs: TabProps[] = [
  {
    key: 'HighRisk',
    status: 'High Risk',
    highlight: baseTheme.red,
    description: 'High Risk',
    noDataMessage: 'No data',
  },
  {
    key: 'Overdue',
    status: 'Overdue',
    highlight: baseTheme.status.red,
    description: 'Overdue (>= 30 days)',
    noDataMessage: 'No data',
  },
  {
    key: 'FollowUp',
    status: 'Follow Up',
    highlight: baseTheme.notification.dangerBox,
    description: 'Follow Up (29-15 days)',
    noDataMessage: 'No data',
  },
  {
    key: 'MissingInfo',
    status: 'Missing Information',
    highlight: baseTheme.status.yellow,
    description: 'Missing Information',
    noDataMessage: 'No data',
  },
  {
    key: 'NoMissingInfo',
    status: 'No Missing Information',
    highlight: baseTheme.status.green,
    description: 'No Missing Information',
    noDataMessage: 'No data',
  },
];
