import React from 'react';
import styled from 'styled-components';
import { SlimButton } from '@instech/components';
import { Formik, Form } from 'formik';
import { SelectField } from '../shared/formFields/SelectField';
import { DatePicker } from '../shared/datePicker/DatePicker';
import { InputField } from '../shared/formFields/InputField';
import { Label } from '../shared/formFields/Label';
import { usePersonsPageContext } from './PersonsPageContext';

const SearchForm = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-row-gap: 16px;
  grid-column-gap: 4px;
  align-items: start;
  margin: 32px auto;
  max-width: 1000px;
`;
const DOBWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
`;
const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

export const SearchPerson = () => {
  const { initialValues, handleSearch, nationalities } = usePersonsPageContext();

  return (
    <div>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={(values) => handleSearch(values)}>
        {({ values, setFieldValue, setFieldTouched, handleChange, handleBlur, errors, touched }) => {
          return (
            <Form>
              <SearchForm>
                <div>
                  <Label label="Nationality" />
                  <SelectField
                    noBorder
                    name="nationalityId"
                    placeholder="Not selected"
                    options={nationalities}
                    displayNameSelector={(x) => `${x.name}  (${x.twoLetterCountryCode})`}
                    valueNameSelector={(x) => x.id}
                    handleChange={(name, value) => setFieldValue(name, value)}
                    handleBlur={setFieldTouched}
                    value={values.nationalityId || null}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div>
                  <Label label="First Name" />
                  <InputField
                    forPersonSearch
                    placeholder="Search by First Name"
                    name="firstName"
                    value={values.firstName}
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </div>
                <div>
                  <Label label="Last Name" />
                  <InputField
                    forPersonSearch
                    placeholder="Search by Last Name"
                    name="lastName"
                    value={values.lastName}
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </div>
                <DOBWrapper>
                  <div>
                    <Label label="Date of Birth" />
                    <DatePicker noBorder name="dateOfBirth" placeholder="Search by DoB" value={values.dateOfBirth} />
                  </div>
                  <ButtonWrapper>
                    <SlimButton aria-label="Search person" padding="0 16px" type="submit">
                      SEARCH
                    </SlimButton>
                  </ButtonWrapper>
                </DOBWrapper>
              </SearchForm>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
