import React, { useState, useCallback } from 'react';
import { BoxButton } from '@instech/components';
import { ConfirmModalDialog } from '../shared/ConfirmModalDialog';
import { AcceptModalDialog } from '../shared/AcceptModalDialog';
import { useCompanyContext } from './CompanyContext';
import { postAcceptMissingInfo } from '../../services/postAcceptData';
import { useAppContext } from '../appRouting/AppContext';
import { constants } from 'utils/constants';

export const AcceptMissingRole = () => {
    const {
        organization,
        reference,
        clientRoleType,
        mutate,
        mutateFleetDetails,
        mutateVesselList,
    } = useCompanyContext();
    const { dispatch } = useAppContext();
    const setIsConfirmDialogVisible = (value) => {
        dispatch({ type: constants.SET_IS_CONFIRM_DIALOG_VISIBLE, value });
    }
    const [showAcceptMissingInfoError, setShowAcceptMissingInfoError] = useState(false);
    const [isModalDialogVisible, setIsModalDialogVisible] = useState(false);

    const acceptMissingRoleHandler = useCallback(
        (values) => {
            const { acceptanceDateFrom, acceptanceDateTo, additionalComments } = values;
            const command = {
                subject: {
                    id: organization.id,
                    partitionKey: organization.id,
                    entityType: 'Organization',
                },
                acceptancePeriod: {
                    from: acceptanceDateFrom,
                    to: acceptanceDateTo,
                },
                acceptanceType: 'MissingPersonRoles',
                comments: additionalComments,
            };
            postAcceptMissingInfo(organization.id, command, organization._etag)
                .then(() => {
                    mutateFleetDetails && mutateFleetDetails();
                    mutate();
                    mutateVesselList && mutateVesselList();
                })
                .catch((error) => {
                    console.error(error);
                    setIsModalDialogVisible(false);
                    setShowAcceptMissingInfoError(error);
                });
            setIsModalDialogVisible(false);
            setIsConfirmDialogVisible(true);
        },
        [organization, mutate, mutateFleetDetails, mutateVesselList]
    );

    const handleShowModalDialog = () => {
        setIsModalDialogVisible(!isModalDialogVisible);
    }

    return (
        <>
            <BoxButton
                aria-label='Accept missing role'
                onClick={handleShowModalDialog}
                type='button'
                className='acceptMissingInfoButton'
            >
                ACCEPT MISSING ROLE(s)
            </BoxButton>

            {showAcceptMissingInfoError && (
                <ConfirmModalDialog
                    text='An error occurred when trying to accept missing role(s).'
                    onClick={() => setShowAcceptMissingInfoError(false)}
                    onCancel={() => setShowAcceptMissingInfoError(false)}
                />
            )}
            {isModalDialogVisible && (
                <AcceptModalDialog
                    isModalDialogVisible={isModalDialogVisible}
                    onClick={handleShowModalDialog}
                    isMissingInfo='true'
                    forType={clientRoleType ?? 'Client'}
                    forDescription={reference.displayName}
                    text='Fill in to accept missing role(s)'
                    labelText='Reason for accepting missing role(s)'
                    cancelButtonText='Cancel'
                    acceptButtonText='Accept missing role(s)'
                    onCancel={handleShowModalDialog}
                    onAccept={acceptMissingRoleHandler}
                />
            )}
        </>
    );
};
