import React from 'react';
import styled from 'styled-components';
import { useCompaniesList } from '../../services/useCompaniesList';
import { CompanyItems } from './CompanyItems';
import { DefaultErrorMessage } from '../error/DefaultErrorMessage';
import { Load } from '@instech/icons';
import { useCompaniesPageContext } from '../projectTabs/CompaniesPageContext';

const StyledLoad = styled(Load)`
  height: 20px;
  margin: 32px auto;
`;

export const CompaniesList = () => {
  const { companyQuery, dispatch, offset } = useCompaniesPageContext();
  const limit = 20;
  const isEmpty = companyQuery && Object.values(companyQuery).every((x) => x === null || x === '');
  const shouldFetch = companyQuery && !isEmpty;
  const { loading, error, companies, hasMore, mutate } = useCompaniesList(shouldFetch, companyQuery, offset, limit);
  if (error) return <DefaultErrorMessage />;
  return (
    <div>
      {loading && shouldFetch && <StyledLoad />}
      <CompanyItems
        companies={companies}
        mutate={mutate}
        hasMore={hasMore}
        loading={loading}
        dispatch={dispatch}
        limit={limit}
        shouldFetch={shouldFetch}
        offset={offset}
      />
    </div>
  );
};
