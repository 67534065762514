import React, { useState, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteQuotedFleetEntry } from '../../services/forQuotedFleets';

const ActionsForQuotedFleetsContext = createContext({});

export const ActionsForQuotedFleetsContextProvider = ({
  children,
  fleetId,
  fleetName,
  insuranceYear,
  setShowConfirmation,
  setOpenQuotedFleetMenu,
  mutateQuotedFleetsList,
  mutateLastImportedFleetsList,
}) => {
  const [isRemoveDialogVisible, setIsRemoveDialogVisible] = useState(false);
  const [isAcceptDialogVisible, setIsAcceptDialogVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  let navigate = useNavigate();

  const removeQuotedFleetEntry = () => {
    setIsSubmitting(true);
    setIsError(false);
    isRemoveDialogVisible && setIsRemoveDialogVisible(true);
    isAcceptDialogVisible && setIsAcceptDialogVisible(true);
    deleteQuotedFleetEntry(fleetId)
      .then(() => {
        setIsSubmitting(false);
        setIsSubmitted(true);
        setShowConfirmation && setShowConfirmation(true);
        setOpenQuotedFleetMenu && setOpenQuotedFleetMenu(false);
        mutateQuotedFleetsList && mutateQuotedFleetsList();
        mutateLastImportedFleetsList && mutateLastImportedFleetsList();
        setTimeout(() => {
          mutateQuotedFleetsList && mutateQuotedFleetsList();
          mutateLastImportedFleetsList && mutateLastImportedFleetsList();
        }, 10000);
        navigate('/preBindFleets', { replace: true });
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        setIsError(true);
        setIsSubmitted(false);
      });
  };

  const value = {
    isRemoveDialogVisible,
    setIsRemoveDialogVisible,
    isAcceptDialogVisible,
    setIsAcceptDialogVisible,
    isSubmitting,
    isSubmitted,
    setIsSubmitted,
    isError,
    removeQuotedFleetEntry,
    fleetName,
    insuranceYear,
  };
  return <ActionsForQuotedFleetsContext.Provider value={value}>{children}</ActionsForQuotedFleetsContext.Provider>;
};

export const useActionsForQuotedFleetsContext = () => useContext(ActionsForQuotedFleetsContext);
