import { putJsonAsync } from "./fetchConfig";
import { AcceptanceReviewStatus } from "../utils/AcceptanceType";

type ProposeHighRiskAcceptanceCommand = {
    eTag: string,
    data: {
        comments: string,
        acceptancePeriod: {
            from: string,
            to: string
        },
        reviewerSubjectIds: string[],
        fleetId: string
    }
}
export type ProposePersonHighRiskAcceptanceCommand = ProposeHighRiskAcceptanceCommand & {
    personId: string
}
export type ProposeOrganizationHighRiskAcceptanceCommand = ProposeHighRiskAcceptanceCommand & {
    organizationId: string
}
export type ProposeManualHighRiskAcceptanceCommand = ProposeHighRiskAcceptanceCommand & {
    fleetId: string
}

type ReviewHighRiskAcceptanceCommand = {
    eTag: string,
    data: {
        reviewComments: string,
        acceptanceReviewStatus: AcceptanceReviewStatus
    }
}
export type ReviewPersonHighRiskAcceptanceCommand = ReviewHighRiskAcceptanceCommand & {
    personId: string
}
export type ReviewOrganizationHighRiskAcceptanceCommand = ReviewHighRiskAcceptanceCommand & {
    organizationId: string
}
export type ReviewManualHighRiskAcceptanceCommand = ReviewHighRiskAcceptanceCommand & {
    fleetId: string
}

export const proposeOrganizationHighRiskAcceptance = (command: ProposeOrganizationHighRiskAcceptanceCommand) => {
    const url = `acceptanceReviews/organizations/${command.organizationId}/propose`;
    return putJsonAsync(url, command.data, { headers: { 'If-Match': command.eTag } })
}
export const reviewOrganizationHighRiskAcceptance = (command: ReviewOrganizationHighRiskAcceptanceCommand) => {
    const url = `acceptanceReviews/organizations/${command.organizationId}/review`;
    return putJsonAsync(url, command.data, { headers: { 'If-Match': command.eTag } })
}
export const proposePersonHighRiskAcceptance = (command: ProposePersonHighRiskAcceptanceCommand) => {
    const url = `acceptanceReviews/persons/${command.personId}/propose`;
    return putJsonAsync(url, command.data, { headers: { 'If-Match': command.eTag } })
}
export const reviewPersonHighRiskAcceptance = (command: ReviewPersonHighRiskAcceptanceCommand) => {
    const url = `acceptanceReviews/persons/${command.personId}/review`;
    return putJsonAsync(url, command.data, { headers: { 'If-Match': command.eTag } })
}
export const proposeManualHighRiskAcceptance = (command: ProposeManualHighRiskAcceptanceCommand) => {
    const url = `acceptanceReviews/manual-high-risks/${command.fleetId}/propose`;
    return putJsonAsync(url, command.data, { headers: { 'If-Match': command.eTag } })
}
export const reviewManualHighRiskAcceptance = (command: ReviewManualHighRiskAcceptanceCommand) => {
    const url = `acceptanceReviews/manual-high-risks/${command.fleetId}/review`;
    return putJsonAsync(url, command.data, { headers: { 'If-Match': command.eTag } })
}
