import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Enlarge } from '@instech/icons';
import { IconButton } from '@instech/components';

const SortIcon = styled(Enlarge)`
  height: 15px;
  margin-top: 3px;
`;
const Header = styled.div`
  color: ${(props) => props.theme.marineBlue};
  border-bottom: 1px solid ${(props) => props.theme.marineBlue50};
  border-radius: 2px;
`;
const Grid = styled.div`
  display: grid;
  width: 92%;
  grid-template-columns:
    minmax(15rem, 30rem) minmax(8rem, 12rem) repeat(3, minmax(3rem, 10rem)) minmax(5rem, 10rem)
    minmax(15rem, 30rem);
  grid-gap: 2rem;
  font-size: 0.9em;
  font-weight: bold;
  padding: 10px;
`;
const Span = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const VesselsListHeader = ({
  handleSort,
  setObjectIds = null,
  objectIds = null,
  setInterestCodesByObjectId = null,
  interestCodesByObjectId = null,
}) => (
  <Header>
    <Grid>
      <Span>
        Object name
        <IconButton
          data-test-id="sort-by-name"
          ariaLabel="Sort by name"
          icon={<SortIcon />}
          onClick={() => {
            handleSort('name');
            setObjectIds && setObjectIds(objectIds);
            setInterestCodesByObjectId && setInterestCodesByObjectId(interestCodesByObjectId);
          }}
          type="button"
        />
      </Span>
      <Span>Status</Span>
      <Span>
        IMO
        <IconButton
          data-test-id="sort-by-imo"
          ariaLabel="Sort by IMO"
          icon={<SortIcon />}
          onClick={() => {
            handleSort('imo');
            setObjectIds && setObjectIds(objectIds);
            setInterestCodesByObjectId && setInterestCodesByObjectId(interestCodesByObjectId);
          }}
          type="button"
        />
      </Span>
      <Span>
        Flag
        <IconButton
          data-test-id="sort-by-flag"
          ariaLabel="Sort by flag"
          icon={<SortIcon />}
          onClick={() => {
            handleSort('flag');
            setObjectIds && setObjectIds(objectIds);
            setInterestCodesByObjectId && setInterestCodesByObjectId(interestCodesByObjectId);
          }}
          type="button"
        />
      </Span>
      <Span>
        Type
        <IconButton
          data-test-id="sort-by-type"
          ariaLabel="Sort by type"
          icon={<SortIcon />}
          onClick={() => {
            handleSort('type');
            setObjectIds && setObjectIds(objectIds);
            setInterestCodesByObjectId && setInterestCodesByObjectId(interestCodesByObjectId);
          }}
          type="button"
        />
      </Span>
      <Span>
        Risk Level
        <IconButton
          data-test-id="sort-by-risk"
          ariaLabel="Sort by risk level"
          icon={<SortIcon />}
          onClick={() => {
            handleSort('validations.riskLevel');
            setObjectIds && setObjectIds(objectIds);
            setInterestCodesByObjectId && setInterestCodesByObjectId(interestCodesByObjectId);
          }}
          type="button"
        />
      </Span>
      <Span>Interest Code</Span>
    </Grid>
  </Header>
);

VesselsListHeader.propTypes = {
  handleSort: PropTypes.func,
};
