import React from 'react';
import styled from 'styled-components';
import { SearchPerson } from './SearchPerson';
import { PersonsList } from '../personsList/PersonsList';
import { PersonsPageContextProvider } from '../projectTabs/PersonsPageContext';

const Wrapper = styled.div`
  margin: 32px 128px;
`;

export const PersonsPage = () => {
  return (
    <Wrapper>
      <PersonsPageContextProvider>
        <SearchPerson />
        <PersonsList />
      </PersonsPageContextProvider>
    </Wrapper>
  );
};
