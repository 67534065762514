import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from '../shared/formFields/Label';
import { Formik, Form, FieldArray } from 'formik';
import { Checkbox } from '../shared/formFields/CheckboxForGroup';
import { FilterRemove } from '@instech/icons';
import { constants } from '../../utils/constants';
import { SlimButton, BoxButton } from '@instech/components';
import { useAppContext } from '../appRouting/AppContext';

const StyledFilterRemove = styled(FilterRemove)`
  width: 20px;
  padding-left: 2px;
  color: ${(props) => props.theme.marineBlue50};
`;
const Wrapper = styled.div`
  background: ${(props) => props.theme.white};
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
  border-top: 2px solid ${(props) => props.theme.marineBlue};
  position: absolute;
  top: 64px;
  z-index: 2;
`;
const FilterItem = styled.div`
  margin-bottom: 32px;
`;
const CheckboxWrapper = styled.div`
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.marineBlue10};
  label {
    padding: 4px 0;
    color: ${(props) => props.theme.marineBlue};
    font-weight: normal;
  }
`;
const ClearFilersButton = styled.button`
  margin: 0 0 0 auto;
  color: ${(props) => props.theme.marineBlue50};
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  &:hover {
    background: ${(props) => props.theme.whiteBlue};
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: right;
  button {
    margin-left: 16px;
  }
`;
const SectionName = styled.div``;
const SectionType = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.border.gray};
`;
const OptionWrapper = styled.div`
  display: flex;
  margin: 8px 0;
`;
const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  color: ${(props) => props.theme.marineBlue};
  background-color: ${(props) => props.theme.marineBlue10};
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 16px;
  padding-left: 8px;
  border: 1px solid ${(props) => props.theme.marineBlue20};
  border-radius: 2px;
  outline: none;
  transition: 0.4s;
  height: 46px;
  &:focus,
  &:hover {
    border: 1px solid ${(props) => props.theme.marineBlue};
  }
  ::placeholder {
    color: ${(props) => props.theme.marineBlue50};
  }
`;

export const FilterUserSection = ({ sectionsList, onlySnapshots, handleOpenSectionFilters, setCurrentPage }) => {
  const { state, dispatch } = useAppContext();
  const sections = onlySnapshots ? state.sectionsForQuoted : state.sections;
  const [searchText, setSearchText] = useState('');

  const handleClearFilters = () => {
    dispatch({
      type: onlySnapshots ? constants.SET_SECTIONS_FOR_QUOTED : constants.SET_SECTIONS,
      value: [],
    });
    dispatch({
      type: onlySnapshots ? constants.SET_CASE_HANDLER_EMAILS_FOR_QUOTED : constants.SET_CASE_HANDLER_EMAILS,
      value: [],
    });
    setCurrentPage(1);
    handleOpenSectionFilters();
  };

  const handleSubmit = (values) => {
    dispatch({
      type: onlySnapshots ? constants.SET_SECTIONS_FOR_QUOTED : constants.SET_SECTIONS,
      value: values.sections,
    });
    dispatch({
      type: onlySnapshots ? constants.SET_CASE_HANDLER_EMAILS_FOR_QUOTED : constants.SET_CASE_HANDLER_EMAILS,
      value: [],
    });
    setCurrentPage(1);
    handleOpenSectionFilters();
  };

  const FormatLabel = ({ name, type }) => {
    return (
      <div>
        <SectionType>{type}</SectionType>
        <SectionName>{name}</SectionName>
      </div>
    );
  };

  const filtered =
    !!searchText &&
    sectionsList &&
    sectionsList?.filter(
      (section) =>
        section.name.toLowerCase().includes(searchText.toLowerCase()) ||
        section.type.toLowerCase().includes(searchText.toLowerCase())
    );

  return (
    <Wrapper>
      <ClearFilersButton data-test-id="clear-filters" type="button" onClick={handleClearFilters}>
        <span>Clear Filters</span>
        <StyledFilterRemove />
      </ClearFilersButton>
      <Formik enableReinitialize initialValues={{ sections }}>
        {({ values }) => {
          return (
            <Form>
              <FilterItem>
                <Label label="Sections" />
                <StyledInput
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <CheckboxWrapper>
                  <FieldArray
                    name="sections"
                    render={(arrayHelpers) => (
                      <div>
                        {!searchText &&
                          sectionsList?.map((item) => {
                            return (
                              <OptionWrapper key={item.code}>
                                <Checkbox
                                  name="sections"
                                  checked={values.sections?.includes(item.code)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      arrayHelpers.push(item.code);
                                    } else {
                                      const idx = values.sections.indexOf(item.code);
                                      arrayHelpers.remove(idx);
                                    }
                                  }}
                                  value={item.code}
                                  label={''}
                                />
                                <FormatLabel name={item.name} type={item.type} />
                              </OptionWrapper>
                            );
                          })}
                        {filtered &&
                          filtered.map((item) => {
                            return (
                              <OptionWrapper key={item.code}>
                                <Checkbox
                                  name="sections"
                                  checked={values.sections?.includes(item.code)}
                                  onChange={(e) => {
                                    if (e.target.checked) arrayHelpers.push(item.code);
                                    else {
                                      const idx = values.sections.indexOf(item.code);
                                      arrayHelpers.remove(idx);
                                    }
                                  }}
                                  value={item.code}
                                  label={''}
                                />
                                <FormatLabel name={item.name} type={item.type} />
                              </OptionWrapper>
                            );
                          })}
                        {!!searchText && filtered.length === 0 && 'no results found'}
                      </div>
                    )}
                  />
                </CheckboxWrapper>
              </FilterItem>
              <ButtonsWrapper>
                <BoxButton aria-label="Cancel" onClick={handleOpenSectionFilters} type="button" inverted padding="6px 16px">
                  CANCEL
                </BoxButton>
                <SlimButton aria-label="Filter" onClick={() => handleSubmit(values)} type="button" padding="0 16px">
                  FILTER
                </SlimButton>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};
FilterUserSection.propTypes = {
  sectionsList: PropTypes.array,
  onlySnapshots: PropTypes.bool,
  handleOpenSectionFilters: PropTypes.func,
};
