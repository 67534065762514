import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount } from '../../providers/authenticationProvider';
import { Update as UpdatesPageWidget } from 'insify-remote-component-loader';
import { createBrowserHistory } from 'history';

export const UpdatesPage = () => {
  const account = useAccount();
  const navigate = useNavigate();
  const history = createBrowserHistory();
  history.push = (to, state) => navigate(to, state);
  history.replace = (to, state) => navigate(to, { ...state, replace: true });

  return (
    <UpdatesPageWidget
      account={account}
      history={navigate}
      portal={{ name: 'kyc', displayName: 'Know Your Customer' }}
    />
  );
};
