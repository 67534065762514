import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { AsyncSelectField } from '../shared/formFields/AsyncSelectField';
import { debounce } from '../../utils/debounce';
import { getFleets } from '../../services/getFleets';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../appRouting/AppContext';
import { SearchByFleetNameTab, SearchByObjectNameTab } from './constants';

const SearchForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  @media (max-width: 980px) {
    margin: 0;
  }
`;
const Tab = styled.div`
  background: ${ (props) => props.$active ? props.theme.flatWhite : 'none' }; 
  color: ${ (props) => props.$active ? props.theme.marineBlue : props.theme.marineBlue50 };
  font-weight: ${ (props) => props.$active ? 'bold' : 'normal' };
  font-size: 12px;
  padding: 8px 16px;
  margin-bottom: -2px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
`;
const TabsWrapper = styled.div`
display: flex;
`;

export const SearchFleet = ({ isQuoted }) => {
  const { userSectionCode } = useAppContext();
  const [optionsCache, setOptionsCache] = useState([]);
  const [activeTab, setActiveTab] = useState(SearchByFleetNameTab);

  const handleTab = (tabName) => {
    if (tabName === SearchByFleetNameTab) {
      setActiveTab(SearchByFleetNameTab);
    }
    if (tabName === SearchByObjectNameTab) {
      setActiveTab(SearchByObjectNameTab);
    }
  }

  const searchField = activeTab === SearchByFleetNameTab ? 'keywords' : 'object';

  const allFleeetsParams = {
    limit: 1000,
    sort: ['entity.name'],
    searchField,
    onlySnapshots: isQuoted ? true : false,
    sectionCode: null,
  };

  let navigate = useNavigate();
  const goToFleet = (fleetId) => {
    navigate(`/fleets/${ fleetId }`, { replace: true });
  };
  const placeholderText = activeTab === SearchByFleetNameTab ? 'Search by fleet name' : 'Search by object name';

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ section: userSectionCode, fleetId: '' }}
      >
        {({ handleBlur, setFieldValue }) => {
          const loadOptionsAsync = debounce(async (value, callback) => {
            const searchField = activeTab === SearchByFleetNameTab ? 'keywords' : 'object';
            try {
              const fleets = await getFleets({ ...allFleeetsParams, searchField, searchText: value });
              const options = fleets.items.map((fleet) => {
                return {
                  value: fleet.id,
                  label: fleet.name + '  (' + fleet.insuranceYear + ')',
                };
              });
              setOptionsCache(options);  
              callback(options);
            } catch (error) {
              console.log(error);
            }
          }, 500);
          return (
            <>
              <Form>
                <SearchForm>
                  <TabsWrapper>
                    <Tab $active={activeTab === SearchByFleetNameTab} onClick={() => handleTab(SearchByFleetNameTab)}>Search by fleet name</Tab>
                    <Tab $active={activeTab === SearchByObjectNameTab} onClick={() => handleTab(SearchByObjectNameTab)} >Search by object name/IMO</Tab>
                  </TabsWrapper>
                  <AsyncSelectField
                    forSearchBox
                    name='fleetId'
                    placeholder={placeholderText}
                    loadOptions={loadOptionsAsync}
                    handleChange={(n, v) => {
                      setFieldValue(n, v.value);
                      goToFleet(v.value);
                    }}
                    handleBlur={handleBlur}
                    cachedOptions={optionsCache}
                  />
                </SearchForm>
              </Form>
            </>
          );
        }}
      </Formik>
    </div >
  );
};
