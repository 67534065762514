export enum Environment {
  Dev = 'dev',
  Test = 'test',
  User = 'user',
  Prod = 'prod'
}

export const getEnvironment = (): Environment => {
  const { host } = window.location;

  if (host.includes('localhost')) {
    const stsUri = document.head.querySelector('meta[name="sts"]')?.getAttribute('content') ?? '';

    if (stsUri.includes(Environment.Dev)) return Environment.Dev;
    if (stsUri.includes(Environment.Test)) return Environment.Test;
  }

  if (host.includes(Environment.Dev)) return Environment.Dev;
  if (host.includes(Environment.Test)) return Environment.Test;
  if (host.includes(Environment.User)) return Environment.User;

  return Environment.Prod;
};

export const getMyNhcUrl = (id: string): string => {
  const env = getEnvironment();
  const prefix = env === Environment.Prod ? "" : (env + "-");

  const url = prefix + "my.norclub.com/companies/" + id + "/kyc";

  return url;
}