import { GetApiData } from './fetchConfig';
import { AcceptanceReviewStatus, AcceptanceType } from "../utils/AcceptanceType";
import useSWR from "swr";

export type AcceptanceLog = InsuredObjectAcceptanceLog | CoverObjectAcceptanceLog | ManualHighRiskAcceptanceLog | OrganizationAcceptanceLog | PersonAcceptanceLog;

type AcceptanceLogBase = {
  acceptancePeriod: { from: string, to: string };
  acceptanceReviewStatus: AcceptanceReviewStatus;
  acceptanceType: AcceptanceType;
  acceptedAt: string;
  comments: string;
  entityId: string;
  id: string;
  isObsolete: boolean;
  personId: string;
  userId: string;
}

export type InsuredObjectAcceptanceLog = AcceptanceLogBase & {
  insuredObjectId: string;
  fleetId: string;
  entityType: 'InsuredObject';
}

export type CoverObjectAcceptanceLog = AcceptanceLogBase & {
  coverId: string;
  interestCodeId: string;
  fleetId: string;
  entityType: 'Cover';
}

export type ManualHighRiskAcceptanceLog = AcceptanceLogBase & {
  fleetId: string;
  entityType: 'ManualHighRiskForFleet';
}

export type OrganizationAcceptanceLog = AcceptanceLogBase & {
  organizationId: string;
  entityType: 'Organization';
}

export type PersonAcceptanceLog = AcceptanceLogBase & {
  personId: string;
  entityType: 'Person';
}

export const useAcceptanceLogForFleet = (id: string) => useSWR<AcceptanceLog[]>(`acceptancelogs/fleet/${id}`);

export const getAcceptanceLogForOrganization = (id: string) => GetApiData<AcceptanceLog[]>(`acceptancelogs/organization/${id}`);

export const useRiskLevelAcceptanceLogForFleet = (id: string | undefined) =>
  useSWR<AcceptanceLog[]>(id ? `acceptancelogs/risk-level/fleet/${id}` : null);
export const useMissingDataAcceptanceLogForFleet = (id: string | undefined) =>
  useSWR<AcceptanceLog[]>(id ? `acceptancelogs/missing-data/fleet/${id}` : null);

export const useRiskLevelAcceptanceLogForOrganization = (id: string) =>
  GetApiData<AcceptanceLog[]>(`acceptancelogs/risk-level/organization/${id}`);
export const useMissingDataAcceptanceLogForOrganization = (id: string) =>
  GetApiData<AcceptanceLog[]>(`acceptancelogs/missing-data/organization/${id}`);
