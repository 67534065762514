import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RadioButton } from '../shared/formFields/RadioButton';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 1fr 0.5fr 0.5fr 0.5fr;
  align-items: center;
  background: ${ (props) => (props.index % 2 === 0 ? props.theme.flatWhite : props.theme.white) };
  padding: 8px;
`;
const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  margin: 0 16px;
`;
const ItemTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const CompanyRow = ({ company, index, name, handleChange, checked, companySource }) => {
  const companyName = company.name || company.displayName || company.company.displayName;
  const companyAddress = company.displayAddress || company.country || company.company.displayAddress;
  return (
    <Wrapper index={index}>
      <RadioButton
        type='radio'
        value={company.organizationId || company.id}
        name={name}
        checked={checked}
        onChange={(e) => handleChange(e, company)}
      />
      <RowItem>
        <ItemTitle>Company</ItemTitle>
        <div>{companyName}</div>
      </RowItem>
      <RowItem>
        <ItemTitle>Address</ItemTitle>
        <div>{companyAddress}</div>
      </RowItem>
      <RowItem>
        <ItemTitle>LEI Code</ItemTitle>
        <div>{company.lei}</div>
      </RowItem>
      <RowItem>
        <ItemTitle>Org.Number</ItemTitle>
        <div>{company.organizationNumber}</div>
      </RowItem>
      <RowItem>
        <ItemTitle>Source</ItemTitle>
        <div>{companySource}</div>
      </RowItem>
    </Wrapper>
  );
};

CompanyRow.propTypes = {
  company: PropTypes.object,
  index: PropTypes.number,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
  companySource: PropTypes.string,
};
