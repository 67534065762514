import React, { useCallback, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { useCountries } from '../../../services/useCountries';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEventStream } from '../../../services/useEventStream';
import { useOrganizationRoles } from 'services/useOrganizationRoles';
import { constants } from '../../../utils/constants';
import { onKeyDown, validate, shouldValidate, checkExistingRole } from '../helpers';
import { initialState, reducer } from '../../../reducers/companyFormReducer';
import { CompanyFormRoleFields } from './CompanyFormRoleFields';
import { CompanyFormFields } from './CompanyFormFields';
import { PersonRoles } from '../../companyRoles/PersonRoles';
import { useCompanyContext } from '../CompanyContext';
import { CompanyFormConfirmDialog } from './CompanyFormConfirmDialog';
import { FormikChangeComponent } from '../FormikChangeComponent';
import { CompanyFormButtons } from './CompanyFormButtons';
import { CompanyFormNavigateButtons } from './CompanyFormNavigateButtons';
import { AcceptButtons } from '../AcceptButtons';
import { RevalidateResyncButtons } from '../../shared/RevalidateResyncButtons';
import { useAppContext } from '../../appRouting/AppContext';
import { ConfirmModalDialog } from '../../shared/ConfirmModalDialog';
import { ContentBlock } from '../../shared/ContentBlock';
import { MissingData } from "../../../utils/validations/types";
import { PromptUnsavedChanges } from "../../shared/PromptUnsavedChanges";

const FormWrapper = styled.fieldset`
  border: none;
  color: ${(props) => props.theme.marineBlue};
  padding: 0;
`;
const StyledForm = styled(Form)`
  background: ${(props) => props.theme.marineBlue10};
`;
const AcceptButtonsWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const CompanyForm = () => {
  const {
    organization,
    industries,
    fleetId,
    activePeriod,
    ownerIsAssured,
    isNewCompany,
    isNewRoleType,
    clientRoleType,
    vesselId,
    covers,
    mutateVesselList,
    forMultipleVessels,
    isRoleTypeEditable,
    locationState,
    companiesPage,
    newCompanyOrganization,
    validations
  } = useCompanyContext();

  const { isAdministrator, isDataAdministrator, state, dispatch } = useAppContext();
  const isConfirmDialogVisible = state.isConfirmDialogVisible;
  const setIsConfirmDialogVisible = (value) => {
    dispatch({ type: constants.SET_IS_CONFIRM_DIALOG_VISIBLE, value });
  };

  const isMissingInfo = ((validations.missingData - validations.acceptedMissingData) & MissingData.CompanyData) > MissingData.None;

  const [formState, formDispatch] = useReducer(reducer, initialState(clientRoleType));

  const isStateRequired = formState.isStateRequired;
  const isTaxDataRequired = formState.isTaxDataRequired;

  const uniqueCovers = covers && covers.filter((c, index) => covers.findIndex((obj) => obj.id === c.id) === index);

  const { mutate: mutateOrganizationRoles } = useOrganizationRoles(fleetId, vesselId);

  const countries = useCountries();

  const initialOrganization = newCompanyOrganization ?? organization;

  const initialValues = {
    covers: uniqueCovers,
    clientRoleType,
    activePeriod,
    ownerIsAssured,
    ...initialOrganization,
    industries,
    industryCustomComment: initialOrganization.industries.find(i => i.customComment)?.customComment || '',
  };

  const eventStreamTopic = '/Organization/' + organization?.id + '/' + organization?.id + '/*';

  const { onEvent, onConnect, onDisconnect, eventHandlerCleanup } = useEventStream(eventStreamTopic);

  onConnect(
    useCallback(() => {
      console.log('Connected to event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  onDisconnect(
    useCallback(() => {
      console.log('Disconnected from event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  useEffect(() => {
    onEvent((event) => {
      //console.info('Received event on topic company', eventStreamTopic, event);
      formDispatch({ type: constants.SET_UPDATED_BY, value: event.user?.displayName });
      if (!formState.isSaving && organization._etag !== event.subject.etag) {
        if (formState.isDirty) {
          formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: true });
        } else {
          formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
        }
      }
    });

    return eventHandlerCleanup;
  }, [eventStreamTopic, formDispatch, formState.isSaving, formState.isDirty]);

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    companiesPage &&
      navigate(location.pathname, {
        state: {
          ...locationState,
          clientId: organization?.id,
        },
        replace: true,
      });
  }, [ locationState, location.pathname, companiesPage, navigate, organization ] );
  

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      initialTouched={{ clientRoleType: { roleType: { id: true } } }}
      validate={(values) =>
        shouldValidate(initialValues, isNewCompany, isNewRoleType)
          ? validate(values, countries, isTaxDataRequired, isStateRequired)
          : null
      }
      validateOnMount={isRoleTypeEditable}
      validateOnChange={true}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        isSubmitting,
        setSubmitting,
        handleChange,
        handleBlur,
        handleReset,
        setFieldValue,
        setFieldTouched,
      }) => {
        const notValidRoleType = isRoleTypeEditable && !!errors?.clientRoleType?.roleType.id;
        const countryPartitionKey =
          values && values.legalAddress.country && values.legalAddress.country.partitionKey
            ? values.legalAddress.country.partitionKey
            : null;

        return (
          <StyledForm onKeyDown={onKeyDown}>
            <FormikChangeComponent
              values={values}
              setSubmitting={setSubmitting}
              isSubmitting={isSubmitting}
              dirty={dirty}
              notValidRoleType={notValidRoleType}
              countryPartitionKey={countryPartitionKey}
              formDispatch={formDispatch}
            />
            <PromptUnsavedChanges isDirty={ dirty && !isSubmitting } />
            <CompanyFormConfirmDialog dirty={dirty} formState={formState} formDispatch={formDispatch} />
            <FormWrapper>
              {(isAdministrator || isDataAdministrator) && !isNewCompany && (
                <RevalidateResyncButtons
                  entityId={organization.id}
                  entityType="Organization"
                  entityPartitionKey={organization.id}
                />
              )}
              <ContentBlock
                title={isNewCompany ? 'Add New Company' : 'Company Data'}
                type={isMissingInfo ? 'warning' : 'default'}
              >
                <AcceptButtonsWrapper>{!isNewCompany && <AcceptButtons />}</AcceptButtonsWrapper>
                <CompanyFormRoleFields
                  values={values}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  showRoleExistsMessage={formState.showRoleExistsMessage}
                  checkExistingRole={checkExistingRole}
                  formDispatch={formDispatch}
                />
                <CompanyFormFields
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  countries={countries}
                  isStateRequired={isStateRequired}
                  isTaxDataRequired={isTaxDataRequired}
                />
                {!forMultipleVessels && (
                  <CompanyFormButtons
                    dirty={dirty}
                    isSubmitting={isSubmitting}
                    formState={formState}
                    handleReset={handleReset}
                    values={values}
                    setSubmitting={setSubmitting}
                    formDispatch={formDispatch}
                    initialValues={initialValues}
                    mutateOrganizationRoles={mutateOrganizationRoles}
                    notValidRoleType={notValidRoleType}
                  />
                )}
              </ContentBlock>
              {!isNewCompany && !forMultipleVessels && (
                <PersonRoles
                  isNewPersonFormOpen={formState.isNewPersonFormOpen}
                  openNewPersonForm={() =>
                    formDispatch({
                      type: constants.SET_IS_NEW_PERSON_FORM_OPEN,
                      value: !formState.isNewPersonFormOpen,
                    })
                  }
                  mutateVesselList={mutateVesselList}
                  values={values}
                  fleetId={fleetId}
                />
              )}
              {forMultipleVessels && (
                <CompanyFormNavigateButtons
                  values={values}
                  handleReset={handleReset}
                  notValidRoleType={notValidRoleType}
                />
              )}
              {isConfirmDialogVisible && (
                <ConfirmModalDialog
                  text="Your changes have been saved. Validation of the fleet and fleet overview can take up to 30 seconds."
                  onCancel={() => setIsConfirmDialogVisible(false)}
                />
              )}
            </FormWrapper>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
