import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Company } from '../company/Company';
import { CompanyProvider } from '../company/CompanyContext';
import { ParseCompanyValidationFlags } from "../../utils/validations/functions";

export const CompanyItem = ({ item, mutate }) => {
  const [locationState, setLocationState] = useState(null);
  const validations = ParseCompanyValidationFlags(item);
  return (
    <CompanyProvider
      client={item}
      roleHeader='Company'
      companyListMutate={mutate}
      locationState={locationState}
      setLocationState={setLocationState}
      companiesPage={true}
      validations={validations}
      entityType={'Organization'}
    >
      <Company />
    </CompanyProvider>
  );
};

CompanyItem.propTypes = {
  item: PropTypes.object,
  mutate: PropTypes.func,
};
