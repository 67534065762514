export enum AcceptanceType {
    RiskLevel = 'RiskLevel',
    MissingData = 'MissingData',
    MissingPersonRoles = 'MissingPersonRoles',
    MissingUbo = 'MissingUbo'
}

export enum AcceptanceReviewStatus {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}
