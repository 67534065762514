import React, { useState, useEffect, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useOrganization } from 'services/useOrganization';
import { useAppContext } from '../appRouting/AppContext';
import { ParseCompanyValidationFlags } from "../../utils/validations/functions";

const CompanyContext = createContext({});

export const CompanyProvider = ({
  children,
  activePeriod = null,
  claims,
  client,
  clientRoleType,
  companiesPage,
  companyListMutate = null,
  covers = null,
  currentStep,
  fleetCovers,
  fleetId,
  forMultipleVessels,
  handleStepForward,
  isClientFromSearch,
  isCoAssured = false,
  isCompanyRole,
  validations,
  isNewCompany,
  isNewRoleTypeCompany,
  isRoleTypeEditable = false,
  isStepBack,
  isTitleAsAssured,
  locationState,
  mutateFleetDetails,
  mutateVesselList,
  newCompanyOrganization,
  organizationForCheckingIndex,
  organizationsListForChecking,
  organizationValues,
  ownerIsAssured = false,
  roleEtag = null,
  roleHeader = null,
  roleId = null,
  roleSourceSystem,
  setIsNewCompanyRoleTypeOpen,
  setIsSearchAssuredOpen,
  setIsSearchCoAssuredOpen,
  setLocationState,
  vesselId,
  entityType
}) => {
  const { dispatch, singleCompanyId } = useAppContext();

  const location = useLocation();

  const returnClientId = !!singleCompanyId ? singleCompanyId : location?.state?.clientId;
  const isNewRoleType = location.state?.isNewRoleType || isNewRoleTypeCompany || null;
  const [openClientId, setOpenClientId] = useState(returnClientId);

  useEffect(() => {
    singleCompanyId && setOpenClientId(singleCompanyId);
  }, [singleCompanyId]);


  const currentClientId = client ? client.id : !!singleCompanyId ? singleCompanyId : location.state?.clientId;

  const isClientOpen = openClientId === currentClientId || isClientFromSearch;

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmClose, setShowConfirmClose] = useState(false);

  const shouldFetch = isClientOpen && !newCompanyOrganization;
  const { data, mutate, error } = useOrganization(shouldFetch ? currentClientId : null);
  const organization = data ?? newCompanyOrganization ?? null;
  const industries = data?.industries?.map(i => ({ label: i.industry.name, value: i.industry.id}));
  const validationsForSingleCompany = !!singleCompanyId && ParseCompanyValidationFlags(organization);

  const clientData = !!client
    ? client
    : organization
      ? {
        displayName: organization.name,
        displayAddress: organization.legalAddress.addressLine,
        organizationNumber: organization.organizationNumber,
      }
      : null;

  const reference = client;

  const roleIsReadOnly = roleSourceSystem === 'Ins2000';

  //if we create a new role type, formik needs initial values with roleType in this format

  const formRoleType = !!clientRoleType
    ? clientRoleType
    : {
      id: '00000000-0000-0000-0000-000000000000',
      roleType: {
        id: '',
        roleName: '',
        isCoAssured: isCoAssured,
      },
    };

  const value = {
    activePeriod,
    claims,
    clientData,
    clientRoleType: formRoleType,
    companiesPage,
    companyListMutate,
    covers,
    appDispatch: dispatch,
    currentStep,
    error,
    fleetCovers,
    fleetId,
    isClientOpen,
    isCoAssured,
    isCompanyRole,
    validations: validations || validationsForSingleCompany,
    isNewCompany,
    isNewRoleType,
    isRoleTypeEditable,
    isStepBack,
    isTitleAsAssured,
    locationState,
    mutate,
    mutateFleetDetails,
    mutateVesselList,
    organization: organization || organizationValues,
    industries: industries ?? [],
    organizationForCheckingIndex,
    organizationsListForChecking,
    ownerIsAssured,
    reference,
    roleEtag,
    roleHeader,
    roleId,
    roleSourceSystem,
    roleIsReadOnly,
    setShowConfirmClose,
    setShowConfirmModal,
    showConfirmClose,
    showConfirmModal,
    singleCompanyId,
    vesselId,
    openClientId,
    currentClientId,
    setOpenClientId,
    setLocationState,
    forMultipleVessels,
    handleStepForward,
    setIsNewCompanyRoleTypeOpen,
    setIsSearchAssuredOpen,
    setIsSearchCoAssuredOpen,
    newCompanyOrganization,
    entityType
  };
  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};

export const useCompanyContext = () => useContext(CompanyContext);
