import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../shared/formFields/Checkbox';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  color: ${(props) => props.theme.marineBlue};
  background-color: ${(props) => props.theme.white};
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
  position: absolute;
  top: -16px;
  right: -205px;
  z-index: 99;
`;
const Item = styled.div`
  padding: 8px;
`;

export const CountryMenu = ({ isHighRiskCountry, handleChange, node }) => {
  const text = isHighRiskCountry ? 'remove as HIGH RISK country' : 'add as a HIGH RISK country';
  return (
    <Wrapper>
      <Item ref={node}>
        <Checkbox label={text} handleChange={handleChange} />
      </Item>
    </Wrapper>
  );
};

CountryMenu.propTypes = {
  isHighRiskCountry: PropTypes.bool,
  handleChange: PropTypes.func,
  node: PropTypes.object,
};
