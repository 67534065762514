import React, { useState } from 'react';
import styled from 'styled-components';
import { CompanySearch } from '../vesselsList/CompanySearch';
import { useFleetDetails } from '../../services/useFleetDetails';
import { Link } from 'react-router-dom';
import { LeftArrow } from '@instech/icons';
import { ProgressBar } from '../shared/ProgressBar';
import { NewCompanyForMultipleVesselsStepTwo } from './NewCompanyForMultipleVesselsStepTwo';
import { NewCompanyForMultipleVesselsConfirmation } from './NewCompanyForMultipleVesselsConfirmation';
import { CompanyData } from '../company/CompanyData';
import { useParams } from 'react-router-dom';
import { CompanyProvider } from '../company/CompanyContext';

const Wrapper = styled.div`
  padding: 10px 32px;
`;
const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 32px 0 32px;
`;
const BreadcrumbsItem = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue75};
`;
const Subtitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.marineBlue};
`;
const StyledProgressBar = styled(ProgressBar)`
  display: flex;
  align-items: center;
  margin: 56px 0 96px 0;
`;

export const NewCompanyForMultipleVessels = () => {
  const { fleetId } = useParams();
  const { data } = useFleetDetails(fleetId);
  const fleet = data;
  const fleetName = fleet?.name;
  const insuranceYear = fleet?.insuranceYear;
  const fleetCovers = fleet?.covers;
  const claims = fleet?.claims;
  const [currentStep, setCurrentStep] = useState(1);
  const [isStepBack, setIsStepBack] = useState(false);
  const [isCompanyNew, setIsCompanyNew] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [vesselsList, setVesselsList] = useState([]);

  const statusSteps = [
    { step: 1, name: 'Select existing company or create new' },
    { step: 2, name: 'Select vessels and interests' },
    { step: 3, name: 'Overview and save data' },
  ];

  const handleStepForward = (values) => {
    currentStep === 1 &&
      values &&
      setFormValues((prevState) => ({
        company: values,
        vessels: prevState?.vessels,
      }));
    currentStep === 2 &&
      values &&
      setFormValues((prevState) => ({
        vessels: values,
        company: prevState?.company,
      }));
    setCurrentStep(currentStep + 1);
    setIsStepBack(false);
  };
  const handleStepBack = () => {
    setCurrentStep(currentStep - 1);
    setIsStepBack(true);
  };
  const handleNewSearch = () => {
    if (isStepBack) {
      setFormValues(null);
      setIsStepBack(false);
    }
  };
  const [sort, setSort] = useState('name');

  const [fieldsSortOrder, setFieldsSortOrder] = useState({
    name: 'asc',
    imo: 'asc',
    flag: 'asc',
    type: 'asc',
    riskLevel: 'desc',
  });

  const sortOrder = fieldsSortOrder[sort] === 'asc' ? `entity.${sort}` : `-entity.${sort}`;

  const handleSort = (sort) => {
    setSort(sort);
    setFieldsSortOrder({
      ...fieldsSortOrder,
      [sort]: fieldsSortOrder[sort] === 'asc' ? 'desc' : 'asc',
    });
  };

  return (
    <Wrapper>
      <Div>
        <Breadcrumbs>
          <Link
            data-test-id="link-to-fleet"
            to={{
              pathname: `/fleets/${fleetId}`,
            }}
          >
            <LeftArrow />
          </Link>
          <BreadcrumbsItem>
            <Title>/ Fleet</Title>
            <Subtitle>
              {fleetName} ({insuranceYear})
            </Subtitle>
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Div>
      <StyledProgressBar steps={statusSteps} step={currentStep - 1} />
      {currentStep === 1 && (
        <>
          <CompanySearch
            sectionTitle="Co-assured"
            hideTitle={true}
            forMultipleVessels={true}
            handleStepForward={handleStepForward}
            currentStep={currentStep}
            isCoAssured={true}
            handleNewSearch={handleNewSearch}
            setIsCompanyNew={setIsCompanyNew}
            fleetId={fleetId}
          />
          {isStepBack && (
            <CompanyProvider
              fleetId={fleetId}
              organizationValues={formValues?.company}
              handleStepForward={handleStepForward}
              isRoleTypeEditable={true}
              forMultipleVessels={true}
              clientRoleType={formValues?.company.clientRoleType.roleType}
              isNewRoleTypeCompany={true}
              isCoAssured={true}
              isStepBack={isStepBack}
            >
              <CompanyData />
            </CompanyProvider>
          )}
        </>
      )}

      {currentStep === 2 && (
        <NewCompanyForMultipleVesselsStepTwo
          handleStepForward={handleStepForward}
          handleStepBack={handleStepBack}
          fleetId={fleetId}
          fleetCovers={fleetCovers}
          formValues={formValues}
          setFormValues={setFormValues}
          isStepBack={isStepBack}
          setVesselsList={setVesselsList}
          fieldsSortOrder={fieldsSortOrder}
          handleSort={handleSort}
          sortOrder={sortOrder}
          sort={sort}
          claims={claims}
        />
      )}
      {currentStep === 3 && (
        <NewCompanyForMultipleVesselsConfirmation
          handleStepBack={handleStepBack}
          fleetId={fleetId}
          fleetCovers={fleetCovers}
          fleetEtag={fleet._etag}
          formValues={formValues}
          vesselsList={vesselsList}
          isCompanyNew={isCompanyNew}
          fieldsSortOrder={fieldsSortOrder}
          handleSort={handleSort}
          sortOrder={sortOrder}
          setFieldsSortOrder={setFieldsSortOrder}
          claims={claims}
        />
      )}
    </Wrapper>
  );
};
