import useSWR from 'swr';
import { AcceptanceReviewStatus, AcceptanceType } from "../utils/AcceptanceType";

type AcceptanceReview = {
    id: string;
    createdAt: string;
    userId: string;
    comments: string;
    acceptancePeriod: boolean;
    acceptanceType: AcceptanceType;
    reviewUserId: string;
    reviewComments: string;
    status: AcceptanceReviewStatus;
    reviewedAt: string;
    _etag: string;
}

export type PersonAcceptanceReview = AcceptanceReview & {
    entityType: "Person";
    personId: string;
}

export type OrganizationAcceptanceReview = AcceptanceReview & {
    entityType: "Organization";
    organizationId: string;
}

export type ManualHighRiskAcceptanceReview = AcceptanceReview & {
    entityType: "ManualHighRisk";
    fleetId: string;
}

export const usePersonAcceptanceReviews = (personId: string | undefined) =>
    useSWR<PersonAcceptanceReview[]>(personId ? `acceptanceReviews/persons/${personId}` : null);

export const useOrganizationAcceptanceReviews = (organizationId: string | undefined) =>
    useSWR<OrganizationAcceptanceReview[]>(organizationId ? `acceptanceReviews/organizations/${organizationId}` : null);

export const useManualHighRiskAcceptanceReviews = (fleetId: string | undefined) =>
    useSWR<ManualHighRiskAcceptanceReview[]>(fleetId ? `acceptanceReviews/manual-high-risks/${fleetId}` : null);
