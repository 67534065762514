import { useState } from 'react';
import { useAppContext } from '../appRouting/AppContext';
import { useFleetItemsList, createRequestParams } from '../../services/useFleetItemsList';
import { subtractDate, subtractCurrentDate, guidelineInceptionDate } from 'utils/date';
import { RiskLevel } from 'utils/validations/types';

const setOffset = (currentPage, limit) => (currentPage - 1) * limit;

export const usePostBindFleets = () => {
  const { state } = useAppContext();
  const sections = state.sections;
  const caseHandlerEmails = state.caseHandlerEmails;
  const riskLevel = state.riskLevel;

  const overdueCurrentPage = state.currentPage.overdue;
  const followUpCurrentPage = state.currentPage.followUp;
  const missingInfoCurrentPage = state.currentPage.missingInfo;
  const noMissingInfoCurrentPage = state.currentPage.noMissingInfo;
  const highRiskCurrentPage = state.currentPage.highRisk;

  const [sort, setSort] = useState({
    missingInfo: 'name',
    overdue: 'name',
    followUp: 'name',
    noMissingInfo: 'name',
    highRisk: 'name'
  });
  const [fieldsSortOrder, setFieldsSortOrder] = useState({
    missingInfo: { name: 'asc' },
    overdue: { name: 'asc' },
    followUp: { name: 'asc' },
    noMissingInfo: { name: 'asc' },
    highRisk: {name: 'asc'},
  });

  const fleetsLimit = 8;

  const searchParams = {
    caseHandlerEmails,
    onlySnapshots: false,
    limit: fleetsLimit,
    sectionCodes: sections,
    hasCoverWithClaim: false // exclude fleets with claims only for post-bind fleets
  };

  const getSortParams = (fleetsSection) => {
    let sortParams = '';
    if (sort[fleetsSection] === 'inceptionDate') {
      sortParams =
        fieldsSortOrder[fleetsSection][sort[fleetsSection]] === 'asc' ? [`entity.inceptionDate`] : [`-entity.inceptionDate`];
    }
    else if (sort[fleetsSection] === 'name') {
      sortParams =
        fieldsSortOrder[fleetsSection][sort[fleetsSection]] === 'asc' ? [`entity.${sort[fleetsSection]}`] : [`-entity.${sort[fleetsSection]}`];
    }
    else if (sort[fleetsSection] === 'riskLevel') {
      sortParams = fieldsSortOrder[fleetsSection][sort[fleetsSection]] === 'asc'
        ? [`entity.validations.riskLevel`, `-entity.validations.acceptedHighRisk`]
        : [`-entity.validations.riskLevel`, `entity.validations.acceptedHighRisk`];
    }
    return sortParams
  }

  /////////////////////HIGH RISK FLEETS/////////////////////////////////
  const highRiskSort = getSortParams('highRisk');
  const highRiskparams = {
    sort: highRiskSort,
    offset: setOffset(highRiskCurrentPage, fleetsLimit),
    ...createRequestParams('High'),
    ...searchParams,
  }
  const handleHighRiskSort = (sortField) => {
    setSort((prev) => ({ ...prev, highRisk: sortField }));
    setOffset(0);
    setFieldsSortOrder({
      ...fieldsSortOrder,
      highRisk: {
        [sortField]: fieldsSortOrder.highRisk[sortField] === 'asc' ? 'desc' : 'asc',
      },
    });
  };
  const { fleets: highRiskFleets, totalCount: highRiskTotalCount } = useFleetItemsList(highRiskparams);


  /////////////////////OVERDUE FLEETS/////////////////////////////////
  const overdueSort = getSortParams('overdue');
  const handleOverdueSort = (sortField) => {
    setSort((prev) => ({ ...prev, overdue: sortField }));
    setOffset(0);
    setFieldsSortOrder({
      ...fieldsSortOrder,
      overdue: {
        [sortField]: fieldsSortOrder.overdue[sortField] === 'asc' ? 'desc' : 'asc',
      },
    });
  };
  const overdueDateParams = {
    inceptionDates: [
      { from: guidelineInceptionDate, to: subtractCurrentDate(30) }
    ]
  };
  const overdueParams = {
    sort: overdueSort,
    hasUnhandledMissingData: true,
    offset: setOffset(overdueCurrentPage, fleetsLimit),
    ...searchParams,
    ...createRequestParams(riskLevel.overdue),
    ...overdueDateParams
  };
  const { fleets: overdueFleets, totalCount: overdueTotalCount } = useFleetItemsList(overdueParams);

  /////////////////////FOLLOW UP FLEETS/////////////////////////////////
  const followUpSort = getSortParams('followUp');
  const handleFollowUpSort = (sortField) => {
    setSort((prev) => ({ ...prev, followUp: sortField }));
    setOffset(0);
    setFieldsSortOrder({
      ...fieldsSortOrder,
      followUp: {
        [sortField]: fieldsSortOrder.followUp[sortField] === 'asc' ? 'desc' : 'asc',
      },
    });
  };
  const followUpDateParams = {
    inceptionDates: [
      { from: subtractCurrentDate(29), to: subtractCurrentDate(15) }
    ]
  }
  const followUpParams = {
    sort: followUpSort,
    hasUnhandledMissingData: true,
    offset: setOffset(followUpCurrentPage, fleetsLimit),
    ...searchParams,
    ...createRequestParams(riskLevel.followUp),
    ...followUpDateParams
  };
  const { fleets: followUpFleets, totalCount: followUpTotalCount } = useFleetItemsList(
    followUpParams
  );

  /////////////////////MISSING INFO FLEETS/////////////////////////////////
  const missingInfoSort = getSortParams('missingInfo');
  const handleMissingInfoSort = (sortField) => {
    setSort((prev) => ({ ...prev, missingInfo: sortField }));
    setOffset(0);
    setFieldsSortOrder({
      ...fieldsSortOrder,
      missingInfo: {
        [sortField]: fieldsSortOrder.missingInfo[sortField] === 'asc' ? 'desc' : 'asc',
      },
    });
  };
  const missingInfoDateParams = {
    inceptionDates: [
      { to: subtractDate(1, guidelineInceptionDate) }, { from: subtractCurrentDate(14) }
    ]
  };
  const missingInfoParams = {
    sort: missingInfoSort,
    hasUnhandledMissingData: true,
    offset: setOffset(missingInfoCurrentPage, fleetsLimit),
    ...searchParams,
    ...createRequestParams(riskLevel.missingInfo),
    ...missingInfoDateParams
  };
  const { fleets: missingInfoFleets, totalCount: missingInfoTotalCount } = useFleetItemsList(
    missingInfoParams
  );

  /////////////////////NO MISSING INFO FLEETS/////////////////////////////////
  const noMissingInfoSort = getSortParams('noMissingInfo');
  const handleNoMissingInfoSort = (sortField) => {
    setSort((prev) => ({ ...prev, noMissingInfo: sortField }));
    setOffset(0);
    setFieldsSortOrder({
      ...fieldsSortOrder,
      noMissingInfo: {
        [sortField]: fieldsSortOrder.noMissingInfo[sortField] === 'asc' ? 'desc' : 'asc',
      },
    });
  };
  const noMissingInfoParams = {
    sort: noMissingInfoSort,
    hasUnhandledMissingData: false,
    offset: setOffset(noMissingInfoCurrentPage, fleetsLimit),
    ...searchParams,
    ...createRequestParams(riskLevel.noMissingInfo)
  };
  const { fleets: noMissingInfoFleets, totalCount: noMissingInfoTotalCount } = useFleetItemsList(
    noMissingInfoParams
  );

  return {
    fleetsLimit,
    missingInfoFleets,
    followUpFleets,
    overdueFleets,
    noMissingInfoFleets,
    highRiskFleets,
    missingInfoTotalCount,
    overdueTotalCount,
    followUpTotalCount,
    noMissingInfoTotalCount,
    highRiskTotalCount,
    handleMissingInfoSort,
    handleOverdueSort,
    handleFollowUpSort,
    handleHighRiskSort,
    handleNoMissingInfoSort,
  };
}
