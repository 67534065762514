import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 32px 0 0 32px;
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  div {
    margin-bottom: 4px;
  }
`;

export const WizardHeaderInfo = ({
  fleetName,
  isObjectName,
  objectName,
  roleName,
  insuranceYear,
  isSimilarCompanyStep,
}) => {
  return (
    <Wrapper>
      <Item>
        <div>Fleet:</div>
        {isObjectName && <div>Object(s):</div>}
        {isSimilarCompanyStep && <div>Role(s):</div>}
      </Item>
      <Item bold>
        <div>
          {fleetName} ({insuranceYear})
        </div>
        {isObjectName && <div>{objectName}</div>}
        {isSimilarCompanyStep && <div>{roleName}</div>}
      </Item>
    </Wrapper>
  );
};

WizardHeaderInfo.propTypes = {
  fleetName: PropTypes.string,
  objectName: PropTypes.string,
  isObjectName: PropTypes.bool,
  roleName: PropTypes.string,
  insuranceYear: PropTypes.number,
  isSimilarCompanyStep: PropTypes.bool,
};
