import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import { SWRConfig } from 'swr';
import { FeatureFlagProvider } from './providers/featureFlagProvider';
import { theme } from './utils/theme';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { getJsonAsync } from './services/fetchConfig';
import { AuthenticationProvider } from './providers/authenticationProvider';
import isPropValid from '@emotion/is-prop-valid';

const swrConfig = {
  revalidateOnFocus: false,
  errorRetryCount: 3,
  fetcher: getJsonAsync,
  onError: (error: any) => {
    if (error.response?.status === 403 && window.location.pathname !== '/user/unauthorized') {
      window.location.href = 'user/unauthorized';
    }
  },
};

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: any) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

const rootElement = document.getElementById('root');

// @ts-ignore
createRoot(rootElement).render(
  <React.StrictMode>
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <AuthenticationProvider>
        <ThemeProvider theme={theme}>
          <SWRConfig value={swrConfig}>
            <FeatureFlagProvider>
              <App />
            </FeatureFlagProvider>
          </SWRConfig>
        </ThemeProvider>
      </AuthenticationProvider>
    </StyleSheetManager>
  </React.StrictMode>
);

serviceWorker.unregister();
