import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { SelectField } from '../shared/formFields/SelectField';
import { Label } from '../shared/formFields/Label';
import { BoxButton, ButtonGroup, WarningNotification } from '@instech/components';
import { Close, Approved, Load } from '@instech/icons';
import { RisksTable } from './RisksTable';
import { getInterestsForQuotedFleet, getQuotedFleetsByYear } from '../../services/forQuotedFleets';
import { PostQuotedFleet } from '../../services/forQuotedFleets';
import { TableTemplate } from './TableTemplate';
import { ErrorMessage } from 'components/shared/datePicker/Components';
import { formatErrorMessage } from '../../utils/errorMessageFormatter';

const StyledLoad = styled(Load)`
  height: 20px;
  margin: 32px 32px 32px auto;
`;
const StyledApproved = styled(Approved)`
  height: 16px;
`;
const MessageWrapper = styled.div`
  margin: -16px 32px 32px 32px;
  text-align: right;
`;
const SuccessMessage = styled.div`
  color: ${(props) => props.theme.green};
  display: flex;
  align-items: center;
  justify-content: right;
`;
const StyledClose = styled(Close)`
  &:hover {
    transform: scale(1.3);
  }
`;
const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: scroll;
`;
const Dialog = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  left: 30%;
  right: 30%;
  top: 100px;
  height: fit-content;
  margin: auto;
  background: white;
  padding: 0;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.marineBlue};
  cursor: pointer;
  text-transform: uppercase;
  border-bottom: 1px solid ${(props) => props.theme.whiteBlue};
  padding: 8px;
`;
const HeaderTitle = styled.div`
  font-size: 18px;
`;
const ItemsWrapper = styled.div`
  align-self: center;
  padding: 32px;
`;
const Subtitle = styled.div`
  color: ${(props) => props.theme.marineBlue};
  line-height: 24px;
  text-align: center;
`;
const Item = styled.div`
  margin: 32px 0;
  font-size: 14px;
`;
const ButtonsWrapper = styled.div`
  padding: 32px;
`;
const NoRiskMessage = styled.div`
  margin: 32px 16px;
  color: ${(props) => props.theme.marineBlue};
`;

export const CreateFleetEntry = ({ onClose, mutateQuotedFleetValidationTasks }) => {
  const year = new Date().getFullYear() + 1;
  const [risks, setRisks] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [insuranceYear, setInsuranceYear] = useState(year);
  const [fleetName, setFleetName] = useState('');
  const [fleetId, setFleetId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [isLoadingInterests, setIsLoadingInterests] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const years = Array.from(new Array(100), (val, index) => year - index);

  useEffect(() => {
    setIsLoadingOptions(true);
    insuranceYear &&
      getQuotedFleetsByYear(insuranceYear)
        .then((response) => {
          const fleetsOptions = response.map((fleet) => {
            return {
              fleetName: fleet.name,
              fleetId: fleet.id,
              isSnapshot: fleet.isSnapshot,
            };
          });
          setFleets(fleetsOptions);
          setIsLoadingOptions(false);
        })
        .catch((error) => console.log(error));
  }, [insuranceYear]);

  useEffect(() => {
    isWarning && setRisks([]);
    !isWarning && insuranceYear && fleetName && setIsLoadingInterests(true);
    insuranceYear &&
      fleetName &&
      getInterestsForQuotedFleet(insuranceYear, fleetName)
        .then((response) => {
          const fleetsRisks = response.map((risk) => {
            return {
              interestCodeId: risk.id,
              interestCode: risk.interestCode.name,
              riskStatus: risk.riskStatus,
              riskNumber: risk.riskNumber,
            };
          });
          setRisks(fleetsRisks);
          setIsLoadingInterests(false);
        })
        .catch((error) => console.log(error));
  }, [insuranceYear, fleetName]);

  const handleSubmit = (values) => {
    setErrorMessage(null);
    setIsSubmitting(true);
    setIsError(false);
    PostQuotedFleet(values)
      .then(() => {
        setIsSubmitting(false);
        setIsSubmitted(true);
        setRisks([]);
        mutateQuotedFleetValidationTasks && mutateQuotedFleetValidationTasks();
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        setIsError(true);
        setErrorMessage(formatErrorMessage(error));
      });
  };
  const initialCovers = risks.map((risk) => risk.interestCodeId);

  const warningText = (fleetName, fleetId) => {
    return (
      <span>
        The fleet <Link to={{ pathname: `/fleets/${fleetId}` }}>{fleetName}</Link> is already in post-bind, click to
        open in post bind.
      </span>
    );
  };

  return (
    <Formik initialValues={{ insuranceYear, fleetName, covers: initialCovers }} enableReinitialize>
      {({ values, handleBlur, setFieldValue, handleChange }) => {
        return (
          <Wrapper>
            <Dialog>
              <Header>
                <HeaderTitle>Import Fleet</HeaderTitle>
                <StyledClose data-test-id="close-add-fleet" onClick={() => onClose()} />
              </Header>
              <ItemsWrapper>
                <Subtitle>
                  Filter to find a fleet from the INS+ Quotation module, and select the interest(s) to import.
                </Subtitle>
                <Item>
                  <Label label="Insurance Year" required />
                  <SelectField
                    name="insuranceYear"
                    placeholder="Select Year"
                    value={isSubmitted ? year : values.insuranceYear}
                    handleChange={(name, value) => {
                      setIsWarning(false);
                      setInsuranceYear(value);
                      setFieldValue(name, value);
                      setIsError(false);
                      setErrorMessage(null);
                      setIsSubmitted(false);
                      setFieldValue('fleetName', '');
                      setFleetName('');
                    }}
                    handleBlur={handleBlur}
                    options={years}
                    displayNameSelector={(x) => x}
                    valueNameSelector={(x) => x}
                  />
                </Item>
                <Item>
                  <Label label="Fleet" required />
                  <SelectField
                    name="fleetName"
                    placeholder={values.insuranceYear == null ? 'Select Insurance Year First' : 'Select Fleet'}
                    value={isSubmitted ? null : values.fleetName}
                    handleChange={(name, value) => {
                      setFleetName(value.name);
                      setFleetId(value.fleetId);
                      setIsWarning(!value.isSnapshot);
                      setFieldValue(name, value);
                      setIsError(false);
                      setErrorMessage(null);
                      setIsSubmitted(false);
                    }}
                    handleBlur={handleBlur}
                    options={fleets}
                    isLoadingOptions={isLoadingOptions}
                    displayNameSelector={(x) => x.fleetName}
                    valueNameSelector={(x) => {
                      return {
                        name: x.fleetName,
                        fleetId: x.fleetId,
                        isSnapshot: x.isSnapshot,
                      };
                    }}
                    isDisabled={values.insuranceYear == null}
                  />
                </Item>
                {isWarning && <WarningNotification size="small" headingText={warningText(fleetName, fleetId)} />}
                {!isWarning && (
                  <Item>
                    <Label label="Interests" />
                    {isLoadingInterests && <StyledLoad />}
                    {!isLoadingInterests && (values.fleetName === '' || values.fleetName == null || isSubmitted) ? (
                      <TableTemplate />
                    ) : risks.length === 0 && !isLoadingInterests && !isSubmitted ? (
                      <NoRiskMessage>No risks with objects found for this fleet.</NoRiskMessage>
                    ) : (
                      <RisksTable
                        risks={risks}
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Item>
                )}
              </ItemsWrapper>
              {isSubmitting ? (
                <StyledLoad />
              ) : (
                <ButtonsWrapper>
                  <ButtonGroup buttonGap="16px" marginTop="" alignRight>
                    <BoxButton
                      inverted
                      aria-label="Cancel"
                      onClick={() => onClose()}
                      padding="10px 20px"
                      title=""
                      type="button"
                    >
                      {isSubmitted ? 'CLOSE' : 'CANCEL'}
                    </BoxButton>
                    <BoxButton
                      aria-label="Import"
                      onClick={() => handleSubmit(values)}
                      disabled={isSubmitted || risks.length === 0 || isWarning}
                      padding="10px 20px"
                      title=""
                      type="button"
                    >
                      IMPORT
                    </BoxButton>
                  </ButtonGroup>
                </ButtonsWrapper>
              )}
              {isError && (
                <MessageWrapper>
                  <ErrorMessage>
                    <div>Failed to create fleet entry. {errorMessage}</div>
                  </ErrorMessage>
                </MessageWrapper>
              )}
              {isSubmitted && (
                <MessageWrapper>
                  <SuccessMessage>
                    <StyledApproved />
                    Import has started. It may take a few minutes to complete. You can now close this window or import
                    another fleet.
                  </SuccessMessage>
                </MessageWrapper>
              )}
            </Dialog>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

CreateFleetEntry.propTypes = {
  onClose: PropTypes.func,
  mutateQuotedFleetValidationTasks: PropTypes.func,
};
