import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getFriendlyDate, getFriendlyDateTime } from '../../utils/date';
import { getUser, User } from '../../services/getUser';
import { getAcceptanceLogComments } from '../../utils/acceptanceLogUtils';
import { ManualHighRiskAcceptanceLog } from '../../services/useAcceptanceLogs';

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 2fr) repeat(7, minmax(80px, 1fr)) minmax(150px, 2fr);
  grid-gap: 8px;
  align-items: center;
  padding: 16px 8px;
  word-wrap: break-word;
  &:nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;

export const LogDataManualHighRisk = ({ item }: { item: ManualHighRiskAcceptanceLog }) => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    item.userId &&
      getUser(item.userId).then((response: User) => {
        setUser(response);
      });
  }, [item.userId]);

  return (
    <Grid>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span>{getFriendlyDate(item.acceptancePeriod.from)}</span>
      <span>{getFriendlyDate(item.acceptancePeriod.to)}</span>
      <span>{user?.name}</span>
      <span>{getFriendlyDateTime(item.acceptedAt)}</span>
      <span>{getAcceptanceLogComments(item)}</span>
    </Grid>
  );
};
