import React from 'react';
import styled from 'styled-components';
import { MenuDots, HighRisk } from '@instech/icons';
import PropTypes from 'prop-types';
import { InterestMenu } from './InterestMenu';

const StyledMenuDots = styled(MenuDots)`
  transform: rotate(90deg);
  height: 16px;
  border-bottom: 1px solid ${(props) => props.theme.whiteBlue};
  margin-left: 8px;
  &:hover {
    cursor: pointer;
  }
`;
const StyledHighRisk = styled(HighRisk)`
  margin-left: 8px;
  color: ${(props) => props.theme.status.red};
`;

const Item = styled.div`
  background-color: ${(props) => (props.redBackground ? props.theme.background.red : props.theme.white)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  position: relative;
`;
const CheckboxMenu = styled.div`
  position: absolute;
  top: -32px;
  left: 70px;
  z-index: 99;
  padding: 5px 10px;
  min-width: 120px;
`;
const ItemWrapper = styled.div`
  position: relative;
  padding: 8px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;
const InterestName = styled.span`
  padding: 8px 12px 8px 8px;
`;
export const InterestItem = ({
  node,
  interest,
  openInterestMenuId,
  handleChange,
  isHighRiskInterestCode,
  isHighRiskInterestGroup,
  clickOpenInterestMenu,
  redBackground,
  isCodeStatusChanged,
  isGroupStatusChanged,
  disabled,
  isUserRoleAllowed,
}) => {
  return (
    <ItemWrapper disabled={disabled}>
      <Item
        isHighRiskInterestCode={isHighRiskInterestCode}
        redBackground={(redBackground && isCodeStatusChanged) || (redBackground && isGroupStatusChanged)}
      >
        {(isHighRiskInterestCode || isHighRiskInterestGroup) && <StyledHighRisk />}
        <InterestName>{interest.name}</InterestName>
        {!isHighRiskInterestGroup && isUserRoleAllowed && (
          <StyledMenuDots data-test-id="interest-menu" onClick={() => clickOpenInterestMenu(interest.id)} />
        )}
      </Item>
      <CheckboxMenu>
        {openInterestMenuId === interest.id && (
          <InterestMenu handleChange={handleChange} node={node} checked={isHighRiskInterestCode} />
        )}
      </CheckboxMenu>
    </ItemWrapper>
  );
};

InterestItem.propTypes = {
  node: PropTypes.object,
  interest: PropTypes.object,
  openInterestMenuId: PropTypes.string,
  handleChange: PropTypes.func,
  isHighRiskInterestCode: PropTypes.bool,
  isHighRiskInterestGroup: PropTypes.bool,
  clickOpenInterestMenu: PropTypes.func,
  redBackground: PropTypes.bool,
  isCodeStatusChanged: PropTypes.bool,
  isGroupStatusChanged: PropTypes.bool,
  disabled: PropTypes.bool,
  isUserRoleAllowed: PropTypes.bool,
};
