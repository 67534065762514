import { postJsonAsync } from './fetchConfig';

type EnqueueFleetConsistencyCheckCommand = { fleetId: string };

export const enqueueFleetToFix = (payload: { entityId: string, entityType: string }) => {
    if (payload.entityType !== 'Fleet') {
        return;
    }

    const endpoint = 'consistency/enqueue-fleet';
    const command: EnqueueFleetConsistencyCheckCommand = { fleetId: payload.entityId };
    return postJsonAsync<unknown, EnqueueFleetConsistencyCheckCommand>(endpoint, command);
}
