export const formatErrorMessage = (error) => {
  if (!error.response) {
    return null;
  }

  var msg = error.response.data.Message;

  if (error.response.data.Details) {
    Object.keys(error.response.data.Details).forEach((x) => {
      msg = '\n - ' + error.response.data.Details[x];
    });
  }

  return msg;
};
