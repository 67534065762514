import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { OwnersList } from './OwnersList';
import { useOwnersList } from '../../services/getOwnersList';
import { ImportCompany, AddCompanyRolesToObjects } from '../../services/postPutCompanyData';
import { SimilarOwners } from './SimilarOwners';
import { WizardHeaderInfo } from '../beneficialOwnerWizard/WizardHeaderInfo';
import { TransferOwnersList } from './TransferOwnersList';
import { Close, LeftArrow, Load, ArrowBack, ArrowForward } from '@instech/icons';
import { BoxButton } from '@instech/components';
import { useOrganizationRoleTypes } from '../../services/UseOrganizationRoleTypes';
import { formatErrorMessage } from '../../utils/errorMessageFormatter';

const StyledLoad = styled(Load)`
  height: 20px;
  margin: 32px auto;
`;
const ErrorMessage = styled.div`
  color: red;
  text-align: right;
`;
const ComponentWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const ContentWrapper = styled.div`
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.marineBlue};
  padding: 16px;
  margin: 64px;
`;
const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  color: ${(props) => props.theme.marineBlue};
  cursor: pointer;
`;
const BackHomeLink = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const StyledClose = styled(Close)`
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
const Title = styled.div`
  font-size: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.marineBlue};
  margin: 32px;
  text-align: center;
`;
const SubTitle = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 16px 0;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.stepOne ? 'right' : 'space-between')};
  margin-top: 36px;
  padding: 32px;
`;
const ApproveButtonWrapper = styled.div`
  margin: 0 0 0 auto;
`;

const groupedCompanies = (companies) => {
  const result = [];
  companies.forEach((c) => {
    const index = result.findIndex((r) => r.company.id === c.company.id);
    if (index >= 0) {
      result[index].roles.push({
        roleType: c.roleType,
        period: c.period,
      });
    } else {
      c.roles = [
        {
          roleType: c.roleType,
          period: c.period,
        },
      ];
      result.push(c);
    }
  });
  return result;
};
export const SelectOwners = ({
  fleetName,
  vesselName,
  insuranceYear,
  handleClose,
  fleetId,
  vesselId,
  mutateVesselList,
  mutateWizardValidationTasks,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [checkedOwners, setCheckedOwners] = useState([]);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const [unselectAllIds, setUnselectAllIds] = useState([]);
  const [checkedPages, setCheckedPages] = useState([]);
  const [savedSelectedOwners, setSavedSelectedOwners] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isImportError, setIsImportError] = useState(false);
  const [isImportErrorMessage, setIsImportErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const organizationRoleTypes = useOrganizationRoleTypes();
  const { data: ownersList, error } = useOwnersList(fleetId, vesselId);

  const checkedOwnersWithSimilar = checkedOwners.filter((c) => c.similarKycOrganizations?.length > 0);
  const isSimilarOwnersExist = checkedOwnersWithSimilar && checkedOwnersWithSimilar.length > 0;
  const groupedOwnersWithSimilar = groupedCompanies(checkedOwnersWithSimilar);

  const handleCheckOwner = (target, owner) => {
    if (target.checked) {
      setCheckedOwners((prev) => [...prev, owner]);
      if (!owner.similarKycOrganizations || owner.similarKycOrganizations.length === 0)
        setSelectedOwners((prev) => [
          ...prev.filter((p) => p.company.id !== owner.company.id || p.roleType.roleName !== owner.roleType.roleName),
          owner,
        ]);
    } else {
      setCheckedOwners((prev) =>
        prev.filter((p) => p.company.id !== owner.company.id || p.roleType.roleName !== owner.roleType.roleName)
      );
      setSelectedOwners((prev) =>
        prev.filter((p) => p.company.id !== owner.company.id || p.roleType.roleName !== owner.roleType.roleName)
      );
    }
  };

  const handleChangeSimilar = (e, company) => {
    const name = e.target.name;
    let companies;
    if (company.company?.id === name) {
      //if not similar company selected
      companies =
        company.roles.length > 1
          ? company.roles.map((o) => ({
              ...company,
              roleType: o.roleType,
              period: o.period,
            }))
          : [company];
    } else {
      const mainCompany = groupedOwnersWithSimilar.find((g) => g.company.id === company.mainCompany);
      const similarCompany = {
        id: company.id,
        company: company,
        kycOrganization: company,
        roleType: mainCompany.roleType,
        period: mainCompany.period,
      };
      companies =
        mainCompany.roles.length > 1
          ? mainCompany.roles.map((o) => ({
              ...similarCompany,
              roleType: o.roleType,
              period: o.period,
            }))
          : [similarCompany];
    }
    setSelectedOwners((prev) => [
      ...prev.filter((p) => p.company.mainCompany !== name && p.company.id !== name),
      ...companies,
    ]);
  };

  const handleUnselectAll = (id, currentPage) => {
    setUnselectAllIds((prev) => [...prev.filter((p) => p !== id), id]);
    setSelectedOwners((prev) => prev.filter((p) => p.company.mainCompany !== id && p.company.id !== id));
    setCheckedPages((prev) => prev.filter((p) => p !== currentPage));
  };
  const handleClickBack = () => {
    if (isSimilarOwnersExist) {
      currentStep === 3 && setSelectedOwners(savedSelectedOwners);
      setCurrentStep(currentStep - 1);
    } else {
      setSelectedOwners(savedSelectedOwners);
      setCurrentStep(1);
    }
    setIsError(false);
    setIsImportError(false);
  };
  const handleClickNext = () => {
    if (isSimilarOwnersExist) {
      currentStep === 2 && setSavedSelectedOwners(selectedOwners);
      setCurrentStep(currentStep + 1);
    } else {
      setSavedSelectedOwners(selectedOwners);
      setCurrentStep(3);
    }
  };
  const handleApprove = async () => {
    setIsSubmitting(true);
    setIsError(false);
    setIsImportError(false);

    const selectedForSubmit = selectedOwners.map((s) => {
      s.organizationId = s.company.id;
      s.twoLetterCountryCode = '';
      s.objectId = vesselId;
      s.objectName = vesselName;
      const roleType = organizationRoleTypes.find((r) => {
        return r.roleName === s.roleType.roleName && r.isCoAssured === false;
      });
      s.roleType = roleType;
      s.activePeriod = {
        from: s.period.from,
        to: s.period.to,
      };
      return s;
    });

    let submittingValues = {
      fleetId,
      data: selectedForSubmit,
      subject: {
        id: fleetId,
        partitionKey: fleetId,
        entityType: 'Fleet',
      },
    };

    let uniqueCompaniesForImport = [...new Map(selectedForSubmit.map((item) => [item.organizationId, item])).values()];

    for (let index = 0; index < uniqueCompaniesForImport.length; ++index) {
      const roleData = uniqueCompaniesForImport[index];
      if (!roleData.kycOrganization) {
        try {
          const response = await ImportCompany({
            sourceId: roleData.organizationId,
            sourceSystem: 'Lli',
            twoLetterCountryCode: '',
          });
          uniqueCompaniesForImport[index].organizationId = response.id;
        } catch (error) {
          console.log(error);
          setIsImportError(true);
          setIsImportErrorMessage(`Failed to add company ${uniqueCompaniesForImport.map((el) => el.name)}`);
          //remove from unique companies array failed to import companies
          uniqueCompaniesForImport = uniqueCompaniesForImport.filter(
            (d) => d.organizationId !== roleData.organizationId
          );
          --index;
        }
      } else {
        uniqueCompaniesForImport[index].organizationId = roleData.kycOrganization.id;
      }
    }

    try {
      const companiesForAddOwnersToObjects = submittingValues.data
        .filter((d) => uniqueCompaniesForImport.some((u) => u.company.id === d.company.id)) //keep in data only successfully imported companies
        .map((company) => {
          const responseCompanyData = uniqueCompaniesForImport.find((c) => c.company.id === company.company.id);
          company.organizationId = responseCompanyData.organizationId;
          company.twoLetterCountryCode = responseCompanyData.twoLetterCountryCode;
          company.mdmReference = {
            mdmExternalId: responseCompanyData.id,
            mdmSourceEntityType: 'vesselownership',
            sourceSystem: 'LLI',
          };
          delete company.kycOrganization;
          return company;
        });
      const dataForAddOwnersToObjects = { ...submittingValues, data: companiesForAddOwnersToObjects };

      await AddCompanyRolesToObjects(dataForAddOwnersToObjects);
    } catch (error) {
      console.log('post error', error);
      setIsSubmitting(false);
      setIsError(true);
      setErrorMessage(formatErrorMessage(error));
      return;
    }
    setIsSubmitting(false);
    setIsSubmitted(true);
    mutateWizardValidationTasks && mutateWizardValidationTasks();
    mutateVesselList && mutateVesselList();
  };

  const title =
    currentStep === 2
      ? 'SIMILAR COMPANIES EXIST IN THE KYC-PORTAL'
      : "Information about OWNERS FROM LloYd's List Intelligence";
  const subtitle =
    currentStep === 2 &&
    'Please consider using one of the existing companies instead of transferring a new one from LLI';

  const [currentPage, setCurrentPage] = useState(1);
  const companyIndex = currentPage - 1;
  const mainCompanyId = groupedOwnersWithSimilar[companyIndex]?.company.id;

  const roleNames = [...new Set(groupedOwnersWithSimilar[companyIndex]?.roles.map((o) => o.roleType.roleName))].join();

  return (
    <ComponentWrapper>
      <ContentWrapper>
        <Navigation>
          <BackHomeLink data-test-id="back-home" onClick={handleClose}>
            <LeftArrow /> <span>Back to object overview</span>
          </BackHomeLink>
          <StyledClose data-test-id="close-select-owners" onClick={handleClose} />
        </Navigation>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        <WizardHeaderInfo
          fleetName={fleetName}
          insuranceYear={insuranceYear}
          objectName={vesselName}
          isObjectName={true}
          roleName={roleNames}
          isSimilarCompanyStep={currentStep === 2}
        />

        {currentStep === 1 && (
          <OwnersList
            checkedOwners={checkedOwners}
            handleCheckOwner={handleCheckOwner}
            ownersList={ownersList}
            error={error}
          />
        )}
        {currentStep === 2 && (
          <SimilarOwners
            selectedOwners={selectedOwners}
            handleChange={handleChangeSimilar}
            checkedPages={checkedPages}
            setCheckedPages={setCheckedPages}
            groupedOwnersWithSimilar={groupedOwnersWithSimilar}
            unselectAllIds={unselectAllIds}
            handleUnselectAll={handleUnselectAll}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            mainCompanyId={mainCompanyId}
            companyIndex={companyIndex}
          />
        )}
        {currentStep === 3 && (
          <TransferOwnersList
            selectedOwners={selectedOwners}
            isSubmitted={isSubmitted}
            groupedOwnersWithSimilar={groupedOwnersWithSimilar}
          />
        )}
        <ButtonsWrapper>
          {currentStep > 1 && !(isSubmitted || isSubmitting) ? (
            <BoxButton
              data-test-id="navigate-back"
              aria-label="Back"
              inverted
              onClick={handleClickBack}
              padding="10px 20px"
              startIcon={<ArrowBack />}
              type="button"
            >
              BACK
            </BoxButton>
          ) : (
            <div></div>
          )}
          {currentStep === 3 ? (
            <ApproveButtonWrapper>
              {isSubmitting ? (
                <StyledLoad />
              ) : isSubmitted ? (
                <BoxButton aria-label="Close" inverted onClick={handleClose} padding="10px 20px" type="button">
                  CLOSE
                </BoxButton>
              ) : (
                <BoxButton
                  aria-label="Approve"
                  disabled={selectedOwners.length === 0 || isSubmitted}
                  onClick={handleApprove}
                  padding="10px 20px"
                  type="button"
                >
                  APPROVE
                </BoxButton>
              )}
            </ApproveButtonWrapper>
          ) : (
            <BoxButton
              data-test-id="navigate-forward"
              aria-label="Next"
              onClick={handleClickNext}
              padding="10px 20px"
              endIcon={<ArrowForward />}
              type="button"
            >
              NEXT
            </BoxButton>
          )}
        </ButtonsWrapper>
        {isError && (
          <ErrorMessage>
            <div>Failed to save data.</div>
            <div>{errorMessage}</div>
          </ErrorMessage>
        )}
        {isImportError && <ErrorMessage>{isImportErrorMessage}</ErrorMessage>}
      </ContentWrapper>
    </ComponentWrapper>
  );
};

SelectOwners.propTypes = {
  fleetName: PropTypes.string,
  vesselName: PropTypes.string,
  handleClose: PropTypes.func,
  fleetId: PropTypes.string,
  vesselId: PropTypes.string,
  insuranceYear: PropTypes.number,
  mutateVesselList: PropTypes.func,
  mutateWizardValidationTasks: PropTypes.func,
};
