import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const RadioButtonField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  line-height: 21px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => (props.disabled && props.theme.mediumGray) || (props.error && props.theme.red)};
  & input[type='radio'] {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: transparent;
    border: 2px solid ${(props) => props.theme.marineBlue};
    border-radius: 50%;
    box-sizing: border-box;
    appearance: none;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    ${(props) =>
      props.disabled &&
      css`
        border-color: ${props.theme.mediumGray};
      `}

    &:checked {
      border-color: ${(props) => props.theme.marineBlue};
      ${(props) =>
        props.disabled &&
        css`
          border-color: ${props.theme.mediumGray};
        `}
    }
    &:checked::after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      background: ${(props) => props.theme.marineBlue};
      border-radius: 50%;
      /* hacky positioning that scales better on zoom than Flex */
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      ${(props) =>
        props.disabled &&
        css`
          background: ${props.theme.mediumGray};
        `};
    }
  }
`;

export const RadioButton = ({ name, value, onChange, checked, disabled }) => {
  return (
    <RadioButtonField disabled={disabled}>
      <input type='radio' name={name} value={value} onChange={onChange} checked={checked} disabled={disabled} />
    </RadioButtonField>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};
