import React, { ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';
import { ContentTabs, TabDefinition } from './ContentTabs';

const ActiveTabContentWrapper = styled.div`
  background: ${(props) => props.theme.marineBlue10};
  padding: 32px;
`

export type ContentTabDefinition = TabDefinition & { render?: () => ReactNode, content?: ReactNode }

type TabbedContentProps = {
  tabs: ContentTabDefinition[];
  className?: string;
  children?: (activeTab: ContentTabDefinition) => ReactNode;
}

export const TabbedContent = ({ tabs, children, ...props }: TabbedContentProps) => {

  const [activeTab, setActiveTab] = useState<ContentTabDefinition | undefined>(tabs[0]);

  const setActiveTabKey = useCallback(
    (key: string) => setActiveTab(tabs.find(t => t.key == key)),
    [tabs]);

  return (
    <>
      <ContentTabs activeTab={activeTab?.key} setActiveTab={setActiveTabKey} tabs={tabs} {...props} />
      <ActiveTabContentWrapper>
        {activeTab?.content}
        {activeTab?.render?.()}
        {activeTab && children?.(activeTab)}
      </ActiveTabContentWrapper>
    </>
  );
};
