import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ClaimsTag } from '../shared/ClaimsTag';
import { InterestCodes } from 'components/interests/InterestCodes';
import { getFriendlyDate } from '../../utils/date';
import { StatusIcons } from '../shared/kyc-icons/StatusIcons';
import { ParseValidationFlags } from "../../utils/validations/functions";
import { RiskIcons } from 'components/shared/kyc-icons/RiskIcons';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(60px, 0.8fr) repeat(3, minmax(60px, 0.5fr)) repeat(2, minmax(80px, 1fr));
  grid-gap: 8px;
  color: ${ (props) => props.theme.marineBlue };
  padding: 8px 0;
  align-items: center;
  font-size: 14px;
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-wrap: wrap;
  word-break: break-all;
`;
const CaseHandlersList = styled.div`
  padding-left: 10px;
  word-break: break-all;
`;
const FleetNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;
const StatusIconsWrapper = styled.div`
  padding-left:10px;
  align-self: center;
`;

export const FleetItemHeader = ({ item }) => {
  return (
    <Wrapper>
      <Item>
        <FleetNameWrapper>
          <div>{`${ item.name } (${ item.insuranceYear })`}</div>
        </FleetNameWrapper>
        {item.claims && item.claims.length >= 1 && (
          <ClaimsTag claimsCounter={item.claims.length} />
        )}
      </Item>
      <StatusIconsWrapper>
        <StatusIcons sourceType='fleetsList' validations={ParseValidationFlags(item.validations)} entityType={'Fleet'} />
      </StatusIconsWrapper>
      <Item>
        <RiskIcons validations={ParseValidationFlags(item.validations)} />
      </Item>
      <Item>{item.inceptionDate ? getFriendlyDate(item.inceptionDate) : '-'}</Item>
      <Item>{item.numberOfInsuredObjects}</Item>
      <Item>
        <InterestCodes covers={item.covers} />
      </Item>
      <CaseHandlersList>
        {item.caseHandlers?.map((el, index) => (
          <div key={index}>{"\n"}{el}</div>
        ))}
      </CaseHandlersList>
    </Wrapper>
  );
};

FleetItemHeader.propTypes = {
  item: PropTypes.object,
};
