import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { BoxButton, Divider } from '@instech/components';
import { Formik } from 'formik';
import { useAccount } from '../../providers/authenticationProvider';
import { AddManualHighRiskData, addManualHighRiskForFleet } from 'services/addManualHighRisk';
import { ReadOnlyInput } from 'components/shared/formFields/InputField';
import { Label } from 'components/shared/formFields/Label';
import { TextAreaField } from 'components/shared/formFields/TextAreaField';
import { Load } from '@instech/icons';
import { Modal } from 'components/shared/Modal';

const StyledLoad = styled(Load)`
  &&& {
    height: 16px;
  }
`;

const FormWrapper = styled.div`
  padding: 0 16px 16px 16px;
  color: ${(props) => props.theme.marineBlue};
  background: ${(props) => props.theme.white};
`;

const TopBox = styled.div`
  border: 2px solid ${(props) => props.theme.background.primary};
  display: grid;
  grid-template-columns: 1fr;
  margin: 20px 20px 0px 20px;
  align-items: start;
`;

const TopHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 20px;
  padding: 20px 20px 0px 20px;
  align-items: start;
`;

const TopDetail = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 20px;
  padding: 0px 20px 20px 20px;
  align-items: start;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding: 20px;
  align-items: start;
`;

const ErrorMessage = styled(Row)`
  color: #de350b;
`;

const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;
  align-items: start;
`;

const WarningBoxButton = styled(BoxButton)`
  &&& {
    background-color: ${({ theme }) => theme.yellow};
    color: ${({ theme }) => theme.marineBlue};
    border: none;
    &:hover:not(:disabled) {
      color: ${({ theme }) => theme.marineBlue};
      background-color: ${({ theme }) => theme.lightYellow};
    }
  }
`;

type AddManualHighRiskForFleetDialogProps = {
  fleet: { id: string; name: string; insuranceYear: number };
  onCancel: () => void;
  onComplete: () => void;
};

const initialValues: AddManualHighRiskData = {
  comments: '',
};

const validationSchema = Yup.object().shape({
  comments: Yup.string().required('Reason for high risk is required'),
});

export const AddManualHighRiskForFleetDialog = ({
  fleet,
  onCancel,
  onComplete,
}: AddManualHighRiskForFleetDialogProps) => {
  const { user } = useAccount();
  const [submitError, setSubmitError] = useState<string>();

  const handleSubmit = useCallback(
    async (values: AddManualHighRiskData) => {
      setSubmitError(undefined);
      try {
        await addManualHighRiskForFleet(fleet.id, values);
        onComplete();
      } catch {
        setSubmitError('Request to set high risk failed');
      }
    },
    [fleet.id, onComplete]
  );

  return (
    <Modal title="Add manual high risk for fleet" onClose={onCancel}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, submitForm, handleChange, handleBlur, errors, touched, isSubmitting }) => {
          return (
            <FormWrapper>
              <TopBox>
                <TopHeader>
                  <Label label="Fleet" fontSize="12px" />
                </TopHeader>
                <Divider />
                <TopDetail>
                  <Label label={`${fleet.name} (${fleet.insuranceYear})`} fontWeight="normal" />
                </TopDetail>
              </TopBox>

              <Row>
                <Label label="User" />
                <ReadOnlyInput value={user?.name || 'User'} />
              </Row>

              <Row>
                <Label label="Reasons for high risk" />
                <TextAreaField
                  name="comments"
                  value={values.comments}
                  placeholder="Describe reason here"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
              </Row>

              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

              <ButtonRow>
                <BoxButton onClick={onCancel} type="button" inverted>
                  Cancel
                </BoxButton>
                <WarningBoxButton onClick={submitForm} disabled={isSubmitting} type="submit">
                  {isSubmitting ? <StyledLoad /> : 'Set high risk'}
                </WarningBoxButton>
              </ButtonRow>
            </FormWrapper>
          );
        }}
      </Formik>
    </Modal>
  );
};
