import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Tab = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  height: 40px;
  width: fit-content;
  white-space: nowrap;
  line-height: 40px;
  padding: 0 32px;
  border-radius: 6px 6px 0px 0px;
  font-size: 14px;
  font-weight: ${(props) => (props.$active ? '600' : 'normal')};
  background: ${(props) => (props.$active ? props.theme.background.primary : props.theme.marineBlue)};
  text-align: center;
  color: ${(props) => (props.$active ? props.theme.marineBlue : props.theme.marineBlue50)};
  a:visited {
    color: ${(props) => props.theme.marineBlue50};
  }
`;
const TabsWrapper = styled.div`
  display: flex;
  align-self: end;
`;

export const HeaderTabs = ({ singleCompanyId, isAuthenticated }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const companiesPath = !!singleCompanyId ? `/companies/${singleCompanyId}` : `/companies`;
  
  return (
    <TabsWrapper>
        <Tab $active={splitLocation[1] === 'preBindFleets' ? 'active' : ''} to={{ pathname: `/preBindFleets` }}>
          Pre-Bind
        </Tab>
      {isAuthenticated && (
        <Tab $active={splitLocation[1] === '' ? 'active' : ''} to={{ pathname: `/` }}>
          Post-Bind
        </Tab>
      )}
        <Tab
          $active={splitLocation[1] === 'companies' && splitLocation.length < 4 ? 'active' : ''}
          to={{ pathname: companiesPath }}
        >
          Companies
        </Tab>
        <Tab $active={splitLocation[1] === 'persons' ? 'active' : ''} to={{ pathname: `/persons` }}>
          Persons
        </Tab>
    </TabsWrapper>
  );
};

HeaderTabs.propTypes = {
  singleCompanyId: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};
