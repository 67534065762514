import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getIn } from 'formik';

const TextArea = styled.textarea`
  box-sizing: border-box;
  font: inherit;
  border: 1px solid ${(props) => props.$borderError || props.theme.marineBlue50};
  border-radius: 2px;
  text-align: left;
  padding: 10px;
  width: 100%;
  outline: none;
  transition: 0.4s;
  resize: none;
  height: 100%;

  &::placeholder {
    color: ${(props) => props.theme.border.gray};
  }
  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${(props) => props.theme.marineBlue};
  }
  :disabled {
    border: 1px solid ${(props) => props.theme.border.blueGray};
  }
`;

const Error = styled.span`
  color: red;
  font-size: 14px;
  position: absolute;
  display: block;
`;

export const TextAreaField = ({
  name,
  value,
  placeholder,
  disabled,
  handleChange,
  handleBlur,
  touched,
  errors,
  borderError,
  rows = 6,
}) => {
  const border = getIn(touched, name) && getIn(errors, name) && 'red';
  const isError = getIn(touched, name) && getIn(errors, name);
  return (
    <div>
      <TextArea
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        $borderError={border || borderError}
        rows={rows}
      />
      {isError && <Error>{getIn(errors, name)}</Error>}
    </div>
  );
};

TextAreaField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  borderError: PropTypes.string,
};
