import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PersonTable } from './PersonTable';

const Wrapper = styled.div`
  padding: 32px 0;
`;
const Title = styled.div`
  font-weight: bold;
  background: ${(props) => props.theme.whiteBlue};
  padding: 16px;
`;

export const ExistingPersonsNotification = ({ persons, selectPerson, roleTypes, errorMessage, isSaving, isUbo }) => {
  return (
    <Wrapper>
      <Title>Persons found in the KYC-portal</Title>
      <div>
        {persons.map((person) => {
          const personData = {
            id: person.id,
            firstName: person.firstName,
            lastName: person.lastName,
            nationality: person.nationality,
            dateOfBirth: person.dateOfBirth,
            isPoliticallyExposed: person.isPoliticallyExposed,
            isPersonNew: false,
            acceptedHighRisk: person.acceptedHighRisk
          };
          return (
            <PersonTable
              key={personData.id}
              person={personData}
              buttonText="Add Person"
              selectPerson={selectPerson}
              roleTypes={roleTypes}
              errorMessage={errorMessage}
              isSaving={isSaving}
              isUbo={isUbo}
            />
          );
        })}
      </div>
    </Wrapper>
  );
};

ExistingPersonsNotification.propTypes = {
  persons: PropTypes.arrayOf(PropTypes.object),
  organizationId: PropTypes.string,
  selectPerson: PropTypes.func,
  roleTypes: PropTypes.array,
  errorMessage: PropTypes.object,
};
