import React from 'react';
import styled from 'styled-components';
import { LeftArrow } from '@instech/icons';
import { AcceptanceLogForFleet } from './AcceptanceLogForFleet';
import { useNavigate, useParams } from 'react-router-dom';
import { useFleetDetails } from '../../services/useFleetDetails';
import {AcceptanceType} from "../../utils/AcceptanceType";

const Wrapper = styled.div`
  margin: 32px 64px;
  color: ${(props) => props.theme.marineBlue};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue75};
`;
const Subtitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
`;

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
`;
const LinkToFleet = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const AcceptanceLogPageForFleet = () => {
  const { fleetId } = useParams();
  const { data: fleetData } = useFleetDetails(fleetId);
  const fleetName = fleetData?.name;
  const insuranceYear = fleetData?.insuranceYear;
  const covers = fleetData?.covers;

  let navigate = useNavigate();

  const goBackToFleet = () => {
    navigate(`/fleets/${fleetId}`, { replace: true });
  };

  return (
    <Wrapper>
      <Breadcrumbs>
        <LinkToFleet data-test-id="link-to-fleet" onClick={goBackToFleet}>
          <LeftArrow />
        </LinkToFleet>
        <Item>
          <Title>/ Fleet</Title>
          <Subtitle>
            {fleetName} ({insuranceYear})
          </Subtitle>
        </Item>
      </Breadcrumbs>
      <AcceptanceLogForFleet acceptanceType={AcceptanceType.RiskLevel} fleetId={fleetId} covers={covers} />
      <AcceptanceLogForFleet acceptanceType={AcceptanceType.MissingData} fleetId={fleetId} covers={covers} />
      <AcceptanceLogForFleet acceptanceType={AcceptanceType.MissingPersonRoles} fleetId={fleetId} covers={covers} />
      <AcceptanceLogForFleet acceptanceType={AcceptanceType.MissingUbo} fleetId={fleetId} covers={covers} />
    </Wrapper>
  );
};
