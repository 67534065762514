import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledLabel = styled.label`
  display: block;
  color: ${(props) => props.theme.marineBlue};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  text-align: left;
  margin-bottom: 6px;
  strong {
    margin-left: 0.5em;
  }
`;

export const Label = ({ label, required, fontSize = '14px', fontWeight = '600' }) => (
  <StyledLabel fontSize={fontSize} fontWeight={fontWeight}>
    <span>{label}</span>
    {required && <strong>*</strong>}
  </StyledLabel>
);

Label.propTypes = {
  label: PropTypes.node,
  required: PropTypes.bool,
};
