import React from 'react';
import styled from 'styled-components';
import { SearchCompany } from './SearchCompany';
import { CompaniesList } from '../companiesList/CompaniesList';
import { CompaniesPageContextProvider } from './CompaniesPageContext';

const Wrapper = styled.div`
  margin: 32px 128px;
`;

export const CompaniesPage = () => {
  return (
    <Wrapper>
      <CompaniesPageContextProvider>
        <SearchCompany />
        <CompaniesList />
      </CompaniesPageContextProvider>
    </Wrapper>
  );
};
