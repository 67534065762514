import React, { createContext, useContext } from 'react';
import { useCountries } from '../../services/useCountries';
import { constants } from '../../utils/constants';
import { useAppContext } from '../appRouting/AppContext';

const CompaniesPageContext = createContext({});

export const CompaniesPageContextProvider = ({ children }) => {
  const { state, dispatch } = useAppContext();
  const countries = useCountries();
  const companyQuery = state.companyQuery;
  const offset = state.offset;
  const handleSearch = (values) => {
    dispatch({ type: constants.SET_COMPANY_QUERY, value: values });
    dispatch({ type: constants.SET_OFFSET, value: 0 });
    dispatch({ type: constants.SET_SINGLE_COMPANY_ID, value: null });
  };

  const value = {
    state,
    dispatch,
    companyQuery,
    handleSearch,
    countries,
    offset,
  };

  return <CompaniesPageContext.Provider value={value}>{children}</CompaniesPageContext.Provider>;
};

export const useCompaniesPageContext = () => useContext(CompaniesPageContext);
