import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.marineBlue};
`;
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 16px repeat(3, 1fr);
  grid-gap: 32px;
  text-align: left;
  margin-top: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid ${(props) => props.theme.whiteBlue};
`;
const TableRow = styled.div`
  display: grid;
  grid-template-columns: 16px repeat(3, 1fr);
  grid-gap: 32px;
  margin: 8px 0;
  padding: 8px 0;

  &:nth-child(odd) {
    background: ${(props) => props.theme.ultraLightGray};
  }
`;
const Checkbox = styled.div`
  display: flex;
  align-items: center;
`;

export const RisksTable = ({ risks, values, setFieldValue }) => {
  const handleRiskChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setFieldValue('covers', [...values.covers, value]);
    } else {
      setFieldValue(
        'covers',
        values.covers.filter((v) => v !== value)
      );
    }
  };

  return (
    risks &&
    risks.length > 0 && (
      <Wrapper>
        <TableHeader>
          <span></span>
          <span>Interest Code</span>
          <span>Risk Status</span>
          <span>Risk Number</span>
        </TableHeader>
        {risks.map((risk, index) => (
          <TableRow key={index}>
            <Checkbox>
              <input
                type='checkbox'
                name='covers'
                checked={values.covers.includes(risk.interestCodeId)}
                onChange={handleRiskChange}
                value={risk.interestCodeId}
              />
            </Checkbox>
            <span>{risk.interestCode}</span>
            <span>{risk.riskStatus}</span>
            <span>{risk.riskNumber}</span>
          </TableRow>
        ))}
      </Wrapper>
    )
  );
};

RisksTable.propTypes = {
  risks: PropTypes.array,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};
