import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DatePicker } from '../../shared/datePicker/DatePicker';
import { SelectField } from '../../shared/formFields/SelectField';
import { Label } from '../../shared/formFields/Label';
import { WarningNotification } from '@instech/components';
import { useCompanyContext } from '../CompanyContext';
import { useOrganizationRoleTypes } from '../../../services/UseOrganizationRoleTypes';

const FormHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 16px;
  column-gap: 16px;
  justify-content: center;
  align-content: center;
  margin: 0 0 8px 0;
  grid-auto-flow: column;
`;
const ChooseRoleNotification = styled.div`
  grid-column: 1/7;
  grid-row: 2/3;
`;
const ActiveFromWrapper = styled.div`
  grid-column: 2/3;
  grid-row: 3/4;
`;
const ActiveToWrapper = styled.div`
  grid-column: 3/4;
  grid-row: 3;
  flex: 3;
`;
const RoleSelectWrapper = styled.div`
  grid-column: 1/2;
  grid-row: 3/4;
`;
const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  grid-column: 4/5;
  grid-row: 3;
`;

export const CompanyFormRoleFields = ({
  values,
  setFieldTouched,
  touched,
  errors,
  handleChange,
  showRoleExistsMessage,
  setFieldValue,
  checkExistingRole,
  formDispatch,
}) => {
  const {
    isNewCompany,
    forMultipleVessels,
    isRoleTypeEditable,
    isNewRoleType,
    isCoAssured,
    roleIsReadOnly,
    activePeriod,
    organizationsListForChecking,
    organizationForCheckingIndex,
  } = useCompanyContext();
  const allOrganizationRoleTypes = useOrganizationRoleTypes();

  const organizationRoleTypes =
    allOrganizationRoleTypes && allOrganizationRoleTypes.length > 0
      ? allOrganizationRoleTypes.filter((x) => x.isCoAssured === isCoAssured && x.roleName !== 'PETI')
      : null;

  return (
    <FormHeader>
      <RoleSelectWrapper>
        {(!isNewCompany || forMultipleVessels) && isRoleTypeEditable && (
          <div>
            <Label label='Select company role' required />
            <SelectField
              isDisabled={!isNewRoleType}
              name='clientRoleType.roleType.id'
              placeholder='Not selected'
              value={values.clientRoleType ? values.clientRoleType.roleType.id : ''}
              handleChange={(name, value) => {
                const roleType = organizationRoleTypes.find((t) => t.id === value);
                const newValue = {
                  id: !!roleType ? roleType.id : '',
                  roleName: !!roleType ? roleType.roleName : '',
                };
                setFieldValue('clientRoleType.roleType.roleName', newValue.roleName);
                setFieldValue(name, newValue.id);
                checkExistingRole(
                  value,
                  values.activePeriod?.from,
                  values.activePeriod?.to,
                  values.id,
                  organizationsListForChecking,
                  organizationForCheckingIndex,
                  formDispatch
                );
              }}
              handleBlur={setFieldTouched}
              touched={touched}
              errors={errors}
              options={organizationRoleTypes}
              displayNameSelector={(x) => x.roleName}
              valueNameSelector={(x) => x.id}
            />
            <input
              type='hidden'
              name='clientRoleType.roleType.roleName'
              value={values.clientRoleType ? values.clientRoleType.roleType.roleName : ''}
            />
            <input type='hidden' name='clientRoleType.roleType.isCoAssured' value={isCoAssured || ''} />
          </div>
        )}
      </RoleSelectWrapper>
      {(!isNewCompany || forMultipleVessels) && isRoleTypeEditable && (
        <>
          <ActiveFromWrapper>
            <Label label='Active' />
            <DatePicker
              name='activePeriod.from'
              placeholder='from'
              disabled={roleIsReadOnly}
              value={activePeriod ? activePeriod.from : null}
              touched={touched}
              errors={errors}
              handleDate={(date) =>
                checkExistingRole(
                  values.clientRoleType?.roleType.id,
                  values.activePeriod?.to,
                  date,
                  values.id,
                  organizationsListForChecking,
                  organizationForCheckingIndex,
                  formDispatch
                )
              }
            />
          </ActiveFromWrapper>
          <ActiveToWrapper>
            <Label label='&nbsp;' />
            <DatePicker
              name='activePeriod.to'
              placeholder='to'
              disabled={roleIsReadOnly}
              value={activePeriod ? activePeriod.to : null}
              touched={touched}
              errors={errors}
              handleDate={(date) =>
                checkExistingRole(
                  values.clientRoleType?.roleType.id,
                  values.activePeriod?.from,
                  date,
                  values.id,
                  organizationsListForChecking,
                  organizationForCheckingIndex,
                  formDispatch
                )
              }
            />
          </ActiveToWrapper>
        </>
      )}

      {values.clientRoleType.roleType && values.clientRoleType.roleType.roleName === 'Owner' && (
        <Checkbox>
          <input
            name='ownerIsAssured'
            type='checkbox'
            value={values.ownerIsAssured}
            checked={values.ownerIsAssured}
            onChange={handleChange}
            disabled={roleIsReadOnly}
          />
          <div>Owner as assured</div>
        </Checkbox>
      )}

      {showRoleExistsMessage && (
        <ChooseRoleNotification>
          <WarningNotification
            size='small'
            headingText='This company already has this role with overlapping dates, please change dates or interests'
          />
        </ChooseRoleNotification>
      )}
    </FormHeader>
  );
};

CompanyFormRoleFields.propTypes = {
  values: PropTypes.object,
  setFieldTouched: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  showRoleExistsMessage: PropTypes.bool,
  setFieldValue: PropTypes.func,
  checkExistingRole: PropTypes.func,
  formDispatch: PropTypes.func,
};
