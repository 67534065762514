import React from 'react';
import styled from 'styled-components';

const Spacing = styled.div`
  padding: ${props => props.padding};
`;

const DividerBar = styled.div`
  width: 100%;
  border-top: 3px solid ${props => props.theme.background.primary};
`;

export const Divider = ({ padding = '10px 0px' }) => (
  <Spacing padding={padding}>
    <DividerBar />
  </Spacing>
);
